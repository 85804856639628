import React from "react";

const RightArrow: React.FC = () => {
  return (
    <svg
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 13 19"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={
          "M0.833336 16.8458L3.05331 19L12.5 9.83333L3.05331 0.66667L0.833336 2.82084L8.04431 9.83333L0.833336 16.8458Z"
        }
        fill={"#fff"}
      />
    </svg>
  );
};

export default RightArrow;
