export const requestFormTopInfoParagraphs = [
  "Complete the fields below to create a payment link for your business customers. We’ll generate a unique link which you can send to the customer so that they can pay securely via our website. The link will contain the order reference and payment amount that you enter here.",
];

export const formButtonLabel = "Create payment link";

export const pageTitle = "B2B payment request";

export const tokenLinkTopInfoParagraphs = [
  "Your unique link is below. Please copy the full link and send it to your customer.",
  "The link will open a secure payment screen on well.co.uk. The amount and order reference number cannot be changed by the customer, so please check that you have entered the information correctly before sending the link out.",
  "You will get an email confirmation when the customer makes this payment. We don’t capture any customer information through the payment platform and so can’t send them an email confirming receipt of payment. Please contact the customer to confirm receipt of payment after you receive the automated confirmation email.",
];
