import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Basket from "../Basket";

export interface MobileBasketStateProps {
  show: boolean;
}

export interface MobileBasketDispatchProps {
  closeBasket(): void;
}

export type MobileBasketProps = MobileBasketStateProps &
  MobileBasketDispatchProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileBasketWrapper: {
      position: "fixed",
      width: "100%",
      zIndex: 100,
      height: "100%",
      overflowY: "auto",
      marginTop: 85,
    },
    floatingBasket: {
      backgroundColor: "white",
    },
    overlay: {
      width: "100%",
      height: "200px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  })
);

const MobileBasket: React.FC<MobileBasketProps> = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.show && (
        <div className={classes.mobileBasketWrapper}>
          <div className={classes.floatingBasket}>
            <Basket width={"100%"} closeBasket={props.closeBasket} />
          </div>
          <div className={classes.overlay} onClick={props.closeBasket} />
        </div>
      )}
    </>
  );
};

export default MobileBasket;
