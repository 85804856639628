import {
  Address,
  PatientDetails,
  PaymentError,
  PostcodeLookupAddress,
  State,
} from "./types";

export function getShippingAddress(state: State): Address {
  return state.shippingAddress;
}

export function getBillingAddress(state: State): Address {
  return state.billingAddress;
}

export function getBraintreeToken(state: State): string {
  return state.braintreeToken;
}

export function getConfirmedPayment(state: State): boolean {
  return state.confirmedPayment;
}

export function getPostcodeAddressLookup(
  state: State
): PostcodeLookupAddress[] {
  return state.postCodeLookUpAddresses;
}

export function getPostcodeLookupError(state: State): string {
  return state.postCodeLookUpError;
}

export function getError(state: State): PaymentError | null {
  return state.err;
}

export function getShippingCost(state: State): number {
  return 400;
}

export const getPatientDetails = (state: State): PatientDetails =>
  state.patientDetails;

export const getPaymentPending = (state: State): boolean => state.pending;

export const getOrderId = (state: State): string | null => state.orderId;

export function getAWCToken(state: State): string | null {
  return state.awcToken;
}
