import {
  FETCH_CATEGORIES,
  FetchCategoriesAction,
  SelectCategoryAction,
  SELECT_CATEGORY,
} from "./types";

export function fetchCategories(): FetchCategoriesAction {
  return {
    type: FETCH_CATEGORIES,
  };
}

export function selectCategoryId(
  categoryId: string | null
): SelectCategoryAction {
  return {
    type: SELECT_CATEGORY,
    payload: {
      categoryId,
    },
  };
}
