import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 30,
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      marginTop: 45,
      marginBottom: 40,
    },
  })
);

export interface MobileCheckoutProps {
  view: JSX.Element;
  title: string;
}

const MobileCheckout: React.FC<MobileCheckoutProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant={"h4"} align={"center"} className={classes.title}>
        {props.title}
      </Typography>
      {props.view}
    </div>
  );
};

export default MobileCheckout;
