import { State, HighlightedCategories, SelectedProduct } from "./types";

export const getHighlightedCategories = (
  state: State
): HighlightedCategories[] => {
  return state.highlightedCategories;
};

export const getSelectedProduct = (state: State): SelectedProduct => {
  return state.selectedProduct;
};
