import { connect } from "react-redux";
import { addToBasket } from "../../store/reducer/basket/action";
import {
  basketPatientDetailsActive,
  checkoutActive,
  getBasketItems,
  getBasketNeedsPatientDetails,
  getTotalCost,
  getTotalItems,
  maxParacetamol,
  paymentActive,
  productActive,
} from "../../store/selectors";
import { RootState } from "../../store/types";
import Basket, { BasketDispatchProps, BasketStateProps } from "./Basket";

function mapStateToProps(state: RootState): BasketStateProps {
  return {
    basketItems: getBasketItems(state),
    totalCost: getTotalCost(state),
    totalItems: getTotalItems(state),
    maxParacetamol: maxParacetamol(state),
    patientDetailsActive: basketPatientDetailsActive(state),
    checkoutActive: checkoutActive(state),
    paymentActive: paymentActive(state),
    productActive: productActive(state),
    basketNeedsPatientDetails: getBasketNeedsPatientDetails(state),
  };
}

const mapDispatchToProps: BasketDispatchProps = {
  addToBasket: addToBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
