import { Box, Typography } from "@material-ui/core";
import React from "react";
//Components
import { BasketItem as BasketItemType } from "../../store/reducer/basket/types";
import { Product } from "../../store/reducer/products/types";
import { addDisabled } from "../../utils/utils";
import BasketItem from "./BasketItem/BasketItem";
import BasketSummary from "./BasketSummary/BasketSummary";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export interface BasketStateProps {
  basketItems: BasketItemType[];
  totalCost: number;
  totalItems: number;
  maxParacetamol: boolean;
  patientDetailsActive: boolean;
  checkoutActive: boolean;
  paymentActive: boolean;
  productActive: boolean;
  basketNeedsPatientDetails: boolean;
}

export interface BasketDispatchProps {
  addToBasket(p: Product, q: number): void;
}

export interface BasketPropsFromParent {
  width: number | string;
  fixed?: boolean;
  closeBasket?(): void;
}

export type BasketProps = BasketStateProps &
  BasketDispatchProps &
  BasketPropsFromParent;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shoppingCartBasket: {
      color: theme.palette.text.primary,
      fontSize: 24,
      marginTop: 20,
      marginLeft: 20,
    },
    close: {
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.text.primary,
      marginRight: 31,
      marginTop: 20,
      "&:hover": {
        cursor: "pointer",
      },
    },
    background: {
      // marginRight: 44,
      margin: "auto",
      backgroundColor: "#FFFFFF",
    },
  })
);

const Basket: React.FC<BasketProps> = ({
  width,
  closeBasket,
  basketItems,
  checkoutActive,
  paymentActive,
  addToBasket,
  maxParacetamol,
  patientDetailsActive,
  productActive,
  totalCost,
  totalItems,
  basketNeedsPatientDetails,
  fixed,
}) => {
  const classes = useStyles();
  return (
    <div style={{ width }} className={classes.background}>
      <Box display={"flex"}>
        <Box flexGrow={1}>
          <ShoppingCartIcon className={classes.shoppingCartBasket} />
        </Box>
        {!fixed && (
          <Box onClick={closeBasket}>
            <Typography variant={"body1"} className={classes.close}>
              Close
            </Typography>
          </Box>
        )}
      </Box>
      {basketItems.map((item, index) => {
        return (
          <BasketItem
            item={item}
            key={`${index}`}
            checkout={checkoutActive || paymentActive}
            addToBasket={addToBasket}
            addDisabled={addDisabled(item.product, maxParacetamol, item.qty)}
          />
        );
      })}
      <div style={{ position: "relative" }}>
        <BasketSummary
          checkout={checkoutActive || paymentActive || patientDetailsActive}
          totalCost={totalCost}
          totalItems={totalItems}
          historyPushTarget={getCheckoutContinueNavTarget(
            basketNeedsPatientDetails
          )}
        />
      </div>
    </div>
  );
};

const getCheckoutContinueNavTarget = (needsPatientDetails: boolean) =>
  needsPatientDetails ? "/patient-details" : "/your-details";

export default Basket;
