import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { apiMiddleware } from "redux-api-middleware";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import basket from "./reducer/basket";
import products from "./reducer/products";
import checkoutReducer from "./reducer/checkout";
import productSuggestions from "./reducer/productSuggestions";
import categories from "./reducer/category";
import b2bPayments from "./reducer/b2bPayments";
import { gaPurchaseMiddleware } from "./reducer/checkout/middleware";
import { gaBasketMiddleware } from "./reducer/basket/middleware";
import { catMiddle } from "./reducer/category/middleware";
import { prodMiddle } from "./reducer/products/middleware";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["checkout", "productDetail", "productSuggestions", "products"],
};

const checkoutPersistConfig = {
  key: "checkout",
  storage: storage,
  blacklist: ["braintreeToken", "confirmedPayment"],
};

const checkout = persistReducer(checkoutPersistConfig, checkoutReducer);

const rootReducers = combineReducers({
  products,
  basket,
  checkout,
  productSuggestions,
  categories,
  b2bPayments,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(
      apiMiddleware,
      gaBasketMiddleware,
      gaPurchaseMiddleware,
      catMiddle,
      prodMiddle
    )
  )
);

export const persistor = persistStore(store);
