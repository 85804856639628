import { RSAA } from "redux-api-middleware";

export interface State {
  suggestions: ProductSuggestion[];
  fetching: boolean;
}

export interface ProductSuggestion {
  sku: string;
  label: string;
  name: string;
}

export const FETCH_PRODUCT_SUGGESTIONS = "PRODUCTS/FETCH_PRODUCT_SUGGESTIONS";
export const FETCH_PRODUCT_SUGGESTIONS_REQUEST =
  "PRODUCTS/FETCH_PRODUCT_SUGGESTIONS_REQUEST";
export const FETCH_PRODUCT_SUGGESTIONS_RESPONSE =
  "PRODUCTS/FETCH_PRODUCT_SUGGESTIONS_RESPONSE";
export const FETCH_PRODUCT_SUGGESTIONS_FAILURE =
  "PRODUCTS/FETCH_PRODUCT_SUGGESTIONS_FAILURE";

export interface FetchProductSuggestionsAction {
  type: string;
  [RSAA]: {
    endpoint: string;
    method: string;
    types: [string, string, string];
  };
}

export interface FetchProductSuggestionsResponseAction {
  type: string;
  payload: {
    id: string;
    name: string;
  }[];
}

export const CLEAR_PROD_SELECTION = "PRODUCTS/CLEAR_PROD_SELECTION";

export interface ClearSuggestionsResponseAction {
  type: string;
}
