import React from "react";
import { Container, useTheme, useMediaQuery, Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

//Components.
import ProductDetails from "../../components/ProductDetails";
import MobileSearchPanel from "../../components/Banner/MobileSearchPanel";
import DesktopSearchPanel from "../../components/Banner/DesktopSearchPanel";
import Categories from "../../components/Categories";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 15,
      marginLeft: 0,
    },
    box: {
      width: "100%",
    },
  })
);

export interface ProductDetailsPageStateProps {
  totalItems: number;
}

export interface ProductDetailsPageDispatchProps {
  onMobileCartClick(): void;
  onDesktopCartClick(): void;
  onCatClick(): void;
}

export type ProductDetailsPageProps = ProductDetailsPageStateProps &
  ProductDetailsPageDispatchProps;

const ProductDetailsPage: React.FC<ProductDetailsPageProps> = ({
  totalItems,
  onMobileCartClick,
  onDesktopCartClick,
  onCatClick,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {isSmallerThanMd ? (
        <MobileSearchPanel
          totalItems={totalItems}
          onCartClick={onMobileCartClick}
          onCatClick={onCatClick}
        />
      ) : (
        <DesktopSearchPanel
          totalItems={totalItems}
          onCartClick={onDesktopCartClick}
        />
      )}
      <Box display={"flex"}>
        {!isSmallerThanMd && (
          <Box>
            <Categories />
          </Box>
        )}
        <Box className={classes.box}>
          <Container className={classes.container}>
            <ProductDetails />
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default ProductDetailsPage;
