import { useEffect } from "react";

export const PrismicEndPoint = "https://welldigital-shop.cdn.prismic.io/api/v2";

const usePrismicPreview = () => {
  useEffect(() => {
    if (
      process.env.REACT_APP_PRISMIC_PREVIEW &&
      process.env.REACT_APP_PRISMIC_PREVIEW === "true"
    ) {
      const script = document.createElement("script");

      script.src =
        "https://static.cdn.prismic.io/prismic.js?new=true&repo=welldigital-shop";
      script.async = true;

      document.head.appendChild(script);
    }
  }, []);
};

export default usePrismicPreview;
