import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  BRAINTREE_CONTAINER_ID,
  PaymentStatus,
  useBraintree,
} from "@welldigital/components";
import { Alert } from "@material-ui/lab";
import React, { useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { BasketItem } from "../../store/reducer/basket/types";
import {
  Address,
  InsufficientInventoryError,
  OnPayInput,
  PatientDetails,
  PaymentError,
  PaymentErrorType,
} from "../../store/reducer/checkout/types";
import { getCostStr } from "../../utils/utils";
import analytics from "../../analytics";
import {
  paymentRequested,
  paymentConfirmed as paymentConfirmedEvent,
  paymentConfirmedItem,
} from "../../analytics/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    wrapper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    error: {
      margin: "0px 90px 0px 90px",
    },
    button: {
      marginRight: 5,
      marginLeft: "auto",
      marginBottom: theme.spacing(2),
      textTransform: "none",
    },
    dropIn: {
      // 13 matches what the drop-in applies to all it's parent elements except the "add another card button"!
      marginBottom: 13,
    },
    loadingSpinnerCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
  })
);

export interface PaymentStateProps {
  err: PaymentError | null;
  basketTotal: number;
  shippingCost: number;
  billingAddress?: Address;
  shippingAddress?: Address;
  basket?: BasketItem[];
  braintreeToken: string;
  paymentConfirmed: boolean;
  patientDetails?: PatientDetails;
  paymentInProcess: boolean;
  needsBraintreeTokenData?: boolean;
  awcToken?: string;
}

export interface PaymentDispatchProps {
  onPay(input: OnPayInput): void;
  fetchBraintreeToken(data?: string): void;
  setErr(message: string): void;
}

export type PaymentProps = PaymentStateProps & PaymentDispatchProps;

const Payment: React.FC<PaymentProps> = ({
  braintreeToken,
  paymentConfirmed,
  fetchBraintreeToken,
  billingAddress,
  basketTotal,
  shippingCost,
  basket,
  patientDetails,
  shippingAddress,
  needsBraintreeTokenData,
  awcToken,
  onPay,
  setErr,
  ...props
}) => {
  const { tokenRequestData } = useParams<{ tokenRequestData?: string }>();
  const { paymentStatus, error, canPay, makePayment } = useBraintree({
    fee: (basketTotal + shippingCost) / 100,
    paymentToken: braintreeToken,
    billingInfo: {
      firstName: billingAddress!.firstName,
      lastName: billingAddress!.lastName,
      email: billingAddress!.email,
      phone: billingAddress!.phone || "",
      address1: billingAddress!.line1,
      address2: billingAddress!.line2 || "",
      city: billingAddress!.city,
      postcode: billingAddress!.postcode,
    },
  });

  // First Get the braintree token from the backend
  // B2B tokens require additional data
  useEffect(() => {
    if (needsBraintreeTokenData) {
      if (!tokenRequestData) {
        return;
      }
      fetchBraintreeToken(tokenRequestData);
    } else {
      fetchBraintreeToken();
    }
  }, [fetchBraintreeToken, needsBraintreeTokenData, tokenRequestData]);

  // Styling
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  const onRequestPayment = useCallback(async () => {
    analytics.trackEvent({
      event: paymentRequested,
      metadata: {
        products: basket?.map((item) => ({
          sku: item.product.sku,
          product: item.product.name,
          cost: item.product.cost,
          qty: item.qty,
        })),
        price: basketTotal,
      },
    });

    try {
      const payload = await makePayment();

      if (payload?.liabilityShifted && payload?.nonce) {
        onPay({
          nonce: payload.nonce,
          patient: patientDetails,
          billingAddress,
          shippingAddress,
          basket,
          tokenData: tokenRequestData,
          awcToken,
        });
      }
    } catch (err) {
      // err.message is the message string
      // err.type can be "CUSTOMER" - only act on customer types? Let's see what happens
      console.log(`err ${err.message}`);
      setErr(err.message);
    }
  }, [
    basket,
    basketTotal,
    billingAddress,
    makePayment,
    onPay,
    patientDetails,
    shippingAddress,
    tokenRequestData,
    awcToken,
    setErr,
  ]);

  // if payment confirmed change the router to a different page.
  useEffect(() => {
    if (!paymentConfirmed) return;
    analytics.trackEvent({
      event: paymentConfirmedEvent,
      metadata: {
        products: basket?.map((item) => ({
          sku: item.product.sku,
          product: item.product.name,
          cost: item.product.cost,
          qty: item.qty,
        })),
        price: basketTotal,
      },
    });
    basket?.forEach((prod) => {
      analytics.trackEvent({
        event: paymentConfirmedItem,
        metadata: {
          product: prod.product.name,
          sku: prod.product.sku,
          price: prod.product.cost,
        },
      });
    });
    history.push("/confirmation");
  }, [paymentConfirmed, history, basket, basketTotal]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction={"column"}
        className={!isSmallerThanMd ? classes.wrapper : undefined}
      >
        {!braintreeToken ? (
          // If the braintree token is not there then put a loading screen instead.
          <Loading />
        ) : (
          <>
            {props.err && getAlert(props.err, classes, basket)}
            <Grid item xs={12} className={classes.dropIn}>
              <div id={BRAINTREE_CONTAINER_ID} />
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <Button
                  disabled={props.paymentInProcess || !canPay}
                  variant={"contained"}
                  id={"payment-button"}
                  color={"secondary"}
                  className={classes.button}
                  onClick={onRequestPayment}
                >
                  {paymentStatus === PaymentStatus.PAYING ? (
                    <div className={classes.loadingSpinnerCenter}>
                      <CircularProgress
                        size={"1em"}
                        className={classes.marginRight}
                      />
                      Paying...
                    </div>
                  ) : (
                    "Pay"
                  )}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Payment;

const getAlert = (
  err: PaymentError,
  classes: ReturnType<typeof useStyles>,
  basket?: BasketItem[]
) => {
  if (isValidInsufficientInventoryError(err)) {
    const inventoryData = ((err.data as unknown) as InsufficientInventoryError)
      .inventoryUpdates;
    const msg = makeInsufficientInventoryErrorMessage(
      inventoryData,
      basket as BasketItem[]
    );
    return (
      <Alert severity={"error"} className={classes.error}>
        {msg}
      </Alert>
    );
  }
  return (
    <Alert severity={"error"} className={classes.error}>
      {err.message}
    </Alert>
  );
};

const Loading: React.FC = () => <div>Loading</div>;

// Runtime check that the data is what we expect
const isValidInsufficientInventoryError = (err: PaymentError) =>
  err.message === PaymentErrorType.inventory &&
  err.data &&
  err.data.inventoryUpdates &&
  typeof err.data.inventoryUpdates === "object";

const makeInsufficientInventoryErrorMessage = (
  data: { [key: string]: number },
  basket: BasketItem[]
) => {
  // \xa0 === &nbsp;
  let str = [
    "There is a problem with your basket. Unfortunately, we no longer have enough of the following items in stock to fulfil your order:\xa0",
  ];
  // data is keyed by SKU
  str = Object.keys(data).reduce((m, sku, i) => {
    if (i !== 0) {
      m = [...m, ",\xa0"];
    }
    return [...m, getProductName(basket, sku)];
  }, str);
  str = [...str, ". Please\xa0"];
  return [
    ...str.map((s) => withTypography(s)),
    <Link key={str.length} to={"/"}>
      {withTypography("go back and review")}
    </Link>,
    withTypography("\xa0your order before you check out"),
  ];
};

const withTypography = (s: string) => (
  <Typography variant={"body1"} display={"inline"} align={"left"}>
    {s}
  </Typography>
);

const getProductName = (basket: BasketItem[], sku: string) =>
  (basket.find((item) => item.product.sku === sku) as BasketItem).product.name;
