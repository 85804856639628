import { RichTextBlock } from "prismic-reactjs";

export interface Category {
  id: string;
  name: string;
  description: RichTextBlock[];
}

export interface State {
  list: Category[];
  selectedCategoryId: string | null;
}

export const FETCH_CATEGORIES = "CATEGORIES/FETCH_CATEGORIES";
export const FETCH_CATEGORIES_REQUEST = "CATEGORIES/FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_RESPONSE = "CATEGORIES/FETCH_CATEGORIES_RESPONSE";
export const FETCH_CATEGORIES_FAILURE = "CATEGORIES/FETCH_CATEGORIES_FAILURE";

export interface FetchCategoriesAction {
  type: string;
}

export interface CategoryRes {
  uid: string;
  data: {
    description: RichTextBlock[];
    name: string;
  };
}

export interface FetchCategoriesResponseAction {
  type: string;
  payload: {
    categories: Category[];
  };
}

export const SELECT_CATEGORY = "CATEGORY/SELECT";

export interface SelectCategoryAction {
  type: string;
  payload: {
    categoryId: string | null;
  };
}
