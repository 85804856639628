import { connect } from "react-redux";
import { RootState } from "../../store/types";
import { getCategories } from "../../store/selectors";
import Categories, {
  CategoriesDispatchProps,
  CategoriesStateProps,
} from "./Categories";

import { fetchCategories } from "../../store/reducer/category/actions";

function mapStateToProps(state: RootState): CategoriesStateProps {
  return {
    categories: getCategories(state),
  };
}

const mapDispatchToProps: CategoriesDispatchProps = {
  fetchCategories: fetchCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
