import { connect } from "react-redux";
import { RootState } from "../../store/types";
import { getSearchSuggestions } from "../../store/selectors";
import {
  clearSelected,
  fetchProductSuggestions,
} from "../../store/reducer/productSuggestions/actions";
import Search, { SearchStateProps, SearchDispatchProps } from "./Search";

function mapStateToProps(state: RootState): SearchStateProps {
  return {
    suggestions: getSearchSuggestions(state),
  };
}

const mapDispatchToProps: SearchDispatchProps = {
  fetchSuggestions: fetchProductSuggestions,
  clearSelectedProds: clearSelected,
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
