import { connect } from "react-redux";
import {
  addShippingAddress,
  fetchPostCodeAddress,
} from "../../store/reducer/checkout/actions";
import {
  getBasketItems,
  getBasketNeedsPatientDetails,
  getBillingAddress,
  getPostcodeAddressLookup,
  getPostcodeLookupError,
  getShippingAddress,
  getTotalCost,
} from "../../store/selectors";
import { RootState } from "../../store/types";
import Shipping, {
  ShippingAddressDispatchProps,
  ShippingAddressStateProps,
} from "./Shipping";

function mapStateToProps(state: RootState): ShippingAddressStateProps {
  return {
    address: getShippingAddress(state),
    postCodeLookUpAddresses: getPostcodeAddressLookup(state),
    error: getPostcodeLookupError(state),
    neededPatientDetails: getBasketNeedsPatientDetails(state),
    basketItems: getBasketItems(state),
    totalCost: getTotalCost(state),
    billingAddress: getBillingAddress(state),
  };
}

const mapDispatchToProps: ShippingAddressDispatchProps = {
  onSubmit: addShippingAddress,
  onClickPostCodeSearch: fetchPostCodeAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shipping);
