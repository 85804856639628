import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import Mask from "./mask";

export interface ShopInfoProps {
  mobile: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: ({ mobile }: ShopInfoProps) => (mobile ? 33 : 333),
      backgroundColor: theme.palette.primary.light,
      paddingBottom: ({ mobile }: ShopInfoProps) => (mobile ? 48 : 91),
    },
    cart: {
      marginTop: 5,
      color: theme.palette.text.primary,
      fontSize: 30,
    },
    title: {
      fontSize: 34,
      color: theme.palette.text.primary,
      fontWeight: 600,
      marginLeft: 20,
    },
    text: {
      marginTop: 25,
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontSize: 18,
      marginRight: 36,
    },
    iconRow: {
      marginTop: 25,
      fontWeight: 600,
      fontSize: 14,
      color: theme.palette.text.primary,
      maxWidth: 600,
    },
    icon: {
      fontSize: 25,
      marginRight: 8,
    },
    iconOutside: {
      height: 32,
      width: 32,
      marginRight: 8,
      marginTop: -2,
    },
    usp: {
      paddingTop: 3,
    },
    uspRow: {
      marginTop: ({ mobile }: ShopInfoProps) => (mobile ? 15 : 0),
    },
  })
);

const ShopInfo: React.FC<ShopInfoProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <Box display={"flex"}>
        <Box>
          <ShoppingCartIcon className={classes.cart} />
        </Box>
        <Typography variant={"h2"} className={classes.title}>
          Shop
        </Typography>
      </Box>
      <div className={classes.text}>
        {props.mobile ? (
          <Typography variant={"body1"}>
            Shop online for everyday healthcare essentials, toiletries,
            skincare, fragrance and gifts.
          </Typography>
        ) : (
          <>
            <Typography variant={"body1"}>
              Shop online for everyday healthcare essentials,
            </Typography>
            <Typography variant={"body1"}>
              toiletries, skincare, fragrance and gifts.
            </Typography>
          </>
        )}
        <Grid container className={classes.iconRow}>
          <Grid item xs={12} sm={4}>
            <Box display={"flex"} className={classes.uspRow}>
              <Box>
                <PaymentOutlinedIcon className={classes.icon} />
              </Box>
              <Box className={classes.usp}>Secure payment</Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display={"flex"} className={classes.uspRow}>
              <Box>
                <ShoppingBasketOutlinedIcon className={classes.icon} />
              </Box>
              <Box className={classes.usp}>Bulk ordering</Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display={"flex"} className={classes.uspRow}>
              <Box>
                <Mask className={classes.iconOutside} />
              </Box>
              <Box className={classes.usp}>COVID-19 essentials</Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ShopInfo;
