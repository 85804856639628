import { handleActions } from "redux-actions";
import {
  State,
  FETCH_PRODUCTS_HIGHLIGHT,
  FETCH_PRODUCTS_HIGHLIGHT_RESPONSE,
  FetchProductsHighlightedResponseAction,
  HighlightedCategories,
  FETCH_CAT_PRODUCTS,
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCT_DETAIL,
  FETCH_PRODUCT_DETAIL_RESPONSE,
  SelectedProduct,
  FetchProductDetailsResponseAction,
  FETCH_PRODUCT_SALES_COUNT_RESPONSE,
  FetchProductSalesCountResponseAction,
  Product,
} from "./types";
import { AnyAction, combineReducers, Reducer } from "redux";
import { calcInventoryCount } from "../../utils/inventory";

const fetching = handleActions(
  {
    [FETCH_PRODUCTS_HIGHLIGHT]: () => true,
    [FETCH_CAT_PRODUCTS]: () => true,
    [FETCH_PRODUCT_DETAIL]: () => true,

    [FETCH_PRODUCTS_HIGHLIGHT_RESPONSE]: () => false,
    [FETCH_PRODUCT_DETAIL_RESPONSE]: () => false,
    [FETCH_PRODUCTS_FAIL]: () => false,
  },
  false
);

const selectedProductProduct = handleActions(
  {
    [FETCH_PRODUCT_DETAIL_RESPONSE]: (
      _state: Product,
      action: AnyAction
    ): Product => {
      const a = action as FetchProductDetailsResponseAction;
      if (a.payload) {
        return a.payload.selectedProduct.product;
      }
      return {} as Product;
    },
    [FETCH_PRODUCT_SALES_COUNT_RESPONSE]: (
      state: Product,
      action: AnyAction
    ): Product => {
      const a = action as FetchProductSalesCountResponseAction;

      // Set quantity to available inventory
      if (!!a.payload.counts[state.sku]) {
        state.inventoryCount = calcInventoryCount(
          a.payload.counts[state.sku],
          state.maxDailyCount
        );
      }
      return state;
    },
  },
  {} as Product
);

const selectedProductRelatedProduct = handleActions(
  {
    [FETCH_PRODUCT_DETAIL_RESPONSE]: (
      _state: Product[],
      action: AnyAction
    ): Product[] => {
      const a = action as FetchProductDetailsResponseAction;
      if (a.payload) {
        return a.payload.selectedProduct.relatedProducts;
      }
      return [] as Product[];
    },
    [FETCH_PRODUCT_SALES_COUNT_RESPONSE]: (
      state: Product[],
      action: AnyAction
    ): Product[] => {
      const a = action as FetchProductSalesCountResponseAction;
      if (!state.length) {
        return [...state];
      }

      const items = state.map((item) => {
        // Set quantity to available inventory
        if (!!a.payload.counts[item.sku]) {
          item.inventoryCount = calcInventoryCount(
            a.payload.counts[item.sku],
            item.maxDailyCount
          );
        }
        return item;
      });
      return items;
    },
  },
  [] as Product[]
);

const highlightedCategories = handleActions(
  {
    [FETCH_PRODUCTS_HIGHLIGHT_RESPONSE]: (
      _state: HighlightedCategories[],
      action: AnyAction
    ) => {
      const a = action as FetchProductsHighlightedResponseAction;
      if (a.payload) {
        return a.payload.highlightedCategories;
      }
      return [];
    },
    [FETCH_PRODUCTS_HIGHLIGHT]: () => [],
    [FETCH_PRODUCT_SALES_COUNT_RESPONSE]: (
      state: HighlightedCategories[],
      action: AnyAction
    ): HighlightedCategories[] => {
      const a = action as FetchProductSalesCountResponseAction;
      if (!state.length) {
        return [...state];
      }

      const items = state.map((item) => {
        return {
          category: item.category,
          products: item.products.map((prod) => {
            if (!!a.payload.counts[prod.sku]) {
              prod.inventoryCount = calcInventoryCount(
                a.payload.counts[prod.sku],
                prod.maxDailyCount
              );
            }
            return prod;
          }),
        };
        // Set quantity to available inventory
      });

      return items;
    },
  },
  []
);

const selectedProduct: Reducer<SelectedProduct, any> = combineReducers({
  product: selectedProductProduct,
  relatedProducts: selectedProductRelatedProduct,
});

const reducer: Reducer<State, any> = combineReducers({
  fetching,
  selectedProduct,
  highlightedCategories,
});

export default reducer;
