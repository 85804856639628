import React, { useEffect, Fragment } from "react";

import { Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

//Types
import {
  HighlightedCategories,
  Product,
} from "../../store/reducer/products/types";

//Components
import ProductListItem from "../../components/ProductListItem/ProductListitem";
import { BasketItem } from "../../store/reducer/basket/types";
import { calcBasketItem } from "../../utils/utils";
import { Link, useParams } from "react-router-dom";
import Marketing from "../SignUpToMarketing/SignUpForMarketing";
import analytics from "../../analytics";
import { landingPage, categoryPage } from "../../analytics/types";
import { RichText } from "prismic-reactjs";

export interface ProductGridStateProps {
  fetching: boolean;
  basketItems: BasketItem[];
  hasMaxParacetamol: boolean;
  highlightedCategories: HighlightedCategories[];
}

export interface ProductGridDispatchProps {
  addToBasket(p: Product, q: number): void;
  consentToMarketing(email: string): void;
  fetchCategoryProducts(cat: string): void;
  fetchHighlightProducts(): void;
}

export interface ProductGridParentProps {
  isMobile: boolean;
  tablet: boolean;
}

interface ParamTypes {
  category: string;
}

export type ProductGridProps = ProductGridStateProps &
  ProductGridDispatchProps &
  ProductGridParentProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 60,
    },
    productGrid: {
      marginTop: 30,
      maxWidth: ({ isMobile, tablet }: ProductGridProps) =>
        isMobile ? 672 : tablet ? 672 : 1008,
    },
    topName: {
      color: theme.palette.text.primary,
      fontSize: 24,
      fontWeight: 700,
    },
    description: {
      color: theme.palette.action.active,
      marginBottom: 10,
      fontWeight: 500,
      fontSize: 18,
      marginTop: 12,
    },
    seeMoreLink: {
      textDecoration: "underline",
      color: theme.palette.action.active,
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 16,
      marginTop: 12,
    },
    catRow: {
      marginTop: 30,
    },
    breadcrumb: {
      paddingLeft: 10,
      marginBottom: 14,
    },
  })
);

const ProductGrid: React.FC<ProductGridProps> = (props) => {
  const {
    fetchCategoryProducts,
    fetchHighlightProducts,
    highlightedCategories,
    basketItems,
    hasMaxParacetamol,
    addToBasket,
    consentToMarketing,
    isMobile,
  } = props;

  function track(category: string | undefined) {
    if (category === undefined) {
      analytics.trackEvent({
        event: landingPage,
        metadata: {
          referer: document.referrer,
        },
      });
    }

    if (category !== undefined) {
      analytics.trackEvent({
        event: categoryPage,
        metadata: {
          category: category,
        },
      });
    }
  }

  const classes = useStyles(props);
  const maxNumberPerCat = isMobile ? 2 : 3;
  const { category } = useParams<ParamTypes>();

  useEffect(() => {
    if (category === undefined) {
      fetchHighlightProducts();
    } else {
      fetchCategoryProducts(category);
    }
  }, [fetchHighlightProducts, fetchCategoryProducts, category]);

  useEffect(() => {
    track(category);
  }, [category]);
  return (
    <div className={classes.root}>
      {highlightedCategories.map((hcCat, catIndex) => (
        <div className={classes.catRow} key={hcCat.category.id}>
          {category ? (
            <div>
              <Typography
                variant={"h4"}
                align={"left"}
                className={classes.topName}
              >
                {hcCat.category.name}
              </Typography>
              <RichText render={hcCat.category.description} />
            </div>
          ) : (
            <div>
              <Typography
                variant={"h4"}
                align={"left"}
                className={classes.topName}
              >
                Most popular products in {hcCat.category.name}
              </Typography>
              <Link
                to={`/category/${hcCat.category.id.toLowerCase()}/products`}
              >
                <Typography
                  variant={"body1"}
                  align={"left"}
                  className={classes.seeMoreLink}
                >
                  See all {hcCat.category.name} products
                </Typography>
              </Link>
            </div>
          )}

          <Grid
            container
            className={classes.productGrid}
            justify={"flex-start"}
            direction={"row"}
            alignItems={"center"}
          >
            {hcCat.products
              .filter((_prod) => _prod.inventoryCount > 0)
              .filter((_prod, index) =>
                category ? true : index < maxNumberPerCat
              )
              .map((product, index2, prods) => {
                return (
                  <Fragment key={`prod-grid-${product.sku}-${index2}`}>
                    <ProductListItem
                      product={product}
                      key={`prod-${product.sku}`}
                      basketItem={calcBasketItem(product, basketItems)}
                      basketItems={basketItems}
                      maxParacetamol={hasMaxParacetamol}
                      addToBasket={addToBasket}
                      mobile={isMobile}
                    />
                    {(index2 === maxNumberPerCat - 1 ||
                      (prods.length < maxNumberPerCat &&
                        index2 === prods.length - 1)) &&
                      catIndex === 0 && (
                        <Marketing
                          consentToMarketing={consentToMarketing}
                          key={`marketing-${product.sku}`}
                          mobile={isMobile}
                        />
                      )}
                  </Fragment>
                );
              })}
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default ProductGrid;
