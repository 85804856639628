import { Box, Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import { getCostStr } from "../../../utils/utils";
import DescriptionIcon from "@material-ui/icons/Description";

export interface BasketSummaryProps {
  totalCost: number;
  totalItems: number;
  checkout: boolean;
  historyPushTarget: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryRoot: {
      paddingBottom: 30,
      background: theme.palette.primary.light,
      paddingLeft: 24,
      paddingRight: 24,
    },
    total: {
      fontWeight: 600,
      fontSize: 16,
      color: theme.palette.text.primary,
      paddingTop: 23,
    },
    button: {
      marginTop: 24,
      width: "100%",
      textTransform: "none",
      backgroundColor: theme.palette.primary.dark,
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 10,
      height: 50,
    },
    delivery: {
      marginTop: 37,
    },
    deliveryText: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
    },
    icon: {
      color: theme.palette.primary.dark,
      fontSize: 24,
      marginLeft: 12,
      marginTop: 15,
    },
    buttonText: {
      textTransform: "none",
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.text.hint,
    },
  })
);

const BasketSummary: React.FC<BasketSummaryProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.summaryRoot}>
      <Box display={"flex"}>
        <Box flexGrow={1}>
          <Typography variant={"h6"} align={"left"} className={classes.total}>
            Total inc delivery
          </Typography>
        </Box>
        <Box>
          <Typography variant={"h6"} align={"right"} className={classes.total}>
            £{getCostStr(props.totalCost + 400)}
          </Typography>
        </Box>
      </Box>
      {!props.checkout && (
        <Button
          variant={"text"}
          color={"secondary"}
          className={classes.button}
          classes={{
            text: classes.buttonText,
          }}
          onClick={() => {
            history.push(props.historyPushTarget);
          }}
        >
          <Typography variant={"h6"}>Checkout</Typography>
        </Button>
      )}
      <Grid container className={classes.delivery}>
        <Grid item xs={2}>
          <DescriptionIcon className={classes.icon} />
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant={"body2"}
            align={"left"}
            className={classes.deliveryText}
          >
            For delivery information and terms of purchase, please review our{" "}
            <a
              className={classes.deliveryText}
              href={
                "https://www.well.co.uk/about-us/policies/shop-terms-and-conditions"
              }
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Terms and Conditions.
            </a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasketSummary;
