import React, { useEffect, useState } from "react";
import {
  TextField,
  makeStyles,
  Button,
  Grid,
  Popper,
  List,
  ListItemText,
  ListItem,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { POSTCODE_PATTERN } from "./postcode";
import {
  Address,
  emptyAddress,
  PostcodeLookupAddress,
} from "../../store/reducer/checkout/types";
import { Alert } from "@material-ui/lab";

const Schema = yup.object().shape({
  firstName: yup.string().trim().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  email: yup
    .string()
    .email("Email is invalid")
    .trim()
    .required("Email is required"),
  postcode: yup
    .string()
    .trim()
    .required("Postcode is required")
    .matches(POSTCODE_PATTERN, "Postcode is invalid"),
  line1: yup.string().trim().required("Address line 1 is required"),
  line2: yup.string().trim(),
  city: yup.string().trim().required("City is required"),
  phone: yup
    .string()
    .trim()
    .matches(/([0-9]+|^$)/, "Phone number is invalid")
    .required("Phone Number is Required"),
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: theme.spacing(4),
    width: "100%",
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.dark,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.dark,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: theme.palette.primary.dark,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: theme.palette.primary.dark,
    },
  },
  buttons: {
    textTransform: "none",
    marginTop: 20,
    backgroundColor: theme.palette.text.primary,
  },
  search: {
    marginBottom: theme.spacing(4),
    paddingLeft: 10,
  },
  searchButton: {
    textTransform: "none",
    width: "100%",
    height: "100%",
    border: `2px solid ${theme.palette.secondary.dark}`,
    borderRadius: 4,
    color: theme.palette.secondary.dark,
  },
  popper: {
    border: "1px solid #AFB7BC",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    zIndex: 99,
    borderRadius: "1px 1px 8px 8px",
    maxHeight: 350,
    overflow: "scroll",
  },
  error: {
    marginBottom: 10,
  },
  checkboxContainer: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkboxText: {
    color: "#65727D",
  },
  deliveryText: {
    paddingTop: 20,
    fontWeight: 500,
    fontSize: 14,
  },
  deliveryTextBlue: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
  title: {
    maxWidth: 200,
  },
  hidden: { display: "none" },
}));

export interface AddressFormProps {
  onSubmit(data: Address, includeMarketing: boolean): void;
  onClickPostCodeSearch(p: string): void;
  postCodeLookUpAddresses: PostcodeLookupAddress[];
  address: Address;
  error: string;
  removeMarketing?: boolean;
}

const AddressForm: React.FC<AddressFormProps> = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
  } = useForm({
    validationSchema: Schema,
    defaultValues: emptyAddress,
  });

  useEffect(() => {
    reset(props.address);
  }, [props.address, reset]);

  //Set up the postcode popover
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);

  //This disables the search button until there are at least 3 characters in the postcode input.
  const [isSearchDisabled, SetSearchDisabled] = useState<boolean>(true);
  const [didConsentToMarketing, SetConsentToMarketing] = useState<boolean>(
    false
  );

  const onPostCodeKeyUp = () => {
    SetSearchDisabled(getValues().postcode.length < 3);
  };

  //The Anchor for the popover initially null, gets set on the search button.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  //This gets the width from the postcode input and sets the width of popper.
  const [popperWidth, setPopperWidth] = useState<undefined | number>(0);

  const onSearchClick = () => {
    setAnchorEl(document.getElementById("postcode-input"));
    setPopperWidth(document.getElementById("postcode-input")?.scrollWidth);
    setPopoverOpen(true);
    props.onClickPostCodeSearch(getValues().postcode);
  };

  const onPostcodeSelectClick = (postCodeAddress: PostcodeLookupAddress) => {
    setValue([
      { postcode: postCodeAddress.postcode },
      { line1: postCodeAddress.line1 },
      { line2: postCodeAddress.line2 },
      { city: postCodeAddress.city },
    ]);
    setPopoverOpen(false);
  };

  const handleTitleChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    setValue([{ title: event.target.value as string }]);
  };

  const classes = useStyles();
  return (
    <form
      id={"address-form"}
      className={classes.form}
      noValidate
      autoComplete={"off"}
      onSubmit={handleSubmit((data) =>
        props.onSubmit(data as Address, didConsentToMarketing)
      )}
    >
      <div className={classes.title}>
        <FormControl
          variant={"outlined"}
          className={classes.input}
          error={!!errors.title && !!errors.title.message}
        >
          <InputLabel
            htmlFor={"outlined-title-select"}
            style={{ backgroundColor: "white" }}
          >
            Title
          </InputLabel>
          <Select
            key={"title"}
            value={getValues("title")}
            onChange={handleTitleChange}
            inputRef={register}
            inputProps={{
              name: "title",
              "data-testid": "title",
              id: "outlined-title-select",
            }}
          >
            {["Mr", "Mrs", "Miss", "Ms", "Mx", "Sir", "Dr"].map(
              (title, index) => {
                return (
                  <MenuItem key={`${title}-${index}`} value={title}>
                    {title}
                  </MenuItem>
                );
              }
            )}
          </Select>
          {errors.title && errors.title.message && (
            <FormHelperText>{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <TextField
          name={"title"}
          inputRef={register}
          className={classes.hidden}
        />
      </div>

      <TextField
        className={classes.input}
        variant={"outlined"}
        label={"First name"}
        name={"firstName"}
        error={!!errors.firstName}
        helperText={errors.firstName && errors.firstName.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "firstName",
        }}
      />
      <TextField
        className={classes.input}
        label={"Last name"}
        variant={"outlined"}
        name={"lastName"}
        error={!!errors.lastName}
        helperText={errors.lastName && errors.lastName.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "lastName",
        }}
      />
      <TextField
        className={classes.input}
        label={"Email"}
        variant={"outlined"}
        name={"email"}
        error={!!errors.email}
        helperText={errors.email && errors.email.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "email",
        }}
      />
      {((isPopoverOpen && props.postCodeLookUpAddresses.length === 0) ||
        props.error !== "") && (
        <Alert severity={"error"} className={classes.error}>
          {props.error}
        </Alert>
      )}
      <Grid container>
        <Grid item xs={9}>
          <TextField
            className={classes.input}
            label={"Postcode"}
            variant={"outlined"}
            name={"postcode"}
            error={!!errors.postcode}
            helperText={errors.postcode && errors.postcode.message}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              "data-testid": "postcode",
            }}
            id={"postcode-input"}
            onKeyUp={() => {
              onPostCodeKeyUp();
            }}
          />
          <Popper
            open={isPopoverOpen && props.postCodeLookUpAddresses.length > 0}
            anchorEl={anchorEl}
            placement={"bottom"}
            className={classes.popper}
            style={{ width: popperWidth }}
          >
            <List component={"nav"}>
              {props.postCodeLookUpAddresses.map((address) => {
                return (
                  <ListItem
                    button
                    onClick={() => onPostcodeSelectClick(address)}
                    key={`${address.postcode}+${address.line1}`}
                  >
                    <ListItemText
                      primary={`${address.line1}, ${address.line2}, ${address.city},${address.postcode}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Popper>
        </Grid>
        <Grid item xs={3} className={classes.search}>
          <Button
            className={classes.searchButton}
            onClick={onSearchClick}
            disabled={isSearchDisabled}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      <TextField
        className={classes.input}
        label={"Address line 1"}
        variant={"outlined"}
        name={"line1"}
        error={!!errors.line1}
        helperText={errors.line1 && errors.line1.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "line1",
        }}
      />
      <TextField
        className={classes.input}
        label={"Address line 2 (optional)"}
        variant={"outlined"}
        name={"line2"}
        error={!!errors.line2}
        helperText={errors.line2 && errors.line2.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "line2",
        }}
      />
      <TextField
        className={classes.input}
        label={"City"}
        variant={"outlined"}
        name={"city"}
        error={!!errors.city}
        helperText={errors.city && errors.city.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "city",
        }}
      />
      <TextField
        className={classes.input}
        label={"Phone number"}
        variant={"outlined"}
        name={"phone"}
        error={!!errors.phone}
        helperText={errors.phone && errors.phone.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "phone",
        }}
      />
      {!props.removeMarketing && (
        <div className={classes.checkboxContainer}>
          <div>
            <Checkbox
              onChange={() => SetConsentToMarketing(!didConsentToMarketing)}
              style={{
                paddingLeft: "0px",
              }}
              inputProps={
                {
                  "data-testid": "YourDetailsForm/checkbox",
                } as any
              }
            />
          </div>
          <div>
            <Typography variant={"caption"} className={classes.checkboxText}>
              Optional: I would like to receive health advice and offers from
              Well by email. (You can change your marketing preferences at any
              time)
            </Typography>
          </div>
        </div>
      )}
      <Button
        variant={"contained"}
        color={"secondary"}
        type={"submit"}
        data-testid={"checkout"}
        className={classes.buttons}
      >
        Continue
      </Button>
      <Typography
        variant={"body2"}
        align={"left"}
        className={classes.deliveryText}
      >
        By entering your details and payment information, you are agreeing to
        our
        <a
          className={classes.deliveryTextBlue}
          href={
            "https://www.well.co.uk/about-us/policies/shop-terms-and-conditions"
          }
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          Terms and Conditions.
        </a>
      </Typography>
    </form>
  );
};

export default AddressForm;
