import React from "react";
import {
  Container,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
} from "@material-ui/core";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import CloseIcon from "@material-ui/icons/Close";
import WellLogo from "./Well.svg";
import Basket from "../../components/Basket";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.breakpoints.down("sm") ? theme.spacing(1) : theme.spacing(6),
  },
  header: {
    display: "flex",
    marginLeft: theme.spacing(19),
    alignItems: "center",
    height: theme.spacing(14),
    [theme.breakpoints.down("sm")]: {
      height: 85,
      justifyContent: "space-between",
      margin: theme.spacing(1.5, 2.5),
    },
    color: "#65727D",
    fontSize: "18px",
    lineHeight: "32.4px",
  },
  headerConfirmation: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: theme.spacing(19),
    height: theme.spacing(14),
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(8),
      margin: theme.spacing(1.5, 2.5),
    },
    color: "#65727D",
    fontSize: "18px",
    lineHeight: "32.4px",
  },
  divider: {
    width: "100%",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  wellLogo: {
    maxHeight: "35px",
    maxWidth: "90px",
    objectFit: "contain",
    paddingBottom: theme.spacing(1),
  },
  textContainer: {
    marginLeft: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  overlay: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  floatingBasket: {
    backgroundColor: "white",
  },
  mobileBasketWrapper: {
    position: "absolute",
    width: "100%",
    zIndex: 100,
    height: "100vh",
  },
  showBasketButton: {
    color: "#0C161F",
    display: "flex",
    alignItems: "center",
  },
  basketCount: {
    marginLeft: theme.spacing(1),
  },
  secondaryPanelBreaksUpwards: {
    order: 1,
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  mainPanelBreaksDownwards: {
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
}));

export interface BasketWrapperStateProps {
  totalBasketItems: number;
  show: boolean;
}

export interface BasketWrapperDispatchProps {
  toggleBasket(): void;
}

type BasketWrapperProps = BasketWrapperStateProps & BasketWrapperDispatchProps;

const BasketWrapper: React.FC<BasketWrapperProps> = (props) => {
  const { totalBasketItems, children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div className={classes.header}>
        <Link to={"/"} className={classes.link}>
          <div className={classes.logoContainer}>
            <img src={WellLogo} alt={"WellLogo"} className={classes.wellLogo} />
            <div className={classes.textContainer}>
              <Typography className={classes.link}>Shop</Typography>
            </div>
          </div>
        </Link>
        {isMobile && (
          <div>
            <IconButton data-testid={"close"} onClick={props.toggleBasket}>
              {props.show && <CloseIcon height={"21px"} />}
              {!props.show && (
                <div className={classes.showBasketButton}>
                  <ShoppingCart height={"21px"} />
                  <Typography className={classes.basketCount}>
                    {totalBasketItems}
                  </Typography>
                </div>
              )}
            </IconButton>
          </div>
        )}
      </div>
      <Divider className={classes.divider} />
      <div>
        <Grid
          container
          spacing={isMobile ? 0 : 3}
          direction={"row"}
          className={classes.container}
        >
          <Grid
            item
            xs={12}
            md={8}
            className={classes.mainPanelBreaksDownwards}
          >
            <Container
              className={classes.container}
              maxWidth={"md"}
              disableGutters
            >
              <div>{children}</div>
            </Container>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className={classes.secondaryPanelBreaksUpwards}
          >
            {!isMobile && <Basket width={372} fixed />}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BasketWrapper;
