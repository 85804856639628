import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ImageInfoRow from "./imageInfoRow/ImageInfoRow";
import { Product, SelectedProduct } from "../../store/reducer/products/types";
import { BasketItem } from "../../store/reducer/basket/types";
import { calcBasketItem } from "../../utils/utils";
import TabDetails from "./TabDetails/TabDetails";
import RelatedProducts from "./RelatedProducts/RelatedProducts";
import { useParams } from "react-router-dom";
import { useGAProductImpression } from "../../services/ga";
import analytics from "../../analytics";
import { productPage } from "../../analytics/types";

// Components

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: 20,
    },
  })
);

export interface ProductDetailsStateProps {
  selectedProduct: SelectedProduct;
  fetching: boolean;
  basketItems: BasketItem[];
  maxParacetamol: boolean;
}

export interface ProductDetailsDispatchProps {
  addToBasket(p: Product, q: number): void;
  fetchProduct(sku: string): void;
}
export type ProductDetailsProps = ProductDetailsStateProps &
  ProductDetailsDispatchProps;

const DesktopProductDetails: React.FC<ProductDetailsProps> = ({
  fetchProduct,
  selectedProduct,
  maxParacetamol,
  basketItems,
  addToBasket,
}) => {
  const classes = useStyles();
  const { sku } = useParams<{ sku?: string }>();
  useEffect(() => {
    if (sku !== undefined) {
      fetchProduct(sku);
    }
  }, [fetchProduct, sku]);

  useEffect(() => {
    analytics.trackEvent({
      event: productPage,
      metadata: {
        sku: sku,
        product: selectedProduct.product ? selectedProduct.product.name : "",
      },
    });
  }, [selectedProduct, sku]);

  useGAProductImpression(selectedProduct.product);

  if (
    selectedProduct === undefined ||
    selectedProduct.product === undefined ||
    selectedProduct.product.sku === undefined
  )
    return <></>;
  return (
    <div className={classes.root}>
      <ImageInfoRow
        product={selectedProduct.product}
        maxParacetamol={maxParacetamol}
        basketItem={calcBasketItem(selectedProduct.product, basketItems)}
        basketItems={basketItems}
        addToBasket={addToBasket}
      />
      <TabDetails product={selectedProduct.product} />
      <RelatedProducts
        products={selectedProduct.relatedProducts}
        basketItems={basketItems}
        maxParacetamol={maxParacetamol}
        addToBasket={addToBasket}
      />
    </div>
  );
};

export default DesktopProductDetails;
