import { createMuiTheme } from "@material-ui/core";
import createSpacing from "@material-ui/core/styles/createSpacing";
import GilroyAltBoldEot from "./fonts/GilroyAlt-Bold.eot";
import GilroyAltBoldWoff from "./fonts/GilroyAlt-Bold.woff";
import GilroyAltBoldWoff2 from "./fonts/GilroyAlt-Bold.woff2";
import GilroyAltMediumEot from "./fonts/GilroyAlt-Medium.eot";
import GilroyAltMediumWoff from "./fonts/GilroyAlt-Medium.woff";
import GilroyAltMediumWoff2 from "./fonts/GilroyAlt-Medium.woff2";
import GilroyAltSemiBoldEot from "./fonts/GilroyAlt-SemiBold.eot";
import GilroyAltSemiBoldWoff from "./fonts/GilroyAlt-SemiBold.woff";
import GilroyAltSemiBoldWoff2 from "./fonts/GilroyAlt-SemiBold.woff2";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({
  values: {
    xs: 580,
    sm: 700,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const theme = createMuiTheme({
  breakpoints,
  palette: {
    primary: {
      main: "#233645",
      dark: "#004FEE",
      light: "#E0ECFD",
    },
    text: {
      primary: "#0C161F",
      secondary: "#65727D",
      hint: "#FFFFFF",
    },
    action: {
      active: "#233645",
    },
    secondary: {
      main: "#004FEE",
      light: "#F7FAFF",
      dark: "#1A2936",
    },
    background: {
      default: "white",
      paper: "white",
    },
    error: {
      main: "#B00511",
      light: "#CDCDCD",
    },
  },
  typography: {
    fontFamily: "GilroyAlt",
  },
  spacing: createSpacing(8),
  overrides: {
    MuiTableCell: {
      head: {
        padding: createSpacing(8)(1, 2),
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "GilroyAlt",
            src: ` 
               url('${GilroyAltMediumEot}?#iefix') format('embedded-opentype'),
               url(${GilroyAltMediumWoff2}) format('woff2'),
               url(${GilroyAltMediumWoff}) format('woff')`,
            fontStyle: "normal",
            fontWeight: 500,
            fontDisplay: "swap",
          },
          {
            fontFamily: "GilroyAlt",
            src: `
              url('${GilroyAltSemiBoldEot}?#iefix') format('embedded-opentype'),
               url(${GilroyAltSemiBoldWoff2}) format('woff2'),
               url(${GilroyAltSemiBoldWoff}) format('woff')`,
            fontStyle: "normal",
            fontWeight: 600,
            fontDisplay: "swap",
          },
          {
            fontFamily: "GilroyAlt",
            src: `
               url('${GilroyAltBoldEot}?#iefix') format('embedded-opentype'),
               url(${GilroyAltBoldWoff2}) format('woff2'),
               url(${GilroyAltBoldWoff}) format('woff'),`,
            fontStyle: "normal",
            fontWeight: 700,
            fontDisplay: "swap",
          },
        ],
        body: {
          position: "relative",
        },
      },
    },
  },
});

export default theme;
