import { connect } from "react-redux";
import { RootState } from "../../store/types";
import {
  getBasketItems,
  maxParacetamol,
  getHighlightedCategories,
} from "../../store/selectors";
import ProductGrid, {
  ProductGridDispatchProps,
  ProductGridStateProps,
} from "./ProductGrid";

import { addToBasket } from "../../store/reducer/basket/action";
import { consentToMarketing } from "../../store/reducer/checkout/actions";
import {
  fetchCategoryProducts,
  fetchHighlightProducts,
} from "../../store/reducer/products/actions";

function mapStateToProps(state: RootState): ProductGridStateProps {
  return {
    fetching: false,
    basketItems: getBasketItems(state),
    hasMaxParacetamol: maxParacetamol(state),
    highlightedCategories: getHighlightedCategories(state),
  };
}

const mapDispatchToProps: ProductGridDispatchProps = {
  addToBasket: addToBasket,
  consentToMarketing: consentToMarketing,
  fetchCategoryProducts,
  fetchHighlightProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGrid);
