import React, { ChangeEvent, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import analytics from "../../analytics";
import { consentToMarketing as consentToMarketingEvent } from "../../analytics/types";

export interface MarketingProps {
  consentToMarketing(email: string): void;
  mobile: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 40,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: ({ mobile }: MarketingProps) => (mobile ? 0 : 15),
      paddingRight: ({ mobile }: MarketingProps) => (mobile ? 0 : 15),
      marginLeft: ({ mobile }: MarketingProps) => (mobile ? 0 : 15),
      marginRight: ({ mobile }: MarketingProps) => (mobile ? 0 : 35),
      fontSize: 16,
      width: "100%",
    },
    signUpCol: {
      color: theme.palette.primary.dark,
    },
    box: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.light,
      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 14,
      paddingRight: 14,
      "&:hover": {
        cursor: "pointer",
      },
    },
    mainText: {
      fontSize: 16,
      fontWeight: 600,
      marginLeft: 20,
    },
    icon: {
      float: "right",
      color: theme.palette.primary.dark,
    },
    iconTitle: {
      fontSize: 32,
      color: theme.palette.primary.dark,
    },
    topName: {
      color: "black",
      fontSize: 18,
    },
    popUpText: {
      marginTop: 10,
    },
    clickable: {
      "&:hover": {
        cursor: "pointer",
      },
      border: `1px solid ${theme.palette.primary.dark}`,
      backgroundColor: theme.palette.primary.dark,
      borderRadius: "4px",
      textAlign: "center",
      width: "100%",
      margin: "10px 40px 40px 40px",
    },
    tick: {
      width: "10%",
      color: theme.palette.secondary.main,
      margin: "auto",
    },
    thanks: {
      color: theme.palette.primary.dark,
      fontSize: 34,
    },
    fill: {
      fill: theme.palette.primary.dark,
    },
    popup: {
      padding: 50,
      height: 450,
      width: 531,
    },
    disabled: {
      backgroundColor: theme.palette.text.primary,
      color: "white",
    },
  })
);

function checkIfEmailInString(text: string) {
  const re = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(text);
}

const Marketing: React.FC<MarketingProps> = ({
  consentToMarketing,
  mobile,
}) => {
  const classes = useStyles({ mobile } as MarketingProps);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [didSignUp, setDidSignUp] = React.useState<boolean>(false);

  const handleEmailChange = (el: ChangeEvent<HTMLInputElement>) => {
    setEmail(el.target.value);
  };

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const onSubmit = () => {
    analytics.enableMarketingTracking(email, "", "");
    analytics.trackEvent({
      event: consentToMarketingEvent,
    });
    consentToMarketing(email);
    setDidSignUp(true);
  };

  const tick: JSX.Element = (
    <svg
      width={"51"}
      height={"49"}
      viewBox={"0 0 51 49"}
      xmlns={"http://www.w3.org/2000/svg"}
      className={classes.fill}
    >
      <path
        d={
          "M50.7084 24.5L45.1167 18.1063L45.8959 9.65L37.623 7.77083L33.2917 0.4375L25.5001 3.78333L17.7084 0.4375L13.3772 7.74792L5.10425 9.60417L5.88341 18.0833L0.291748 24.5L5.88341 30.8938L5.10425 39.3729L13.3772 41.2521L17.7084 48.5625L25.5001 45.1938L33.2917 48.5396L37.623 41.2292L45.8959 39.35L45.1167 30.8938L50.7084 24.5ZM21.123 35.3167L12.4147 26.5854L15.8063 23.1938L21.123 28.5333L34.5292 15.0813L37.9209 18.4729L21.123 35.3167Z"
        }
      />
    </svg>
  );

  const preSignUp: JSX.Element = (
    <>
      <DialogTitle id={"form-dialog-title"}>
        <MailOutlineIcon className={classes.iconTitle} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant={"h4"} align={"left"} className={classes.topName}>
            Stay up to date with Well Pharmacy
          </Typography>
          <Typography
            variant={"body1"}
            align={"left"}
            className={classes.popUpText}
          >
            If you would like to receive health advice and offers from Well by
            email, all you have to do is enter your email address below.
          </Typography>
        </DialogContentText>
        <TextField
          autoFocus
          margin={"dense"}
          id={"email"}
          label={"Your Email Address"}
          type={"email"}
          fullWidth
          onChange={handleEmailChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          className={classes.clickable}
          variant={"contained"}
          color={"secondary"}
          disabled={!checkIfEmailInString(email)}
        >
          Subscribe
        </Button>
      </DialogActions>
    </>
  );

  const postSignUp: JSX.Element = (
    <>
      <DialogTitle id={"form-dialog-title"}>
        <div className={classes.tick}>{tick}</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            variant={"h4"}
            align={"center"}
            className={classes.thanks}
          >
            Thanks for Signing up!
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className={classes.clickable}
          variant={"contained"}
          color={"secondary"}
        >
          Close Box
        </Button>
      </DialogActions>
    </>
  );

  return (
    <div className={classes.root}>
      <Grid item xl={12} onClick={handleClickOpen}>
        <Box display={"flex"} className={classes.box}>
          <Box>
            <MailOutlineIcon className={classes.icon} />
          </Box>
          <Box className={classes.mainText}>
            To find out about our latest products and deals,{" "}
            <span className={classes.signUpCol}>sign up</span> to our email list
            today
          </Box>
        </Box>
      </Grid>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby={"form-dialog-title"}
      >
        <div className={classes.popup}>
          {didSignUp ? postSignUp : preSignUp}
        </div>
      </Dialog>
    </div>
  );
};

export default Marketing;
