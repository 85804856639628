import { connect } from "react-redux";
import BasketWrapper, {
  BasketWrapperStateProps,
  BasketWrapperDispatchProps,
} from "./BasketWrapper";
import { RootState } from "../../store/types";
import { getTotalItems, showMobileBasket } from "../../store/selectors";
import { toggleMobileBasket } from "../../store/reducer/basket/action";

function mapStateToProps(state: RootState): BasketWrapperStateProps {
  return {
    totalBasketItems: getTotalItems(state),
    show: showMobileBasket(state),
  };
}

const mapDispatchToProps: BasketWrapperDispatchProps = {
  toggleBasket: toggleMobileBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketWrapper);
