import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Basket from "../Basket";

export interface DesktopCheckoutProps {
  view: JSX.Element;
  title: string;
  withoutBasket?: boolean;
}

const useStyles = (withoutBasket: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        flexShrink: 0,
        width: "100%",
        marginTop: 42,
      },
      basket: {
        marginRight: 0,
        marginLeft: "auto",
      },
      view: {
        width: "100%",
        minWidth: 575,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginRight: withoutBasket ? undefined : 110,
        border: "1px solid #AFB7BC",
      },
      title: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        marginTop: 45,
        marginBottom: 40,
      },
    })
  );

const DesktopCheckout: React.FC<DesktopCheckoutProps> = (props) => {
  const classes = useStyles(!!props.withoutBasket)();

  return (
    <div className={classes.root}>
      <Box display={"flex"}>
        <div className={classes.view}>
          <Typography variant={"h4"} align={"center"} className={classes.title}>
            {props.title}
          </Typography>
          {props.view}
        </div>
      </Box>

      {!props.withoutBasket && (
        <Box>
          <div className={classes.basket}>
            <Basket width={372} fixed />
          </div>
        </Box>
      )}
    </div>
  );
};

export default DesktopCheckout;
