import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
import { Category } from "../../../store/reducer/category/types";
import { Typography } from "@material-ui/core";

const useDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    basketRoot: {
      marginLeft: 32,
      paddingBottom: 48,
    },
    titleContainer: {
      display: "flex",
      marginBottom: 12,
    },
    right: {
      marginRight: 16,
      marginLeft: "auto",
    },
    click: {
      cursor: "pointer",
      fontSize: 15,
      marginTop: 9,
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.primary,
      fontSize: 15,
      marginTop: 20,
    },
    selectedMenuItem: {
      textDecoration: "none",
      color: theme.palette.primary.dark,
      fontSize: 15,
      marginTop: 20,
    },
    menuHeader: {
      flexGrow: 1,
      fontWeight: "bold",
      fontSize: 24,
    },
    categories: {
      fontWeight: 600,
      marginTop: 30,
      fontSize: 18,
    },
    mobileBasketWrapper: {
      position: "fixed",
      width: "100%",
      zIndex: 100,
      height: "100%",
      overflowY: "auto",
      marginTop: 85,
    },
    floatingBasket: {
      backgroundColor: "white",
    },
    overlay: {
      width: "100%",
      height: "200px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  })
);
export interface MobileCatStateProps {
  show: boolean;
  categories: Category[];
}

export interface MobileCatDispatchProps {
  fetchCategories(): void;
  onClose(): void;
}

export type MobileCatProps = MobileCatStateProps & MobileCatDispatchProps;

interface ParamTypes {
  category: string;
}

const MobileCat: React.FC<MobileCatProps> = ({
  show,
  categories,
  onClose,
  fetchCategories,
}) => {
  const classes = useDrawerStyles();
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const { category } = useParams<ParamTypes>();
  return (
    <>
      {show && (
        <div className={classes.mobileBasketWrapper}>
          <div className={classes.floatingBasket}>
            <div className={classes.basketRoot}>
              <div className={classes.titleContainer}>
                <Typography
                  variant={"body1"}
                  align={"right"}
                  className={classes.click}
                  onClick={onClose}
                >
                  Close
                </Typography>
              </div>
              <Typography
                variant={"h6"}
                align={"left"}
                className={classes.categories}
              >
                Categories
              </Typography>
              {categories.map((c) => (
                <Link
                  to={`/category/${c.id}/products`}
                  className={classes.link}
                  onClick={() => onClose()}
                  key={c.id}
                >
                  {c.id?.toLocaleLowerCase() === category?.toLowerCase() ? (
                    <Typography
                      key={c.id}
                      variant={"body1"}
                      align={"left"}
                      className={`${classes.click} ${classes.selectedMenuItem}`}
                    >
                      {c.name}
                    </Typography>
                  ) : (
                    <Typography
                      key={c.id}
                      variant={"body1"}
                      align={"left"}
                      className={`${classes.click} ${classes.link}`}
                    >
                      {c.name}
                    </Typography>
                  )}
                </Link>
              ))}
            </div>
          </div>
          <div className={classes.overlay} onClick={() => onClose()} />
        </div>
      )}
    </>
  );
};

export default MobileCat;
