import { Container, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
//Components.
import Banner from "../Banner/Banner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 15,
      maxWidth: "900px",
    },
    view: {
      width: "100%",
      border: "1px solid #AFB7BC",
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      marginTop: 45,
      marginBottom: 40,
    },
  })
);

export interface CheckoutPropsFromParent {
  view: JSX.Element;
  title: string;
}

const B2B: React.FC<CheckoutPropsFromParent> = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Banner />
      <Container className={classes.container}>
        <div className={classes.view}>
          <Typography variant={"h4"} align={"center"} className={classes.title}>
            {props.title}
          </Typography>
          {props.view}
        </div>
      </Container>
    </div>
  );
};

export default B2B;
