type Event =
  | [string]
  | [string, Record<string, unknown>]
  | [string, string, Record<string, unknown>];

export default class Klavyio {
  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  public initialize() {
    // https://www.klaviyo.com/docs/getting-started
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.async = true;
    script.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${this.key}`;
    head.appendChild(script);
  }

  public setUser(email: string, firstName: string, lastName: string) {
    this.pushEvent([
      "identify",
      {
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
      },
    ]);
  }

  public trackEvent(name: string, metadata?: Record<string, unknown>) {
    this.pushEvent(["track", name, metadata || {}]);
  }

  private pushEvent(event: Event) {
    const _learnq = (window as any)._learnq || [];
    _learnq.push(event);
  }
}
