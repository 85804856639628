import { connect } from "react-redux";
import {
  createB2BPaymentToken,
  startPaymentRequestProcess,
} from "../../store/reducer/b2bPayments/actions";
import {
  getB2BPaymentsRequestToken,
  getB2BPaymentsRequestTokenError,
  getB2BPaymentsRequestTokenLoading,
} from "../../store/selectors";
import { RootState } from "../../store/types";
import B2BPaymentRequest, {
  B2BPaymentRequestDispatchProps,
  B2BPaymentRequestStateProps,
} from "./Screen";

function mapStateToProps(state: RootState): B2BPaymentRequestStateProps {
  return {
    token: getB2BPaymentsRequestToken(state),
    tokenLoading: !!getB2BPaymentsRequestTokenLoading(state),
    tokenError: getB2BPaymentsRequestTokenError(state),
  };
}

const mapDispatchToProps: B2BPaymentRequestDispatchProps = {
  onLoad: startPaymentRequestProcess,
  onSubmit: createB2BPaymentToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(B2BPaymentRequest);
