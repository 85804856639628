import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  AddOutlined,
  RemoveOutlined,
  Close as CloseIcon,
} from "@material-ui/icons";

//Types
import { BasketItem as BasketItemType } from "../../../store/reducer/basket/types";
import { Product } from "../../../store/reducer/products/types";

//Components
import ListImage from "../../ListImage/ListImage";
import { Typography, Grid } from "@material-ui/core";
import { getCostStr } from "../../../utils/utils";

export interface BasketItemProps {
  item: BasketItemType;
  key: string;
  addToBasket(p: Product, q: number): void;
  addDisabled: boolean;
  checkout: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    rowImage: {
      marginTop: 20,
    },
    image: {
      width: 40,
      height: 40,
      minWidth: 40,
      marginLeft: 15,
    },
    crossRoot: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    cross: {
      color: theme.palette.error.main,
      fontSize: 15,
      width: "100%",
      margin: "auto",
      marginTop: 1,
    },
    rowButton: {
      marginTop: 30,
      marginLeft: 86,
      marginBottom: 15,
    },
    qty: {
      height: 32,
      width: 32,
      fontSize: 14,
      fontWeight: 500,
      backgroundColor: "#F5F5F5",
      paddingLeft: 12,
      color: theme.palette.text.primary,
    },
    button: {
      height: 32,
      width: 32,
      backgroundColor: "#F5F5F5",
      "&:hover": {
        cursor: "pointer",
      },
      margin: "auto",
    },
    icon: {
      fontSize: 28,
      margin: "auto",
      color: theme.palette.primary.dark,
      paddingLeft: 5,
      paddingTop: 5,
    },
    cost: {
      marginRight: 30,
      fontSize: 16,
      fontWeight: 600,
    },
    disabled: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.error.main,
      paddingLeft: 20,
      paddingTop: 10,
    },
  })
);

const BasketItem: React.FC<BasketItemProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.itemRoot}>
      <div className={classes.rowImage}>
        <Grid container>
          <Grid item xs={3}>
            <div className={classes.image}>
              <ListImage
                imageUrl={props.item.product.imageUrl}
                productName={props.item.product.name}
                iconSize={24}
              />
            </div>
          </Grid>
          <Grid item xs={7}>
            <Typography variant={"body1"} align={"left"}>
              {props.item.product.name}
            </Typography>
          </Grid>
          <Grid xs={2}>
            {!props.checkout && (
              <div
                className={classes.crossRoot}
                onClick={() => {
                  props.addToBasket(props.item.product, -props.item.qty);
                }}
              >
                <CloseIcon className={classes.cross} />
              </div>
            )}
          </Grid>
        </Grid>
        {props.addDisabled && !props.checkout ? (
          <Typography variant={"body1"} className={classes.disabled}>
            Unable to add more of this item
          </Typography>
        ) : (
          <Typography variant={"body1"} className={classes.disabled}>
            &nbsp;
          </Typography>
        )}
        <div className={classes.rowButton}>
          <Grid container>
            <Grid item xs={2}>
              <div className={classes.qty}>
                <Typography variant={"h6"} align={"left"}>
                  {props.item.qty}
                </Typography>
              </div>
            </Grid>
            {!props.checkout && (
              <>
                <Grid item xs={2}>
                  <div
                    className={classes.button}
                    onClick={() => {
                      props.addToBasket(props.item.product, -1);
                    }}
                  >
                    <RemoveOutlined className={classes.icon} />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  {!props.addDisabled && (
                    <div
                      className={classes.button}
                      onClick={() => {
                        props.addToBasket(props.item.product, 1);
                      }}
                    >
                      <AddOutlined className={classes.icon} />
                    </div>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography
                variant={"h6"}
                align={"right"}
                className={classes.cost}
              >
                £{getCostStr(props.item.product.cost * props.item.qty)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default BasketItem;
