import React, { useState } from "react";
import { Grid, Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MenuIcon from "@material-ui/icons/Menu";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import Search from "../Search";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

export interface MobileSearchPanelProps {
  totalItems: number;
  onCartClick(): void;
  onCatClick(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridRoot: {
      paddingTop: 40,
      width: "100%",
      position: "sticky",
      backgroundColor: theme.palette.primary.light,
      paddingBottom: 48,
      top: 0,
      zIndex: 99,
      // height: 85,
    },
    icon: {
      color: theme.palette.text.primary,
      fontSize: 25,
      margin: "auto",
      display: "block",
    },
    logo: {
      height: 20,
      margin: "auto",
      display: "block",
    },
    cartNumber: {
      background: theme.palette.primary.dark,
      color: theme.palette.text.hint,
      paddingLeft: ({ totalItems }: MobileSearchPanelProps) =>
        totalItems >= 10 ? 2 : 4,
      borderRadius: 50,
      marginLeft: -10,
      marginTop: -5,
      height: 12,
      width: 12,
      fontSize: 9,
    },
    box: {
      justifyContent: "center",
    },
    search: {
      marginTop: -5,
      paddingRight: 20,
    },
  })
);

const MobileSearchPanel: React.FC<MobileSearchPanelProps> = ({
  totalItems,
  onCartClick,
  onCatClick,
}) => {
  const classes = useStyles({ totalItems } as MobileSearchPanelProps);
  const [shouldShowSearch, setShouldShowSearch] = useState<boolean>(false);

  return (
    <div className={classes.gridRoot}>
      {shouldShowSearch ? (
        <Grid container>
          <Grid item xs={3}>
            <div className={classes.logo}>
              <Link to={"/"}>
                <Logo />
              </Link>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.search}>
              <Search />
            </div>
          </Grid>
          <Grid item xs={1}>
            <CloseIcon
              className={classes.icon}
              onClick={() => setShouldShowSearch(false)}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container justify={"space-evenly"}>
          <Grid item xs={2}>
            <MenuIcon className={classes.icon} onClick={onCatClick} />
          </Grid>
          <Grid item xs={2}>
            <a href={"https://finder.well.co.uk/"} rel={"noopener noreferrer"}>
              <LocationOnIcon className={classes.icon} />
            </a>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.logo}>
              <Link to={"/"}>
                <Logo />
              </Link>
            </div>
          </Grid>
          <Grid item xs={2}>
            <SearchIcon
              className={classes.icon}
              onClick={() => setShouldShowSearch(true)}
            />
          </Grid>
          <Grid item xs={2}>
            <Box display={"flex"} className={classes.box}>
              <Box>
                <ShoppingCartIcon
                  className={classes.icon}
                  onClick={onCartClick}
                />
              </Box>
              <Box>
                <div className={classes.cartNumber}>{totalItems}</div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default MobileSearchPanel;
