import { handleActions } from "redux-actions";
import {
  Category,
  State,
  FetchCategoriesResponseAction,
  FETCH_CATEGORIES_RESPONSE,
  SELECT_CATEGORY,
  SelectCategoryAction,
  FETCH_CATEGORIES_REQUEST,
} from "./types";
import { AnyAction, combineReducers, Reducer } from "redux";

const list = handleActions(
  {
    [FETCH_CATEGORIES_RESPONSE]: (_state: Category[], action: AnyAction) => {
      const a = action as FetchCategoriesResponseAction;
      if (a.payload) {
        return a.payload.categories;
      }
      return [];
    },
    [FETCH_CATEGORIES_REQUEST]: () => [],
  },
  []
);

const selectedCategoryId = handleActions(
  {
    [SELECT_CATEGORY]: (_state: string | null, action: AnyAction) => {
      const a = action as SelectCategoryAction;
      return a.payload.categoryId || null;
    },
  },
  null
);

const reducer: Reducer<State, any> = combineReducers({
  list,
  selectedCategoryId,
});

export default reducer;
