import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import React, { useState, useCallback } from "react";
import Footer from "./components/Footer/Footer";
import MobileBasket from "./components/MobileBasket";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Routes from "./routes/Router";
import theme from "./theme";
import { useAwinMasterTracker } from "./services/awin";
import { AnyAction, Dispatch } from "redux";
import { setAwcToken } from "./store/reducer/checkout/actions";
import PopUpBasket from "./components/PopUpBasket";
import MobileCat from "./components/Categories/MobileCategories";
import usePrismicPreview from "./services/prismic";
import { CookiesProvider } from "react-cookie";
import RightArrow from "./assets/rightArrow";
import { cookie } from "@welldigital/ui-common/Components/CookiesManager/helpers";
import { CookieManagerModal } from "./components/Cookie/CookieManagerModal";
import { CookiesPreferenceNames } from "@welldigital/ui-common/Components/CookiesManager/constants";
import { Panel } from "@welldigital/ui-common/Components/CookiesManager";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cookiesPreferencesLink: {
      position: "absolute",
      bottom: 0,
      left: 0,
      padding: 16,
      display: "block",
      zIndex: 100,
      textDecoration: "none",
      fontSize: "16px",
      lineHeight: "25.6px",
      fontWeight: 500,
      color: "#fff",
      backgroundColor: "#083149",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
);

interface AppProps {
  dispatch: Dispatch<AnyAction>;
}

const App: React.FC<AppProps> = (props) => {
  const classes = useStyles();

  const [currentCookieBannerPanel, setCurrentCookieBannerPanel] = useState(
    cookie.get(CookiesPreferenceNames.cookiePreferencesSet) !== "true"
      ? Panel.home
      : null
  );
  const closeCookiesBanner = useCallback(() => {
    setCurrentCookieBannerPanel(null);
  }, []);

  const launchCookiesBanner = useCallback((evt) => {
    evt.preventDefault();
    setCurrentCookieBannerPanel(Panel.preferences);
  }, []);

  useAwinMasterTracker({
    setAWCToken: (t) => props.dispatch(setAwcToken(t)),
  });

  usePrismicPreview();
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <ScrollToTop />
        <CssBaseline />
        <MobileBasket />
        <MobileCat />
        <PopUpBasket />
        <Routes />
        <CookieManagerModal
          onDone={closeCookiesBanner}
          onPanelChangeIntent={setCurrentCookieBannerPanel}
          panel={currentCookieBannerPanel}
        />
        <Link
          onClick={launchCookiesBanner}
          className={classes.cookiesPreferencesLink}
          href={"#"}
        >
          <span
            style={{
              transform: "rotate(-90deg)",
              display: "inline-block",
              marginRight: 10,
              textSizeAdjust: "100%",
            }}
          >
            <RightArrow />
          </span>
          Cookie preferences
        </Link>
        <Footer />
      </CookiesProvider>
    </ThemeProvider>
  );
};

export default App;
