import { connect } from "react-redux";
import {
  closeDesktopBasket,
  closeMobileBasket,
  setCheckout,
  setPayment,
  setPaymentDetails,
  setProduct,
} from "../../store/reducer/basket/action";

import RouterWrapper, { RouterWrapperDispatchProps } from "./RouterWrapper";

const mapDispatchToProps: RouterWrapperDispatchProps = {
  setProduct: setProduct,
  setCheckout: setCheckout,
  setPayment: setPayment,
  setPatientDetails: setPaymentDetails,
  closeMobileBasket: closeMobileBasket,
  closeDesktopBasket: closeDesktopBasket,
};

export default connect(null, mapDispatchToProps)(RouterWrapper);
