import { useEffect } from "react";
import qs from "qs";
import Prismic from "@prismicio/client";
import { useHistory, useLocation } from "react-router-dom";
import { PrismicEndPoint } from "../services/prismic";

// In src/prismic-configuration.js
const linkResolver = (doc: any) => {
  // URL for a category type
  if (doc.type === "category") {
    return `/category/${doc.uid}/products`;
  }

  // Backup for all other types
  return "/";
};

const client = Prismic.client(PrismicEndPoint);
const Preview = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const { token, documentId } = qs.parse(location.search.slice(1));
    if (!token) {
      return console.warn(`No token available, check your configuration`);
    }
    client
      .getPreviewResolver(token as string, documentId as string)
      .resolve(linkResolver, "/")
      .then((url) => history.push(url));
  });
  return null;
};

export default Preview;
