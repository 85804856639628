import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Product } from "../store/reducer/products/types";

export function useGATracker() {
  // Initialize the GA sdk.
  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
      ReactGA.plugin.require("ec");
      if (process.env.REACT_APP_INDICATIVE_KEY) {
        loadIndicativePlugin();
        ReactGA.plugin.require("indGaPlugin", {
          apikey: process.env.REACT_APP_INDICATIVE_KEY,
        });
      }
    }
  }, []);
  // Track page every time the location changes.
  const location = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      trackCheckoutStep(location.pathname);
      ReactGA.pageview(location.pathname);
    }
  }, [location]);
}

export function loadIndicativePlugin() {
  const el = document.createElement("script");
  el.async = true;
  el.src =
    "https://cdn.indicative.com/indicative-ga-plugin/0.1.0/indicative-ga-plugin.js";
  const m = document.getElementsByTagName("script")[0];
  m.parentNode?.insertBefore(el, m);
}

const CHECKOUT_STEPS: Record<string, number> = {
  "/shipping": 1,
  "/billing": 2,
  "/payment": 3,
};

export function trackCheckoutStep(location: string) {
  const step = CHECKOUT_STEPS[location];
  if (!step) return;
  ReactGA.plugin.execute("ec", "setAction", "checkout", {
    step,
  });
}

export function useGAProductImpression(product: Product | null) {
  useEffect(() => {
    if (!process.env.REACT_APP_GA_ID || !product) return;
    ReactGA.plugin.execute("ec", "addProduct", {
      id: product.sku,
      name: product.name,
      categories: product.categories,
    });
    ReactGA.plugin.execute("ec", "setAction", "detail");
    ReactGA.event({
      category: "ecommerce",
      action: "detail",
      nonInteraction: true,
    });
  }, [product]);
}

export default useGATracker;
