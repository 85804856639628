import { RSAA } from "redux-api-middleware";
import { API_ENDPOINT } from "../../config";

import {
  FetchCategoryProductsAction,
  FetchHighlightProductsAction,
  FetchProductDetailsAction,
  FetchProductSalesCountAction,
  FETCH_CAT_PRODUCTS,
  FETCH_PRODUCTS_HIGHLIGHT,
  FETCH_PRODUCT_DETAIL,
  FETCH_PRODUCT_SALES_COUNT,
  FETCH_PRODUCT_SALES_COUNT_REQUEST,
  FETCH_PRODUCT_SALES_COUNT_RESPONSE,
  FETCH_PRODUCT_SALES_COUNT_FAILURE,
} from "./types";

//Used for prod list page to get highlight cats and highlight prods of cat
export function fetchHighlightProducts(): FetchHighlightProductsAction {
  return {
    type: FETCH_PRODUCTS_HIGHLIGHT,
  };
}

//Used to get all prods for category
export function fetchCategoryProducts(
  cat: string
): FetchCategoryProductsAction {
  return {
    type: FETCH_CAT_PRODUCTS,
    payload: {
      category: cat,
    },
  };
}

export function fetchProductDetail(sku: string): FetchProductDetailsAction {
  return {
    type: FETCH_PRODUCT_DETAIL,
    payload: {
      sku,
    },
  };
}

export function fetchProductSalesCount(
  skus: string[],
  endpoint: string = API_ENDPOINT
): FetchProductSalesCountAction {
  const uniqueSkus = skus.filter((item, pos) => {
    return skus.indexOf(item) === pos;
  });
  return {
    type: FETCH_PRODUCT_SALES_COUNT,
    [RSAA]: {
      endpoint: `${endpoint}/products/salescount?${uniqueSkus.reduce(
        (accumulator, currentVal, index) =>
          index === 1
            ? `sku=${accumulator}&sku=${currentVal}`
            : `${accumulator}&sku=${currentVal}`
      )}`,
      method: "GET",
      types: [
        FETCH_PRODUCT_SALES_COUNT_REQUEST,
        FETCH_PRODUCT_SALES_COUNT_RESPONSE,
        FETCH_PRODUCT_SALES_COUNT_FAILURE,
      ],
    },
  };
}
