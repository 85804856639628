import React, { useState } from "react";
import { Product } from "../../../store/reducer/products/types";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, useTheme, useMediaQuery } from "@material-ui/core";
import { RichText } from "prismic-reactjs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      marginTop: 20,
      margin: "auto",
      textTransform: "none",
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
    },
    tabs: {
      color: theme.palette.primary.main,
      backgroundColor: "#FAFAFA",
    },
    details: {
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: 4,
      padding: "20px 120px 20px 20px",
    },
    text: {
      marginTop: 10,
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
    },
    tabRoot: {
      fontSize: 16,
      fontWeight: 600,
      textTransform: "none",
    },
    selectedTab: {
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export interface TabDetailsProps {
  product: Product;
}

const TabDetails: React.FC<TabDetailsProps> = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (
    event: React.ChangeEvent<Record<string, never>>,
    newValue: number
  ) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down("sm"));
  let isVertical = false;
  if (isSmallerThanMd) {
    isVertical = true;
  }
  return (
    <div className={classes.root}>
      <AppBar position={"static"} className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          className={classes.tabs}
          variant={"fullWidth"}
          orientation={isVertical ? "vertical" : "horizontal"}
          classes={{
            indicator: classes.selected,
          }}
        >
          <Tab
            classes={{ selected: classes.selectedTab, root: classes.tabRoot }}
            label={"Product description"}
          />
          <Tab
            classes={{ selected: classes.selectedTab, root: classes.tabRoot }}
            label={"Ingredients"}
          />
          <Tab
            classes={{ selected: classes.selectedTab, root: classes.tabRoot }}
            label={"Directions for use"}
          />
          <Tab
            classes={{ selected: classes.selectedTab, root: classes.tabRoot }}
            label={"Warnings and cautions"}
          />
        </Tabs>
      </AppBar>

      <div className={classes.details}>
        {value === 0 && (
          <div>
            <RichText render={props.product.description} />
          </div>
        )}
        {value === 1 && (
          <div>
            <RichText render={props.product.ingredients} />
          </div>
        )}
        {value === 2 && (
          <div>
            <RichText render={props.product.directions} />
          </div>
        )}
        {value === 3 && (
          <div>
            <RichText render={props.product.warnings} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TabDetails;
