import { AnyAction, combineReducers, Reducer } from "redux";
import { handleActions } from "redux-actions";
import { calcInventoryCount } from "../../utils/inventory";
import {
  FetchProductSalesCountResponseAction,
  FETCH_PRODUCT_SALES_COUNT_RESPONSE,
} from "../products/types";
import {
  AddToBasketAction,
  ADD_TO_BASKET,
  BasketItem,
  BASKET_SET_CHECKOUT_ACTIVE,
  BASKET_SET_PAYMENT_ACTIVE,
  BASKET_SET_PRODUCT_ACTIVE,
  CLOSE_MOBILE_BASKET,
  State,
  TOGGLE_MOBILE,
  BASKET_SET_PATIENT_DETAILS_ACTIVE,
  CLEAN_BASKET,
  TOGGLE_DESKTOP,
  CLOSE_DESKTOP_BASKET,
  TOGGLE_MOBILE_CAT,
} from "./types";

const items = handleActions(
  {
    [ADD_TO_BASKET]: (state: BasketItem[], action: AnyAction): BasketItem[] => {
      const a = action as AddToBasketAction;
      // First loop though the existing state to see if we need to increase or decrease the quantity.
      let isFound = false;
      state.forEach((basketItem, index, object) => {
        if (basketItem.product.sku === a.payload.product.sku) {
          // Check if we need to remove the product completely from the basket.
          if (basketItem.qty + a.payload.qty <= 0) {
            object.splice(index, 1);
          } else {
            basketItem.qty += a.payload.qty;
          }
          isFound = true;
        }
      });
      if (isFound) {
        return [...state];
      }
      const basketItem: BasketItem = {
        product: a.payload.product,
        qty: a.payload.qty,
      };
      state.push(basketItem);
      return [...state];
    },
    [CLEAN_BASKET]: () => [],
    [FETCH_PRODUCT_SALES_COUNT_RESPONSE]: (
      state: BasketItem[],
      action: AnyAction
    ): BasketItem[] => {
      const a = action as FetchProductSalesCountResponseAction;
      if (!state.length) {
        return [...state];
      }
      // Remove items with no inventory
      // !! is force to be a boolean so (!!a.payload.counts[item.product.sku]) checks to see if it exists in the map or not
      const items = state
        .filter(
          (item) =>
            !!!a.payload.counts[item.product.sku] ||
            (!!a.payload.counts[item.product.sku] &&
              calcInventoryCount(
                a.payload.counts[item.product.sku],
                item.product.maxDailyCount
              ) > 0)
        )
        .map((item) => {
          // Set quantity to available inventory
          if (!!a.payload.counts[item.product.sku]) {
            const inventory = calcInventoryCount(
              a.payload.counts[item.product.sku],
              item.product.maxDailyCount
            );
            if (inventory < item.qty) {
              item.qty = inventory;
            }
          }
          return item;
        });
      return items;
    },
  },
  []
);

const showMobileBasket = handleActions(
  {
    [TOGGLE_MOBILE]: (state: boolean): boolean => {
      document.body.style.overflowY = state ? "visible" : "hidden";
      return !state;
    },
    [CLOSE_MOBILE_BASKET]: (): boolean => false,
    [TOGGLE_MOBILE_CAT]: (state: boolean): boolean => false,
  },
  false
);

const showDesktopBasket = handleActions(
  {
    [TOGGLE_DESKTOP]: (state: boolean): boolean => !state,
    [CLOSE_DESKTOP_BASKET]: (): boolean => false,
  },
  false
);

const showMobileCat = handleActions(
  {
    [TOGGLE_MOBILE_CAT]: (state: boolean): boolean => {
      document.body.style.overflowY = state ? "visible" : "hidden";
      return !state;
    },
    [TOGGLE_MOBILE]: (state: boolean): boolean => false,
  },
  false
);

const checkoutActive = handleActions(
  {
    [BASKET_SET_PATIENT_DETAILS_ACTIVE]: (): boolean => false,
    [BASKET_SET_CHECKOUT_ACTIVE]: (): boolean => true,
    [BASKET_SET_PAYMENT_ACTIVE]: (): boolean => false,
    [BASKET_SET_PRODUCT_ACTIVE]: (): boolean => false,
  },
  false
);

const paymentActive = handleActions(
  {
    [BASKET_SET_PATIENT_DETAILS_ACTIVE]: (): boolean => false,
    [BASKET_SET_CHECKOUT_ACTIVE]: (): boolean => false,
    [BASKET_SET_PAYMENT_ACTIVE]: (): boolean => true,
    [BASKET_SET_PRODUCT_ACTIVE]: (): boolean => false,
  },
  false
);

const productActive = handleActions(
  {
    [BASKET_SET_PATIENT_DETAILS_ACTIVE]: (): boolean => false,
    [BASKET_SET_CHECKOUT_ACTIVE]: (): boolean => false,
    [BASKET_SET_PAYMENT_ACTIVE]: (): boolean => false,
    [BASKET_SET_PRODUCT_ACTIVE]: (): boolean => true,
  },
  false
);

const patientDetailsActive = handleActions(
  {
    [BASKET_SET_PATIENT_DETAILS_ACTIVE]: (): boolean => true,
    [BASKET_SET_CHECKOUT_ACTIVE]: (): boolean => false,
    [BASKET_SET_PAYMENT_ACTIVE]: (): boolean => false,
    [BASKET_SET_PRODUCT_ACTIVE]: (): boolean => false,
  },
  false
);

const reducer: Reducer<State, any> = combineReducers({
  items,
  showMobileBasket,
  showMobileCat,
  showDesktopBasket,
  checkoutActive,
  paymentActive,
  productActive,
  patientDetailsActive,
});

export default reducer;
