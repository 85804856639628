import { State, BasketItem } from "./types";

export function getTotalItems(state: State): number {
  return state.items.reduce((total, item) => (total += item.qty), 0);
}

export function getTotalCost(state: State): number {
  return state.items.reduce(
    (total, item) => (total += item.qty * item.product.cost),
    0
  );
}

export function getBasketItems(state: State): BasketItem[] {
  return state.items;
}

export function showMobileBasket(state: State): boolean {
  return state.showMobileBasket;
}

export function showDesktopBasket(state: State): boolean {
  return state.showDesktopBasket;
}

export function showMobileCat(state: State): boolean {
  return state.showMobileCat;
}

// Max of two paracetamol items in a basket
export function maxParacetamol(state: State): boolean {
  const totalPara = state.items
    .filter((item) => item.product.containsParacetamol)
    .reduce((total, item) => (total += item.qty), 0);

  return totalPara >= 2;
}

export const patientDetailsActive = (state: State) =>
  state.patientDetailsActive;

export function paymentActive(state: State): boolean {
  return state.paymentActive;
}

export function checkoutActive(state: State): boolean {
  return state.checkoutActive;
}

export function productActive(state: State): boolean {
  return state.productActive;
}
