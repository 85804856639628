import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.secondary.dark,
    width: "100%",
    zIndex: 2,
    position: "relative",
    paddingBottom: 20,
  },
  container: {
    textDecoration: "none",
  },
  content: {
    color: theme.palette.text.hint,
    alignSelf: "center",
    fontSize: 16,
    fontWeight: 600,
  },
  max: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 1660,
    paddingTop: 13,
  },
}));

export interface NewsBannerProps {
  show: boolean;
}

const NewsBanner: React.FC<NewsBannerProps> = ({ show }) => {
  const classes = useStyles();
  return (
    <>
      {show && (
        <div className={classes.root}>
          <div className={classes.max}>
            <a
              href={"https://www.well.co.uk/bulk-purchasing"}
              target={"_blank"}
              rel={"noopener noreferrer"}
              className={classes.container}
            >
              <Typography className={classes.content} align={"center"}>
                You can now order in bulk. Click here for more information.
              </Typography>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default NewsBanner;
