import { connect } from "react-redux";
import { patientDetailsSubmitted } from "../../store/reducer/checkout/actions";
import { RootState } from "../../store/types";
import PatientDetails, {
  PatientDetailsDispatchProps,
  PatientDetailsStateProps,
} from "./PatientDetails";
import { getCheckoutPatientDetails } from "../../store/selectors";

function mapStateToProps(state: RootState): PatientDetailsStateProps {
  return {
    patientDetails: getCheckoutPatientDetails(state),
  };
}

const mapDispatchToProps: PatientDetailsDispatchProps = {
  onSubmit: patientDetailsSubmitted,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
