import { connect } from "react-redux";
import { toggleDesktopBasket } from "../../store/reducer/basket/action";
import { showDesktopBasket } from "../../store/selectors";
import { RootState } from "../../store/types";
import PopUpBasket, {
  PopUpBasketStateProps,
  PopUpBasketDispatchProps,
} from "./PopUpBasket";

function mapStateToProps(state: RootState): PopUpBasketStateProps {
  return {
    show: showDesktopBasket(state),
  };
}

const mapDispatchToProps: PopUpBasketDispatchProps = {
  setClose: toggleDesktopBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUpBasket);
