import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { emptyPaymentRequestData } from "../../store/reducer/b2bPayments";
import { PaymentRequestData } from "../../store/reducer/b2bPayments/types";
import { formButtonLabel, requestFormTopInfoParagraphs } from "./copy";
import Schema from "./formSchema";
import useFormStyles from "./formStyles";

export interface FormProps {
  onSubmit(data: PaymentRequestData): void;
  loading: boolean;
  error: string | null;
}

const Form: React.FC<FormProps> = ({ onSubmit, loading, error }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema,
    defaultValues: emptyPaymentRequestData,
  });

  const classes = useFormStyles();
  return (
    <form
      id={"b2b-payment-request-form"}
      noValidate
      autoComplete={"off"}
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <Grid container direction={"column"} spacing={2}>
        {requestFormTopInfoParagraphs.map((p, i) => (
          <Grid key={i} item>
            <Typography>{p}</Typography>
          </Grid>
        ))}
        <Grid item>
          <TextField
            className={classes.input}
            variant={"outlined"}
            label={"Order reference"}
            name={"reference"}
            error={!!errors.reference}
            helperText={errors.reference && errors.reference.message}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              "data-testid": "reference",
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.input}
            variant={"outlined"}
            type={"number"}
            label={"Amount"}
            name={"amount"}
            error={!!errors.amount}
            helperText={errors.amount && errors.amount.message}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              "data-testid": "amount",
              step: "any",
              min: 1,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.input}
            variant={"outlined"}
            label={"Password"}
            type={"password"}
            name={"password"}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              "data-testid": "password",
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant={"contained"}
            color={"secondary"}
            type={"submit"}
            disabled={loading}
            data-testid={"b2b-payment-request-continue"}
            className={classes.buttons}
          >
            {loading ? "Loading..." : formButtonLabel}
          </Button>
        </Grid>
        {error && (
          <Grid item>
            <Typography color={"error"}>{error}</Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default Form;
