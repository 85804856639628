export interface AnalyticsEvent {
  event: string;
  metadata?: Record<string, unknown>;
}

export const consentToMarketing = "shop:consent-to-marketing";
export const shippingDetailsEntered = "shop:shipping-details-entered";
export const billingDetailsEntered = "shop:billing-details-entered";
export const paymentRequested = "shop:payment-requested";
export const paymentConfirmed = "shop:payment-confirmed";
export const paymentConfirmedItem = "shop:payment-confirmed-item";
export const productSearch = "shop:product-search";
export const landingPage = "shop:landing-page";
export const productPage = "shop:product-page";
export const categoryPage = "shop:category-page";
export const shippingPage = "shop:shipping-page";
export const billingPage = "shop:billing-page";
export const addToBasket = "shop:add-to-basket";
export const paymentPage = "shop:payment-page";
export const completePage = "shop:complete-page";
