import moment from "moment";
import { AnyAction, combineReducers, Reducer } from "redux";
import { handleActions } from "redux-actions";
import {
  FETCH_B2B_BRAINTREE_TOKEN_REQUEST,
  FETCH_B2B_BRAINTREE_TOKEN_RESPONSE,
} from "../b2bPayments/types";
import {
  AddAddressAction,
  Address,
  ADD_BILLING_ADDRESS,
  ADD_SHIPPING_ADDRESS,
  CLEAR_CHECKOUT,
  emptyAddress,
  FetchAddressFromPostcodeActionResponse,
  FetchB2BBraintreeTokenResponseAction,
  FetchBraintreeTokenResponseAction,
  FETCH_ADDRESS_FROM_POSTCODE_FAILURE,
  FETCH_ADDRESS_FROM_POSTCODE_RESPONSE,
  FETCH_BRAINTREE_TOKEN_REQUEST,
  FETCH_BRAINTREE_TOKEN_RESPONSE,
  ON_PAY_FAILURE,
  ON_PAY_REQUEST,
  ON_PAY_RESPONSE,
  OnPayResponseAction,
  PatientDetails,
  PatientDetailsSubmittedAction,
  PATIENT_DETAILS_SUBMITTED,
  PaymentError,
  PaymentFailureResponseAction,
  PostcodeLookupAddress,
  SetPaymentErrorAction,
  SET_PAYMENT_ERROR,
  State,
  SET_AWC_TOKEN,
  SetAWCTokenAction,
} from "./types";

const err = handleActions(
  {
    [ON_PAY_FAILURE]: (_state: PaymentError | null, action: AnyAction) => {
      const a = action as PaymentFailureResponseAction;
      return a.payload.response as PaymentError;
    },
    [SET_PAYMENT_ERROR]: (_: PaymentError | null, action: AnyAction) => ({
      message: (action as SetPaymentErrorAction).payload.message,
    }),
    [ON_PAY_REQUEST]: () => null,
    [FETCH_BRAINTREE_TOKEN_REQUEST]: () => null,
    [FETCH_B2B_BRAINTREE_TOKEN_REQUEST]: () => null,
    [CLEAR_CHECKOUT]: () => null,
  },
  null
);

const pending = handleActions(
  {
    [ON_PAY_REQUEST]: () => true,
    [ON_PAY_RESPONSE]: () => false,
    [ON_PAY_FAILURE]: () => false,
  },
  false
);

const defaultAddress: Address = {
  title: "",
  firstName: "",
  lastName: "",
  email: "",
  postcode: "",
  line1: "",
  line2: "",
  city: "",
  phone: "",
};

const shippingAddress = handleActions(
  {
    [ADD_SHIPPING_ADDRESS]: (_state: Address, action: AnyAction) => {
      const a = action as AddAddressAction;
      return { ...a.payload.address };
    },
    [ON_PAY_RESPONSE]: () => emptyAddress,
  },
  defaultAddress
);

const billingAddress = handleActions(
  {
    [ADD_BILLING_ADDRESS]: (_state: Address, action: AnyAction) => {
      const a = action as AddAddressAction;
      return { ...a.payload.address };
    },
    [ON_PAY_RESPONSE]: () => emptyAddress,
  },
  defaultAddress
);

const braintreeToken = handleActions(
  {
    [FETCH_BRAINTREE_TOKEN_RESPONSE]: (_state: string, action: AnyAction) => {
      const a = action as FetchBraintreeTokenResponseAction;
      return a.payload.token;
    },
    [FETCH_B2B_BRAINTREE_TOKEN_RESPONSE]: (_state: string, action: AnyAction) =>
      (action as FetchB2BBraintreeTokenResponseAction).payload.braintreeToken,
    [FETCH_BRAINTREE_TOKEN_REQUEST]: () => "",
    [FETCH_B2B_BRAINTREE_TOKEN_REQUEST]: () => "",
    [CLEAR_CHECKOUT]: () => "",
  },
  ""
);

const confirmedPayment = handleActions(
  {
    [ON_PAY_FAILURE]: () => false,
    [CLEAR_CHECKOUT]: () => false,
    [ON_PAY_REQUEST]: () => false,
    [ON_PAY_RESPONSE]: () => true,
  },
  false
);

const orderId = handleActions(
  {
    [ON_PAY_FAILURE]: () => null,
    [CLEAR_CHECKOUT]: () => null,
    [ON_PAY_REQUEST]: () => null,
    [ON_PAY_RESPONSE]: (_state: string | null, action: OnPayResponseAction) =>
      `${action.payload.orderID}`,
  },
  null
);

const postCodeLookUpAddresses = handleActions(
  {
    [FETCH_ADDRESS_FROM_POSTCODE_RESPONSE]: (
      _state: PostcodeLookupAddress[],
      action: AnyAction
    ) => {
      const a = action as FetchAddressFromPostcodeActionResponse;
      return a.payload.addresses;
    },
    [FETCH_ADDRESS_FROM_POSTCODE_FAILURE]: () => [],
  },
  []
);

const postCodeLookUpError = handleActions(
  {
    [FETCH_ADDRESS_FROM_POSTCODE_FAILURE]: (
      _state: string,
      action: AnyAction
    ) => {
      return action.payload?.response?.error?.message || "";
    },
    [FETCH_ADDRESS_FROM_POSTCODE_RESPONSE]: () => "",
  },
  ""
);

export const emptyPatientDetails: PatientDetails = {
  firstName: "",
  lastName: "",
  email: "",
  dob: "",
};
const patientDetails = handleActions(
  {
    [PATIENT_DETAILS_SUBMITTED]: (
      _state: PatientDetails,
      action: AnyAction
    ) => {
      const a = action as PatientDetailsSubmittedAction;
      const { dob, ...rest } = a.payload.patientDetails;
      return {
        ...rest,
        dob: moment.utc(dob, "DD/MM/YYYY", true).format("YYYY-MM-DD"),
      };
    },
    [ON_PAY_RESPONSE]: () => emptyPatientDetails,
    [CLEAR_CHECKOUT]: () => emptyPatientDetails,
  },
  emptyPatientDetails
);

const awcToken = handleActions(
  {
    [SET_AWC_TOKEN]: (_state: string | null, action: SetAWCTokenAction) =>
      action.payload.token,
  },
  null
);

const reducer: Reducer<State, any> = combineReducers({
  pending,
  err,
  shippingAddress,
  billingAddress,
  braintreeToken,
  confirmedPayment,
  postCodeLookUpAddresses,
  postCodeLookUpError,
  patientDetails,
  orderId,
  awcToken,
});

export default reducer;
