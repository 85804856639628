import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import ListImage from "../../ListImage/ListImage";
import { Product } from "../../../store/reducer/products/types";
import { BasketItem } from "../../../store/reducer/basket/types";
import { getCostStr, addDisabled } from "../../../utils/utils";
import DoneIcon from "@material-ui/icons/Done";
import { addToBasket } from "../../../analytics/types";
import analytics from "../../../analytics";
import { RichText } from "prismic-reactjs";

// Components

const idealWidth = 430;
const imageRatio = 1.32;
const innerWidth = window.innerWidth - 40;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 48,
      paddingTop: 48,
    },
    image: {
      width: innerWidth < 430 ? innerWidth : idealWidth,
      height:
        innerWidth < 430 ? innerWidth / imageRatio : idealWidth / imageRatio,
      margin: "auto",
    },
    maxParacetamol: {
      padding: 10,
      marginTop: 10,
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.error.main,
    },
    title: {
      fontWeight: 700,
      fontSize: 24,
    },
    description: {
      marginTop: 8,
      fontSize: 18,
      fontWeight: 500,
    },
    extraInfo: {
      marginTop: 6,
      fontSize: 14,
    },
    details: {
      padding: "10px",
      position: "relative",
      height: 350,
      marginRight: 30,
    },
    footer: {
      bottom: 0,
      position: "absolute",
      width: "100%",
      margin: "auto",
    },
    addedText: {
      marginTop: 6,
      color: theme.palette.secondary.main,
      fontSize: 14,
    },
    button: {
      backgroundColor: theme.palette.primary.dark,
      width: 260,
      marginTop: 20,
      textTransform: "none",
      height: 50,
    },
    buttonSize: {
      fontSize: 16,
      color: "#FFFFFF",
      fontWeight: 600,
    },
    disabled: {
      backgroundColor: theme.palette.error.light,
    },
  })
);

export interface ImageRowInfoProps {
  product: Product;
  maxParacetamol: boolean;
  basketItem: BasketItem;
  basketItems: BasketItem[];
  addToBasket(p: Product, q: number): void;
}

const ImageInfoRow: React.FC<ImageRowInfoProps> = (props) => {
  const classes = useStyles();
  const [isAdded, setIsAdded] = useState<boolean>(false);

  const startTickTimeout = () => {
    setTimeout(() => {
      setIsAdded(false);
    }, 2000);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.image}>
            <ListImage
              imageUrl={props.product.imageUrl}
              productName={props.product.name}
              iconSize={130}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.details}>
            <Typography variant={"h4"} align={"left"} className={classes.title}>
              {props.product.name}
            </Typography>
            <div className={classes.description}>
              <RichText render={props.product.shortDescription} />
            </div>

            {props.maxParacetamol && props.product.containsParacetamol && (
              <Typography
                variant={"body2"}
                align={"left"}
                className={classes.maxParacetamol}
              >
                You cannot add any more paracetamol containing products to your
                basket
              </Typography>
            )}
            {props.product.inventoryCount <= props.basketItem.qty &&
              props.basketItem.qty && (
                <Typography
                  variant={"body2"}
                  align={"left"}
                  className={classes.maxParacetamol}
                >
                  {`We only have ${props.basketItem.qty} of this item in stock at the moment`}
                </Typography>
              )}
            <div className={classes.footer}>
              <Typography
                variant={"h4"}
                align={"left"}
                className={classes.title}
              >
                £{getCostStr(props.product.cost)}
              </Typography>
              <Button
                variant={"text"}
                color={"secondary"}
                className={classes.button}
                onClick={() => {
                  setIsAdded(true);
                  startTickTimeout();
                  props.addToBasket(props.basketItem.product, 1);
                  analytics.trackEventWithBasketDetails(
                    {
                      event: addToBasket,
                      metadata: {
                        productName: props.basketItem.product.name,
                        productSKU: props.basketItem.product.sku,
                      },
                    },
                    [...props.basketItems, props.basketItem]
                  );
                }}
                disabled={addDisabled(
                  props.basketItem.product,
                  props.maxParacetamol,
                  props.basketItem.qty
                )}
                classes={{
                  disabled: classes.disabled,
                  text: classes.buttonSize,
                }}
              >
                {isAdded ? (
                  <DoneIcon />
                ) : (
                  <Typography variant={"h6"}>Add to basket</Typography>
                )}
              </Button>
              <Typography
                variant={"body2"}
                align={"left"}
                className={classes.extraInfo}
              >
                Limited to {props.product.maxPurchaseNo} per customer
              </Typography>
              {props.product.containsParacetamol && (
                <Typography
                  variant={"body2"}
                  align={"left"}
                  className={classes.extraInfo}
                >
                  This product contains paracetamol*
                </Typography>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageInfoRow;
