import React, { ChangeEvent, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ProductSuggestion } from "../../store/reducer/productSuggestions/types";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import analytics from "../../analytics";
import { productSearch } from "../../analytics/types";

export interface SearchStateProps {
  suggestions: ProductSuggestion[];
}

export interface SearchDispatchProps {
  fetchSuggestions(q: string): void;
  clearSelectedProds(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      color: theme.palette.text.primary,
      fontSize: "13px",
      marginLeft: -10,
    },
    root: {
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 10,
      background: theme.palette.background.default,
      border: `solid 1px ${theme.palette.primary.light}`,
    },
    focused: {
      border: `solid 1px ${theme.palette.primary.dark}`,
    },
    inputRoot: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  })
);

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    searchRoot: {
      width: "100%",
    },
  })
);

export type SearchProps = SearchStateProps & SearchDispatchProps;

const Search: React.FC<SearchProps> = ({
  suggestions,
  fetchSuggestions,
  clearSelectedProds,
}) => {
  const history = useHistory();
  const autoClasses = useStyles();
  const classes = useStyles2();

  const handleTextChange = (ev: any) => {
    const mev = ev as ChangeEvent<HTMLInputElement>;
    if (mev.target.value !== undefined && mev.target.value.length > 2) {
      fetchSuggestions(mev.target.value);
      analytics.trackEvent({
        event: productSearch,
        metadata: {
          search: mev.target.value,
        },
      });
    }
  };

  const clearSuggestions = () => {
    clearSelectedProds();
  };

  const handleSelect = (
    event: ChangeEvent<Record<string, unknown>>,
    pickedProduct: string | ProductSuggestion
  ) => {
    if (typeof pickedProduct === "string") return;

    if (!pickedProduct.name) {
      if (suggestions.length === 0) {
        return;
      }
      if (suggestions.length > 0) {
        pickedProduct = suggestions[0];
      }
    }

    // Suggestion could be product or category; redirect based on assumption that
    // categories sku start with 'cat_'
    if (!pickedProduct.sku) return;

    const pickedUrl = pickedProduct.sku.startsWith("cat_")
      ? `/category/${pickedProduct.sku.toLowerCase()}/products`
      : `/product/${pickedProduct.sku}`;

    clearSelectedProds();
    history.push(pickedUrl);
    analytics.trackEvent({
      event: productSearch,
      metadata: {
        search: pickedProduct.name,
        found: true,
      },
    });
  };

  useEffect(() => {
    clearSelectedProds();
  }, [clearSelectedProds]);

  return (
    <Autocomplete
      className={classes.searchRoot}
      classes={autoClasses}
      options={suggestions}
      filterOptions={(options) => options}
      clearOnEscape
      disablePortal
      disableClearable
      freeSolo
      onChange={handleSelect}
      onInputChange={handleTextChange}
      onBlur={clearSuggestions}
      onFocus={clearSuggestions}
      getOptionLabel={(option) =>
        option !== undefined && option.name !== undefined ? option.name : ""
      }
      renderInput={(params) => (
        <TextField
          placeholder={"Search products"}
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position={"end"}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        return (
          <div style={{ display: "flex" }}>
            <SearchIcon />
            <span style={{ marginLeft: 10 }}>{option.label}</span>
          </div>
        );
      }}
    />
  );
};

export default Search;
