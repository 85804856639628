import { connect } from "react-redux";
import {
  fetchB2CBraintreeToken,
  onB2CPay,
  setPaymentError,
} from "../../../store/reducer/checkout/actions";
import {
  getBasketItems,
  getBillingAddress,
  getBraintreeToken,
  getCheckoutAWCToken,
  getCheckoutError,
  getCheckoutPatientDetails,
  getCheckoutPaymentPending,
  getConfirmedPayment,
  getShippingAddress,
  getShippingCost,
  getTotalCost,
} from "../../../store/selectors";
import { RootState } from "../../../store/types";
import Payment, { PaymentDispatchProps, PaymentStateProps } from "../Payment";

function mapStateToProps(state: RootState): PaymentStateProps {
  return {
    err: getCheckoutError(state),
    basketTotal: getTotalCost(state),
    shippingCost: getShippingCost(state),
    billingAddress: getBillingAddress(state),
    shippingAddress: getShippingAddress(state),
    basket: getBasketItems(state),
    braintreeToken: getBraintreeToken(state),
    paymentConfirmed: getConfirmedPayment(state),
    patientDetails: getCheckoutPatientDetails(state),
    paymentInProcess: getCheckoutPaymentPending(state),
    awcToken: getCheckoutAWCToken(state) || undefined,
  };
}

const mapDispatchToProps: PaymentDispatchProps = {
  onPay: onB2CPay,
  fetchBraintreeToken: fetchB2CBraintreeToken,
  setErr: setPaymentError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
