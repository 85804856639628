import { RSAA } from "redux-api-middleware";
import { API_ENDPOINT, POSTCODE_ENDPOINT } from "../../config";
import { BasketItem } from "../basket/types";
import {
  AddAddressAction,
  Address,
  ADD_BILLING_ADDRESS,
  ADD_SHIPPING_ADDRESS,
  CLEAR_CHECKOUT,
  FetchBraintreeTokenAction,
  FETCH_ADDRESS_FROM_POSTCODE,
  FETCH_ADDRESS_FROM_POSTCODE_FAILURE,
  FETCH_ADDRESS_FROM_POSTCODE_REQUEST,
  FETCH_ADDRESS_FROM_POSTCODE_RESPONSE,
  FETCH_BRAINTREE_TOKEN,
  FETCH_BRAINTREE_TOKEN_FAILURE,
  FETCH_BRAINTREE_TOKEN_REQUEST,
  FETCH_BRAINTREE_TOKEN_RESPONSE,
  OnPayAction,
  OnPayInput,
  ON_PAY,
  ON_PAY_FAILURE,
  ON_PAY_REQUEST,
  ON_PAY_RESPONSE,
  PatientDetails,
  PatientDetailsSubmittedAction,
  PATIENT_DETAILS_SUBMITTED,
  SetAWCTokenAction,
  SetPaymentErrorAction,
  SET_AWC_TOKEN,
  SET_PAYMENT_ERROR,
  StartB2CPaymentProcessAction,
  START_B2C_PAYMENT_PROCESS,
  CONSENT_TO_MARKETING,
  CONSENT_TO_MARKETING_REQUEST,
  CONSENT_TO_MARKETING_RESPONSE,
  CONSENT_TO_MARKETING_FAILURE,
  ConsentToMarketingAction,
} from "./types";

export function onB2CPay(
  {
    nonce,
    patient,
    billingAddress,
    shippingAddress,
    basket,
    awcToken,
  }: OnPayInput,
  endpoint: string = API_ENDPOINT
): OnPayAction {
  return {
    type: ON_PAY,
    [RSAA]: {
      endpoint: `${endpoint}/orders`,
      method: "POST",
      types: [ON_PAY_REQUEST, ON_PAY_RESPONSE, ON_PAY_FAILURE],
      body: JSON.stringify({
        shippingAddress,
        billingAddress,
        braintreeNonce: nonce,
        items: (basket as BasketItem[]).map((item) => ({
          sku: item.product.sku,
          quantity: item.qty,
        })),
        patient,
        awc: awcToken,
      }),
    },
  };
}

export function fetchB2CBraintreeToken(
  endpoint: string = API_ENDPOINT
): FetchBraintreeTokenAction {
  return {
    type: FETCH_BRAINTREE_TOKEN,
    [RSAA]: {
      endpoint: `${endpoint}/paymenttoken`,
      method: "GET",
      types: [
        FETCH_BRAINTREE_TOKEN_REQUEST,
        FETCH_BRAINTREE_TOKEN_RESPONSE,
        FETCH_BRAINTREE_TOKEN_FAILURE,
      ],
    },
  };
}

export function addBillingAddress(data: Address): AddAddressAction {
  return {
    type: ADD_BILLING_ADDRESS,
    payload: {
      address: data,
    },
  };
}

export function addShippingAddress(data: Address): AddAddressAction {
  return {
    type: ADD_SHIPPING_ADDRESS,
    payload: {
      address: data,
    },
  };
}

export function fetchPostCodeAddress(
  postcode: string,
  endpoint: string = POSTCODE_ENDPOINT
): FetchBraintreeTokenAction {
  return {
    type: FETCH_ADDRESS_FROM_POSTCODE,
    [RSAA]: {
      endpoint: `${endpoint}/postcodes/${postcode}/addresses`,
      method: "GET",
      types: [
        FETCH_ADDRESS_FROM_POSTCODE_REQUEST,
        FETCH_ADDRESS_FROM_POSTCODE_RESPONSE,
        FETCH_ADDRESS_FROM_POSTCODE_FAILURE,
      ],
    },
  };
}

export function clearCheckout() {
  return { type: CLEAR_CHECKOUT };
}

export const patientDetailsSubmitted = (
  patientDetails: PatientDetails
): PatientDetailsSubmittedAction => ({
  type: PATIENT_DETAILS_SUBMITTED,
  payload: {
    patientDetails,
  },
});

export const startB2CPaymentProcessing = (): StartB2CPaymentProcessAction => ({
  type: START_B2C_PAYMENT_PROCESS,
});

export const setPaymentError = (message: string): SetPaymentErrorAction => ({
  type: SET_PAYMENT_ERROR,
  payload: {
    message,
  },
});

export function setAwcToken(token: string): SetAWCTokenAction {
  return {
    type: SET_AWC_TOKEN,
    payload: {
      token,
    },
  };
}

export function consentToMarketing(
  email: string,
  firstName = "",
  lastName = "",
  endpoint: string = API_ENDPOINT
): ConsentToMarketingAction {
  return {
    type: CONSENT_TO_MARKETING,
    [RSAA]: {
      endpoint: `${endpoint}/marketing/subscribe`,
      method: "POST",
      types: [
        CONSENT_TO_MARKETING_REQUEST,
        CONSENT_TO_MARKETING_RESPONSE,
        CONSENT_TO_MARKETING_FAILURE,
      ],
      body: JSON.stringify({
        email,
        firstName,
        lastName,
      }),
    },
  };
}
