import React from "react";
import { Switch, Route } from "react-router-dom";

import ProductList from "../pages/ProductList";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import B2CPaymentPage from "../pages/B2CPayment";
import B2BPaymentPage from "../pages/B2BPayment";
import ShippingPage from "../pages/Shipping";
import Confirmation from "../pages/Confirmation";
import RouterWrapper from "./RouterWrapper";
import ProductDetail from "../pages/ProductDetailPage";
import PatientDetails from "../pages/PatientDetails";
import B2BPaymentRequestPage from "../pages/B2BPaymentRequest";
import BasketWrapper from "./BasketWrapper";
import BillingAddress from "../components/BillingAddress";
import Preview from "./Preview";

const Routes = () => (
  <Switch>
    <Route exact path={"/preview"} component={Preview} />
    {/* B2C routes */}
    <Route path={"/category/:category/products"}>
      <RouterWrapper product>
        <ProductList />
      </RouterWrapper>
    </Route>
    <Route path={"/product/:sku"}>
      <RouterWrapper product>
        <ProductDetail />
      </RouterWrapper>
    </Route>
    <Route path={"/payment"}>
      <RouterWrapper payment>
        <BasketWrapper>
          <B2CPaymentPage />
        </BasketWrapper>
      </RouterWrapper>
    </Route>
    <Route path={"/patient-details"}>
      <RouterWrapper patientDetails>
        <PatientDetails />
      </RouterWrapper>
    </Route>
    <Route path={"/shipping"}>
      <RouterWrapper checkout>
        <BasketWrapper>
          <ShippingPage />
        </BasketWrapper>
      </RouterWrapper>
    </Route>
    <Route path={"/your-details"}>
      <RouterWrapper checkout>
        <BasketWrapper>
          <BillingAddress />
        </BasketWrapper>
      </RouterWrapper>
    </Route>
    <Route path={"/confirmation"}>
      <RouterWrapper>
        <Confirmation />
      </RouterWrapper>
    </Route>
    {/* B2B routes */}
    <Route path={"/b2b/payment-request/:tokenRequestData"}>
      <RouterWrapper>
        <B2BPaymentPage />
      </RouterWrapper>
    </Route>
    <Route path={"/b2b/payment-request"}>
      <RouterWrapper>
        <B2BPaymentRequestPage />
      </RouterWrapper>
    </Route>
    {/* Defaults */}
    <Route path={"/"}>
      <RouterWrapper product>
        <ProductList home />
      </RouterWrapper>
    </Route>
    <Route>
      <PageNotFound />
    </Route>
  </Switch>
);

export default Routes;
