import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import {
  Address,
  AddAddressAction,
  PostcodeLookupAddress,
} from "../../store/reducer/checkout/types";

import AddressForm from "../AddressForm/AddressForm";
import { useHistory } from "react-router-dom";
import analytics from "../../analytics";
import { BasketItem } from "../../store/reducer/basket/types";
import {
  billingDetailsEntered,
  consentToMarketing,
} from "../../analytics/types";
import BreadCrumb from "../Checkout/Breadcrumb/Breadcrumb";
import { makeCheckoutBreadcrumb } from "../../pages/Shipping/Shipping";

export interface BillingAddressStateProps {
  billingAddress: Address;
  postCodeLookUpAddresses: PostcodeLookupAddress[];
  error: string;
  basketItems: BasketItem[];
  totalCost: number;
  neededPatientDetails: boolean;
}

export interface BillingAddressDispatchProps {
  onSubmit(data: Address): AddAddressAction;
  onClickPostCodeSearch(p: string): void;
  includeMarketing(email: string, firstName: string, lastName: string): void;
}

export type BillingAddressProps = BillingAddressStateProps &
  BillingAddressDispatchProps;

const BillingAddress: React.FC<BillingAddressProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginLeft: isMobile ? 0 : 100,
      },

      breadcrumb: {
        marginTop: 20,
      },
      title: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginTop: 45,
        marginBottom: 25,
        fontSize: 34,
      },
    })
  );

  const classes = useStyles();

  const history = useHistory();
  const onSubmit = (data: Address, includeMarketing: boolean): void => {
    if (includeMarketing) {
      analytics.enableMarketingTracking(
        data.email,
        data.firstName,
        data.lastName
      );
      analytics.trackEvent({
        event: consentToMarketing,
      });
      props.includeMarketing(data.email, data.firstName, data.firstName);
    }

    analytics.trackEvent({
      event: billingDetailsEntered,
      metadata: {
        products: props.basketItems.map((item) => ({
          sku: item.product.sku,
          product: item.product.name,
          cost: item.product.cost,
          qty: item.qty,
        })),
        price: props.totalCost,
      },
    });
    props.onSubmit(data);
    history.push("/shipping");
  };

  return (
    <div className={classes.root}>
      {!isMobile && (
        <div className={classes.breadcrumb}>
          <BreadCrumb
            links={makeCheckoutBreadcrumb(
              props.neededPatientDetails,
              "billing"
            )}
          />
        </div>
      )}
      <Typography variant={"h4"} align={"left"} className={classes.title}>
        Your details
      </Typography>
      <AddressForm
        onSubmit={onSubmit}
        address={props.billingAddress}
        postCodeLookUpAddresses={props.postCodeLookUpAddresses}
        onClickPostCodeSearch={props.onClickPostCodeSearch}
        error={props.error}
      />
    </div>
  );
};

export default BillingAddress;
