import React from "react";
import { Product } from "../../../store/reducer/products/types";
import { Grid, useMediaQuery, useTheme, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { calcBasketItem } from "../../../utils/utils";
import { BasketItem } from "../../../store/reducer/basket/types";
import ProductListItem from "../../ProductListItem/ProductListitem";

export interface RelatedProductsProps {
  products: Product[];
  basketItems: BasketItem[];
  maxParacetamol: boolean;
  addToBasket(p: Product, q: number): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productGrid: {
      marginTop: 20,
      marginLeft: -10,
    },
    RelatedProducts: {
      fontWeight: "bold",
      fontSize: 24,
      marginTop: 30,
    },
  })
);

const RelatedProducts: React.FC<RelatedProductsProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxNumber = useMediaQuery(theme.breakpoints.down("sm")) ? 2 : 3;

  return (
    <div>
      <Typography
        variant={"h3"}
        align={"left"}
        className={classes.RelatedProducts}
      >
        Related Products
      </Typography>
      <Grid container spacing={0} className={classes.productGrid}>
        {props.products
          .filter((prod) => prod.inventoryCount > 0)
          .filter((_prod, index) => index < maxNumber)
          .map((product) => {
            return (
              <ProductListItem
                mobile={isMobile}
                product={product}
                key={product.sku}
                basketItem={calcBasketItem(product, props.basketItems)}
                basketItems={props.basketItems}
                maxParacetamol={props.maxParacetamol}
                addToBasket={props.addToBasket}
              />
            );
          })}
      </Grid>
    </div>
  );
};

export default RelatedProducts;
