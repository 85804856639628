import { connect } from "react-redux";
import { startB2BPaymentProcessing } from "../../store/reducer/b2bPayments/actions";
import {
  getB2BPaymentsPaymentAmount,
  getB2BPaymentsReference,
} from "../../store/selectors";
import { RootState } from "../../store/types";
import B2BPayment, { PaymentDispatchProps, PaymentStateProps } from "./Screen";

function mapStateToProps(state: RootState): PaymentStateProps {
  return {
    amount: getB2BPaymentsPaymentAmount(state),
    reference: getB2BPaymentsReference(state) || "",
  };
}

const mapDispatchToProps: PaymentDispatchProps = {
  onLoad: startB2BPaymentProcessing,
};

export default connect(mapStateToProps, mapDispatchToProps)(B2BPayment);
