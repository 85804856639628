import { AnyAction, combineReducers, Reducer } from "redux";
import { handleActions } from "redux-actions";
import {
  FetchB2BBraintreeTokenResponseAction,
  PaymentError,
  START_B2C_PAYMENT_PROCESS,
} from "../checkout/types";
import {
  CreateB2BPaymentRequestTokenAResponseAction,
  CREATE_B2B_PAYMENT_REQUEST_TOKEN_FAILURE,
  CREATE_B2B_PAYMENT_REQUEST_TOKEN_REQUEST,
  CREATE_B2B_PAYMENT_REQUEST_TOKEN_RESPONSE,
  FETCH_B2B_BRAINTREE_TOKEN_REQUEST,
  FETCH_B2B_BRAINTREE_TOKEN_RESPONSE,
  ON_B2B_PAY_FAILURE,
  ON_B2B_PAY_REQUEST,
  ON_B2B_PAY_RESPONSE,
  PaymentRequestData,
  StandardShopAPIErrorResponseAction,
  START_B2B_PAYMENT_PROCESS,
  START_PAYMENT_REQUEST_PROCESS,
  State,
} from "./types";

export const emptyPaymentRequestData: PaymentRequestData = {
  reference: "",
  amount: 0,
  password: "",
};

const requestTokenError = handleActions(
  {
    [CREATE_B2B_PAYMENT_REQUEST_TOKEN_FAILURE]: (
      _state: string | null,
      action: AnyAction
    ) => {
      const a = action as StandardShopAPIErrorResponseAction;
      return a.payload.response.message;
    },
    [CREATE_B2B_PAYMENT_REQUEST_TOKEN_REQUEST]: () => null,
    [CREATE_B2B_PAYMENT_REQUEST_TOKEN_RESPONSE]: () => null,
    [START_PAYMENT_REQUEST_PROCESS]: () => null,
  },
  null
);

const requestToken = handleActions(
  {
    [START_PAYMENT_REQUEST_PROCESS]: () => null,
    [CREATE_B2B_PAYMENT_REQUEST_TOKEN_REQUEST]: () => null,
    [CREATE_B2B_PAYMENT_REQUEST_TOKEN_RESPONSE]: (
      _state: string | null,
      action: AnyAction
    ) => (action as CreateB2BPaymentRequestTokenAResponseAction).payload.token,
  },
  null
);

const requestTokenLoading = handleActions(
  {
    [START_PAYMENT_REQUEST_PROCESS]: () => false,
    [CREATE_B2B_PAYMENT_REQUEST_TOKEN_REQUEST]: () => true,
    [CREATE_B2B_PAYMENT_REQUEST_TOKEN_RESPONSE]: () => false,
    [CREATE_B2B_PAYMENT_REQUEST_TOKEN_FAILURE]: () => false,
  },
  false
);

const paymentAmount = handleActions(
  {
    [FETCH_B2B_BRAINTREE_TOKEN_REQUEST]: () => 0,
    [FETCH_B2B_BRAINTREE_TOKEN_RESPONSE]: (_: number, action: AnyAction) =>
      (action as FetchB2BBraintreeTokenResponseAction).payload.amount,
    [START_B2B_PAYMENT_PROCESS]: () => 0,
  },
  0
);

const paymentPending = handleActions(
  {
    [ON_B2B_PAY_REQUEST]: () => true,
    [ON_B2B_PAY_RESPONSE]: () => false,
    [ON_B2B_PAY_FAILURE]: () => false,
    [START_B2B_PAYMENT_PROCESS]: () => false,
  },
  false
);

const paymentError = handleActions(
  {
    [ON_B2B_PAY_REQUEST]: () => null,
    [ON_B2B_PAY_RESPONSE]: () => null,
    [ON_B2B_PAY_FAILURE]: (_: PaymentError | null, action: AnyAction) => {
      const a = action as StandardShopAPIErrorResponseAction;
      return {
        message: a.payload.response.message,
      };
    },
    [START_B2B_PAYMENT_PROCESS]: () => null,
  },
  null
);

const paymentConfirmed = handleActions(
  {
    [ON_B2B_PAY_REQUEST]: () => false,
    [ON_B2B_PAY_RESPONSE]: () => true,
    [ON_B2B_PAY_FAILURE]: () => false,
    [START_B2B_PAYMENT_PROCESS]: () => false,
  },
  false
);

const isB2BPayment = handleActions(
  {
    [START_B2B_PAYMENT_PROCESS]: () => true,
    [START_B2C_PAYMENT_PROCESS]: () => false,
  },
  false
);

const reference = handleActions(
  {
    [FETCH_B2B_BRAINTREE_TOKEN_REQUEST]: () => null,
    [FETCH_B2B_BRAINTREE_TOKEN_RESPONSE]: (
      _: string | null,
      action: AnyAction
    ) => (action as FetchB2BBraintreeTokenResponseAction).payload.reference,
    [START_B2B_PAYMENT_PROCESS]: () => null,
  },
  null
);

const reducer: Reducer<State, any> = combineReducers({
  requestTokenError,
  requestToken,
  requestTokenLoading,
  paymentAmount,
  paymentPending,
  paymentError,
  paymentConfirmed,
  isB2BPayment,
  reference,
});

export default reducer;
