import amplitude from "amplitude-js";
import Klavyio from "./klavyio";
import Marketing from "./marketing";

import { AnalyticsEvent } from "./types";
import { BasketItem } from "../store/reducer/basket/types";

class Analytics {
  /**
   * Analytics class is a singleton, so if this class is invoked multiple times, This
   * instance is returned for subsequent invokes
   */
  static instance: Analytics;

  private klaviyo?: Klavyio;
  private marketing: Marketing;

  constructor() {
    this.marketing = new Marketing();

    if (!Analytics.instance) {
      if (process.env.REACT_APP_KLAVIYO) {
        this.klaviyo = new Klavyio(process.env.REACT_APP_KLAVIYO);
      }
      Analytics.instance = this;
    }
    return Analytics.instance;
  }

  /**
   * Initialise analytics
   */
  public init() {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE as string);
    this.klaviyo?.initialize();
  }

  /**
   * Set custom user ID. Useful if the user is logged in and has a unique ID
   * @see https://developers.amplitude.com/docs/setting-custom-user-ids
   */
  public setUser(userId: string) {
    amplitude.getInstance().setUserId(userId);
  }

  /**
   * Enables event tracking on klaviyo.
   * @param email customer's email
   */
  public enableMarketingTracking(
    email: string,
    firstName: string,
    lastName: string
  ) {
    this.klaviyo?.setUser(email, firstName, lastName);
    this.marketing.enable();
  }

  /**
   * Set useful user data on the amplitude session user
   * @see https://developers.amplitude.com/docs/setting-user-properties#setting-multiple-user-properties
   */
  public setUserData(properties: { [key: string]: string | number }) {
    amplitude.getInstance().setUserProperties(properties);
  }

  /**
   * Track a revenue event
   * @see https://developers.amplitude.com/docs/tracking-revenue
   */
  public trackRevenue(productId: string | number, price: number, quantity = 1) {
    const revenue = new amplitude.Revenue()
      .setProductId(`${productId}`)
      .setPrice(price)
      .setQuantity(quantity);

    amplitude.getInstance().logRevenueV2(revenue);
  }

  /**
   * Track an event
   * @see https://developers.amplitude.com/docs/tracking-events
   */
  public trackEvent(event: AnalyticsEvent) {
    amplitude.getInstance().logEvent(this.formatEvent(event), event.metadata);
    this.klaviyo?.trackEvent(this.formatEvent(event), event.metadata);
  }

  public trackEventWithBasketDetails(
    event: AnalyticsEvent,
    items: BasketItem[]
  ) {
    if (event.metadata === undefined) {
      event.metadata = {};
    }
    event.metadata.items = items.reduce(
      (total, item) => (total += item.qty),
      0
    );
    event.metadata.value =
      items.reduce(
        (total, item) => (total += item.qty * item.product.cost),
        0
      ) / 100;
    event.metadata.products = items
      .map((item) => item.product.sku)
      .filter((sku, idx, self) => self.indexOf(sku) === idx).length;
    event.metadata.enabledMarketing = this.marketing.isEnabled();
    this.trackEvent(event);
  }

  private getProductCount(items: BasketItem[]): number {
    return items
      .map((item) => item.product.sku)
      .filter((sku, idx, self) => self.indexOf(sku) === idx).length;
  }

  private formatEvent(event: AnalyticsEvent) {
    return `${event.event}`.toLowerCase();
  }
}

const instance = new Analytics();
Object.freeze(instance);

export default instance;
export * from "./types";
