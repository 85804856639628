import { Product } from "../products/types";
import {
  AddToBasketAction,
  ADD_TO_BASKET,
  BASKET_SET_CHECKOUT_ACTIVE,
  BASKET_SET_PATIENT_DETAILS_ACTIVE,
  BASKET_SET_PAYMENT_ACTIVE,
  BASKET_SET_PRODUCT_ACTIVE,
  CleanBasketAction,
  ToggleBasketActon,
  CLEAN_BASKET,
  TOGGLE_MOBILE,
  TOGGLE_DESKTOP,
  CLOSE_MOBILE_BASKET,
  CLOSE_DESKTOP_BASKET,
  TOGGLE_MOBILE_CAT,
} from "./types";

export function setProduct() {
  return { type: BASKET_SET_PRODUCT_ACTIVE };
}
export function setCheckout() {
  return { type: BASKET_SET_CHECKOUT_ACTIVE };
}
export function setPayment() {
  return { type: BASKET_SET_PAYMENT_ACTIVE };
}

export const setPaymentDetails = () => ({
  type: BASKET_SET_PATIENT_DETAILS_ACTIVE,
});

export function addToBasket(p: Product, q: number): AddToBasketAction {
  return {
    type: ADD_TO_BASKET,
    payload: {
      product: p,
      qty: q,
    },
  };
}

export function cleanBasket(): CleanBasketAction {
  return {
    type: CLEAN_BASKET,
  };
}

export function toggleMobileBasket(): ToggleBasketActon {
  return {
    type: TOGGLE_MOBILE,
  };
}

export function toggleDesktopBasket(): ToggleBasketActon {
  return {
    type: TOGGLE_DESKTOP,
  };
}

export function toggleMobileCat(): ToggleBasketActon {
  return {
    type: TOGGLE_MOBILE_CAT,
  };
}

export function closeMobileBasket(): ToggleBasketActon {
  return {
    type: CLOSE_MOBILE_BASKET,
  };
}

export function closeDesktopBasket(): ToggleBasketActon {
  return {
    type: CLOSE_DESKTOP_BASKET,
  };
}
