import { State, Category } from "./types";

export function getCategories(state: State): Category[] {
  return state.list;
}

export function getSelectedId(state: State): string | null {
  return state.selectedCategoryId;
}

export function getSelectedCategory(state: State): Category | null {
  if (state.selectedCategoryId) {
    const cat = state.list.find((c) => c.id === state.selectedCategoryId);
    return cat ? cat : null;
  }
  return null;
}
