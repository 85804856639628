import { Button, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Checkout from "../../components/Checkout/Checkout";
import { emptyPatientDetails } from "../../store/reducer/checkout";
import {
  PatientDetails,
  PatientDetailsSubmittedAction,
} from "../../store/reducer/checkout/types";
import Schema from "./formSchema";
import useFormStyles from "./formStyles";

export interface PatientDetailsStateProps {
  patientDetails: PatientDetails;
}

export interface PatientDetailsDispatchProps {
  onSubmit(data: PatientDetails): PatientDetailsSubmittedAction;
}

export type PatientDetailsProps = PatientDetailsStateProps &
  PatientDetailsDispatchProps;

const PatientDetailsPage: React.FC<PatientDetailsProps> = ({
  patientDetails,
  ...props
}) => {
  const history = useHistory();
  const onSubmit = (data: PatientDetails): void => {
    props.onSubmit(data);
    history.push("/shipping");
  };

  const view: JSX.Element = (
    <Form onSubmit={onSubmit} patientDetails={patientDetails} />
  );

  return (
    <Checkout
      view={view}
      title={"Antibody testing kit patient information"}
      breadcrumb={[
        {
          name: "Home",
          linkTo: "/",
        },
        {
          name: "COVID-19 Antibody testing kit patient information",
          linkTo: "",
        },
      ]}
    />
  );
};

export interface FormProps {
  onSubmit(data: PatientDetails): void;
  patientDetails: PatientDetails;
}

const Form: React.FC<FormProps> = ({ onSubmit, patientDetails }) => {
  const { register, handleSubmit, reset, errors } = useForm({
    validationSchema: Schema,
    defaultValues: {
      ...emptyPatientDetails,
      confirmEmail: "",
    },
  });

  useEffect(() => {
    const { dob, ...rest } = patientDetails;
    reset({
      ...rest,
      dob: formatDobForInputField(dob),
    });
  }, [patientDetails, reset]);

  const classes = useFormStyles();
  return (
    <form
      id={"patient-details-form"}
      className={classes.form}
      noValidate
      autoComplete={"off"}
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <Typography className={classes.paragraph}>
        We need some information about the person this test is intended for.
      </Typography>
      <Typography className={classes.paragraph}>
        This information will be passed to the testing lab. The test can only be
        used by the person whose details you have provided here.
      </Typography>
      <TextField
        className={classes.input}
        variant={"outlined"}
        label={"First name"}
        name={"firstName"}
        error={!!errors.firstName}
        helperText={errors.firstName && errors.firstName.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "firstName",
        }}
      />
      <TextField
        className={classes.input}
        label={"Last name"}
        variant={"outlined"}
        name={"lastName"}
        error={!!errors.lastName}
        helperText={errors.lastName && errors.lastName.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "lastName",
        }}
      />
      <TextField
        className={classes.input}
        label={"Date of birth"}
        placeholder={"01/01/1990"}
        variant={"outlined"}
        name={"dob"}
        error={!!errors.dob}
        helperText={errors.dob && errors.dob.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "dob",
        }}
      />
      <Typography variant={"h4"} align={"center"} className={classes.title}>
        Test results
      </Typography>
      <Typography className={classes.paragraph}>
        Please enter the email address you would like us to send the test
        results to.
      </Typography>
      <TextField
        className={classes.input}
        label={"Email"}
        variant={"outlined"}
        name={"email"}
        type={"email"}
        error={!!errors.email}
        helperText={errors.email && errors.email.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "email",
        }}
      />
      <TextField
        className={classes.input}
        label={"Confirm email"}
        variant={"outlined"}
        name={"confirmEmail"}
        type={"email"}
        error={!!errors.confirmEmail}
        helperText={errors.confirmEmail && errors.confirmEmail.message}
        inputRef={register}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "data-testid": "confirmEmail",
        }}
      />
      <Button
        variant={"contained"}
        color={"secondary"}
        type={"submit"}
        data-testid={"patient-details-continue"}
        className={classes.buttons}
      >
        Continue
      </Button>
    </form>
  );
};

const formatDobForInputField = (dob: string) => {
  // Expected format YYYY-MM-DD
  const parts = dob.split("-");
  return parts.length === 3
    ? [parts[2] || "", parts[1] || "", parts[0] || ""].join("/")
    : "";
};

export default PatientDetailsPage;
