import { connect } from "react-redux";
import { RootState } from "../../store/types";

import { addToBasket } from "../../store/reducer/basket/action";
import ProductDetails, {
  ProductDetailsStateProps,
  ProductDetailsDispatchProps,
} from "./ProductDetails";
import {
  getBasketItems,
  maxParacetamol,
  getSelectedProduct,
} from "../../store/selectors";
import { fetchProductDetail } from "../../store/reducer/products/actions";

function mapStateToProps(state: RootState): ProductDetailsStateProps {
  return {
    selectedProduct: getSelectedProduct(state),
    fetching: false,
    basketItems: getBasketItems(state),
    maxParacetamol: maxParacetamol(state),
  };
}

const mapDispatchToProps: ProductDetailsDispatchProps = {
  addToBasket: addToBasket,
  fetchProduct: fetchProductDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
