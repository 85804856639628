import { AnyAction } from "redux";
import { RSAA } from "redux-api-middleware";
import { BasketItem } from "../basket/types";

export interface State {
  err: PaymentError | null;
  pending: boolean;
  shippingAddress: Address;
  billingAddress: Address;
  confirmedPayment: boolean;
  braintreeToken: string;
  postCodeLookUpAddresses: PostcodeLookupAddress[];
  postCodeLookUpError: string;
  patientDetails: PatientDetails;
  orderId: string | null;
  awcToken: string | null;
}

export interface PaymentError {
  message: string;
  data?: { [key: string]: unknown };
}

export interface PaymentFailureResponseAction {
  type: string;
  payload: {
    response: PaymentError;
  };
}

export interface InsufficientInventoryError {
  inventoryUpdates: {
    [key: string]: number;
  };
}

export enum PaymentErrorType {
  inventory = "insufficient_inventory",
}

export interface Address {
  title: string;
  firstName: string;
  lastName: string;
  line1: string;
  line2?: string | undefined;
  postcode: string;
  email: string;
  city: string;
  phone?: string;
}

export const emptyCustomerDetails: Address = {
  title: "",
  firstName: "",
  lastName: "",
  line1: "",
  line2: "",
  city: "",
  postcode: "",
  email: "",
  phone: "",
};

export interface PostcodeLookupAddress {
  line1: string;
  line2?: string | undefined;
  city: string;
  postcode: string;
}

export const ON_PAY = "PAYMENT/ON_PAY";
export const ON_PAY_REQUEST = "PAYMENT/ON_PAY_REQUEST";
export const ON_PAY_RESPONSE = "PAYMENT/ON_PAY_RESPONSE";
export const ON_PAY_FAILURE = "PAYMENT/ON_PAY_FAILURE";

export interface OnPayInput {
  nonce: string;
  tokenData?: string;
  patient?: PatientDetails;
  billingAddress?: Address;
  shippingAddress?: Address;
  basket?: BasketItem[];
  awcToken?: string;
}

export interface OnPayAction {
  type: string;
  [RSAA]: {
    endpoint: string;
    method: string;
    body: string;
    types: [string, string, string];
  };
}

export interface OnPayResponseAction {
  type: string;
  payload: {
    orderID: number;
  };
}

export interface BasketItemForPay {
  sku: string;
  quantity: number;
}

export const ADD_SHIPPING_ADDRESS = "SHIPPING/ADD_ADDRESS";
export const ADD_BILLING_ADDRESS = "BILLING/ADD_ADDRESS";

export interface AddAddressAction {
  type: string;
  payload: {
    address: Address;
  };
}

export interface AddAddressFromPostcodeAction {
  type: string;
  payload: {
    address: PostcodeLookupAddress;
  };
}

export const emptyAddress: Address = {
  title: "",
  firstName: "",
  lastName: "",
  email: "",
  postcode: "",
  line1: "",
  line2: "",
  city: "",
  phone: "",
};

export const FETCH_BRAINTREE_TOKEN = "PAYMENT/FETCH_BRAINTREE_TOKEN";
export const FETCH_BRAINTREE_TOKEN_REQUEST =
  "PAYMENT/FETCH_BRAINTREE_TOKEN_REQUEST";
export const FETCH_BRAINTREE_TOKEN_RESPONSE =
  "PAYMENT/FETCH_BRAINTREE_TOKEN_RESPONSE";
export const FETCH_BRAINTREE_TOKEN_FAILURE =
  "PAYMENT/FETCH_BRAINTREE_TOKEN_FAILURE";

export interface FetchBraintreeTokenAction {
  type: string;
  [RSAA]: {
    endpoint: string;
    method: string;
    body?: string;
    types: [string, string, string];
  };
}

export interface FetchBraintreeTokenResponseAction {
  type: string;
  payload: {
    token: string;
  };
}

export interface FetchB2BBraintreeTokenResponseAction {
  type: string;
  payload: {
    braintreeToken: string;
    amount: number;
    reference: string;
  };
}

export const FETCH_ADDRESS_FROM_POSTCODE =
  "ADDRESS/FETCH_ADDRESS_FROM_POSTCODE";
export const FETCH_ADDRESS_FROM_POSTCODE_REQUEST =
  "ADDRESS/FETCH_ADDRESS_FROM_POSTCODE_REQUEST";
export const FETCH_ADDRESS_FROM_POSTCODE_RESPONSE =
  "ADDRESS/FETCH_ADDRESS_FROM_POSTCODE_RESPONSE";
export const FETCH_ADDRESS_FROM_POSTCODE_FAILURE =
  "ADDRESS/FETCH_ADDRESS_FROM_POSTCODE_FAILURE";

export interface FetchAddressFromPostcodeAction {
  type: string;
  [RSAA]: {
    endpoint: string;
    method: string;
    types: [string, string, string];
  };
}

export interface FetchAddressFromPostcodeActionResponse {
  type: string;
  payload: {
    addresses: PostcodeLookupAddress[];
  };
}
export const CLEAR_CHECKOUT = "CHECKOUT/CLEAR";

export interface PatientDetails {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
}

export const PATIENT_DETAILS_SUBMITTED = "CHECKOUT/PATIENT_DETAILS_SUBMITTED";

export interface PatientDetailsSubmittedAction {
  type: string;
  payload: {
    patientDetails: PatientDetails;
  };
}

export const START_B2C_PAYMENT_PROCESS = "START_B2C_PAYMENT_PROCESS";
export type StartB2CPaymentProcessAction = AnyAction;

export const SET_PAYMENT_ERROR = "SET_PAYMENT_ERROR";
export type SetPaymentErrorAction = {
  type: string;
  payload: {
    message: string;
  };
};

export const SET_AWC_TOKEN = "CHECKOUT/SET_AWC_TOKEN";
export interface SetAWCTokenAction {
  type: string;
  payload: {
    token: string;
  };
}

export const CONSENT_TO_MARKETING = "MARKETING/CONSENT_TO_MARKETING";
export const CONSENT_TO_MARKETING_REQUEST =
  "MARKETING/CONSENT_TO_MARKETING_REQUEST";
export const CONSENT_TO_MARKETING_RESPONSE =
  "MARKETING/CONSENT_TO_MARKETING_RESPONSE";
export const CONSENT_TO_MARKETING_FAILURE =
  "MARKETING/CONSENT_TO_MARKETING_FAILURE";

export interface ConsentToMarketingAction {
  type: string;
  [RSAA]: {
    endpoint: string;
    method: string;
    body?: string;
    types: [string, string, string];
  };
}
