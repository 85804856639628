import { connect } from "react-redux";
import {
  fetchB2BBraintreeToken,
  onB2BPay,
} from "../../../store/reducer/b2bPayments/actions";
import {
  getB2BPaymentsPaymentAmount,
  getB2BPaymentsPaymentConfirmed,
  getB2BPaymentsPaymentError,
  getB2BPaymentsPaymentPending,
  getBraintreeToken,
} from "../../../store/selectors";
import { RootState } from "../../../store/types";
import Payment, { PaymentDispatchProps, PaymentStateProps } from "../Payment";
import { setPaymentError } from "../../../store/reducer/checkout/actions";

function mapStateToProps(state: RootState): PaymentStateProps {
  return {
    err: getB2BPaymentsPaymentError(state),
    basketTotal: getB2BPaymentsPaymentAmount(state),
    shippingCost: 0, // This will always be zero for B2B
    braintreeToken: getBraintreeToken(state),
    paymentConfirmed: getB2BPaymentsPaymentConfirmed(state),
    paymentInProcess: getB2BPaymentsPaymentPending(state),
    needsBraintreeTokenData: true,
  };
}

const mapDispatchToProps: PaymentDispatchProps = {
  onPay: onB2BPay,
  fetchBraintreeToken: fetchB2BBraintreeToken,
  setErr: setPaymentError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
