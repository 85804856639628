import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AddressForm from "../../components/AddressForm/AddressForm";
import BreadCrumb, {
  BreadCrumbLink,
} from "../../components/Checkout/Breadcrumb/Breadcrumb";
import {
  AddAddressAction,
  Address,
  PostcodeLookupAddress,
} from "../../store/reducer/checkout/types";

import analytics from "../../analytics";
import {
  shippingPage as shippinPageType,
  shippingDetailsEntered,
} from "../../analytics/types";
import { BasketItem as BasketItemType } from "../../store/reducer/basket/types";
import {
  Checkbox,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export interface ShippingAddressStateProps {
  address: Address;
  postCodeLookUpAddresses: PostcodeLookupAddress[];
  error: string;
  neededPatientDetails: boolean;
  basketItems: BasketItemType[];
  totalCost: number;
  billingAddress: Address;
}

export interface ShippingAddressDispatchProps {
  onSubmit(data: Address): AddAddressAction;
  onClickPostCodeSearch(p: string): void;
}

export type ShippingAddressProps = ShippingAddressStateProps &
  ShippingAddressDispatchProps;

const ShippingPage: React.FC<ShippingAddressProps> = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginLeft: isMobile ? 0 : 100,
      },
      breadcrumb: {
        marginTop: 20,
      },
      title: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginTop: 45,
        marginBottom: 25,
        fontSize: 34,
      },
      checkboxRow: {
        display: "flex",
        margin: "auto",
        width: "250px",
      },
      box: {
        marginTop: -9,
      },
    })
  );
  const classes = useStyles();
  const onSubmit = (data: Address, includeMarketing: boolean): void => {
    analytics.trackEvent({
      event: shippingDetailsEntered,
      metadata: {
        products: props.basketItems.map((item) => ({
          sku: item.product.sku,
          product: item.product.name,
          cost: item.product.cost,
          qty: item.qty,
        })),
        price: props.totalCost,
      },
    });

    props.onSubmit(data);
    history.push("/payment");
  };

  useEffect(() => {
    analytics.trackEventWithBasketDetails(
      {
        event: shippinPageType,
      },
      props.basketItems
    );
  }, [props.basketItems]);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      props.onSubmit(props.billingAddress);
    } else {
      props.onSubmit({
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        postcode: "",
        line1: "",
        line2: "",
        city: "",
        phone: "",
      });
    }
  };

  return (
    <div className={classes.root}>
      {!isMobile && (
        <div className={classes.breadcrumb}>
          <BreadCrumb
            links={makeCheckoutBreadcrumb(
              props.neededPatientDetails,
              "shipping"
            )}
          />
        </div>
      )}
      <Typography variant={"h4"} align={"left"} className={classes.title}>
        Delivery Details
      </Typography>
      <div className={classes.checkboxRow}>
        <div className={classes.box}>
          <Checkbox checked={isChecked} onChange={handleChange} />
        </div>
        <div>
          <Typography variant={"body1"}>Same as Your details</Typography>
        </div>
      </div>
      <AddressForm
        onSubmit={onSubmit}
        address={props.address}
        postCodeLookUpAddresses={props.postCodeLookUpAddresses}
        onClickPostCodeSearch={props.onClickPostCodeSearch}
        error={props.error}
        removeMarketing
      />
    </div>
  );
};

export const makeCheckoutBreadcrumb = (
  needsPatientDetails: boolean,
  page: string
): BreadCrumbLink[] => {
  const home = {
    name: "Home",
    linkTo: "/",
  };
  const details = {
    name: "Patient details",
    linkTo: "/patient-details",
  };
  const shipping = {
    name: "Shipping details",
    linkTo: page === "shipping" ? "" : "/shipping",
  };
  const billing = {
    name: "Your details",
    linkTo: page === "billing" ? "" : "/your-details",
  };
  const payment = {
    name: "Payment",
    linkTo: page === "payment" ? "" : "/payment",
  };

  switch (page) {
    case "billing":
      return needsPatientDetails ? [home, details, shipping] : [home, billing];
    case "shipping":
      return needsPatientDetails
        ? [home, details, billing, shipping]
        : [home, billing, shipping];
    case "payment":
      return needsPatientDetails
        ? [home, details, billing, shipping, payment]
        : [home, billing, shipping, payment];
    default:
      return [];
  }
};

export default ShippingPage;
