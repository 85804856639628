import { Dispatch, AnyAction, MiddlewareAPI } from "redux";
import { ON_PAY_RESPONSE, OnPayResponseAction } from "./types";
import ReactGA from "react-ga";
import { getShippingCost, getBasketItems, getTotalCost } from "../../selectors";

export const gaPurchaseMiddleware = (store: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  if (action.type !== ON_PAY_RESPONSE || !process.env.REACT_APP_GA_ID) {
    return next(action);
  }

  const a = action as OnPayResponseAction;

  const state = store.getState();
  getBasketItems(state).forEach((bi) => {
    ReactGA.plugin.execute("ec", "addProduct", {
      id: bi.product.sku,
      name: bi.product.name,
      category: bi.product.categories,
      price: bi.product.cost / 100,
      quantity: bi.qty,
      currency: "GBP",
    });
  });

  ReactGA.plugin.execute("ec", "setAction", "purchase", {
    id: `${a.payload.orderID}`,
    revenue: getTotalCost(state) / 100,
    currency: "GBP",
    shipping: getShippingCost(state) / 100,
  });

  ReactGA.event({
    category: "ecommerce",
    action: "purchase",
    nonInteraction: true,
  });

  return next(action);
};
