import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  buttons: {
    textTransform: "none",
  },
  error: {
    marginBottom: 10,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: 20,
  },
}));

export default useStyles;
