import { Product } from "../store/reducer/products/types";
import { BasketItem } from "../store/reducer/basket/types";

export const addDisabled = (
  prod: Product,
  maxParacetamol: boolean,
  qty: number
): boolean => {
  if (qty >= prod.maxPurchaseNo) return true;

  if (prod.containsParacetamol && maxParacetamol) return true;

  if (prod.inventoryCount <= 0 || prod.inventoryCount <= qty) return true;

  return false;
};

export const getCostStr = (cost: number): string => {
  return (cost / 100).toFixed(2);
};

export const calcBasketItem = (
  prod: Product,
  basketItems: BasketItem[]
): BasketItem => {
  for (const index in basketItems) {
    if (basketItems[index].product.sku === prod.sku) {
      return {
        ...basketItems[index],
      };
    }
  }
  return {
    product: prod,
    qty: 0,
  };
};
