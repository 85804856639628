import React from "react";
import { Container, useTheme, useMediaQuery, Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

//Components.
import ProductGrid from "../../components/ProductGrid";
import Categories from "../../components/Categories";
import MobileSearchPanel from "../../components/Banner/MobileSearchPanel";
import DesktopSearchPanel from "../../components/Banner/DesktopSearchPanel";
import ShopInfo from "../../components/Banner/ShopInfo";
import NewsBanner from "../../components/Banner/NewsBanner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 35,
    },
  })
);

export interface ProductListParentProps {
  home?: boolean;
}

export interface BannerStateProps {
  totalItems: number;
  showNews: boolean;
}

export interface BannerDispatchProps {
  onMobileCartClick(): void;
  onDesktopCartClick(): void;
  onCatClick(): void;
}

export type BannerProps = BannerStateProps &
  BannerDispatchProps &
  ProductListParentProps;

const ProductList: React.FC<BannerProps> = ({
  totalItems,
  onMobileCartClick,
  onDesktopCartClick,
  home,
  onCatClick,
  showNews,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <NewsBanner show={showNews} />
      {isMobile ? (
        <MobileSearchPanel
          totalItems={totalItems}
          onCartClick={onMobileCartClick}
          onCatClick={onCatClick}
        />
      ) : (
        <DesktopSearchPanel
          totalItems={totalItems}
          onCartClick={onDesktopCartClick}
        />
      )}
      {home && <ShopInfo mobile={isMobile} />}
      <Box display={"flex"}>
        {!isMobile && (
          <Box>
            <Categories />
          </Box>
        )}
        <Box>
          <Container className={classes.container}>
            <ProductGrid isMobile={isMobile} tablet={isTablet} />
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default ProductList;
