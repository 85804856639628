import { Button, Container, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import { useAwinConversionTracking } from "../../services/awin";
import { BasketItem } from "../../store/reducer/basket/types";
import analytics from "../../analytics";
import { completePage } from "../../analytics/types";

export interface ConfirmationStateProps {
  isB2B: boolean;
  orderId: string;
  orderItemsTotal: number;
  basketItems: BasketItem[];
}

export interface ConfirmationDispatchProps {
  clearCheckout(): void;
  clearBasket(): void;
}

export type ConfirmationProps = ConfirmationStateProps &
  ConfirmationDispatchProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmBox: {
      border: `1px solid ${theme.palette.primary.dark}`,
      width: "50%",
      minWidth: 300,
      margin: "auto",
      marginTop: "80px",
    },
    title: {
      fontWeight: "bold",
      marginTop: 45,
      marginBottom: 40,
    },
    text: {
      marginTop: 10,
    },
    button: {
      marginTop: 50,
      margin: "auto",
      marginBottom: 20,
      width: "100%",
      textTransform: "none",
    },
  })
);

const Confirmation: React.FC<ConfirmationProps> = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Banner />
      <Container>
        <div className={classes.confirmBox}>
          {props.isB2B ? <B2B /> : <B2C {...props} />}
        </div>
      </Container>
    </div>
  );
};

const B2B: React.FC = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography variant={"h4"} align={"center"} className={classes.title}>
        Thank you
      </Typography>
      <Typography variant={"h6"} align={"center"} className={classes.title}>
        We’ve successfully processed your payment. Your Well Pharmacy Sales
        Manager will be in touch to confirm receipt of payment.
      </Typography>
    </Container>
  );
};

const B2C: React.FC<ConfirmationProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  useAwinConversionTracking({
    orderReference: props.orderId,
    itemTotal: props.orderItemsTotal,
  });

  useEffect(() => {
    if (props.basketItems.length > 0) {
      analytics.trackEventWithBasketDetails(
        {
          event: completePage,
        },
        props.basketItems
      );
      props.clearCheckout();
      props.clearBasket();
    }
  }, [props]);

  return (
    <Container>
      <Typography variant={"h4"} align={"center"} className={classes.title}>
        Thank you, we’ve got your order
      </Typography>
      <Typography variant={"h6"} align={"center"} className={classes.title}>
        Thanks for shopping with Well Pharmacy. We’ve sent you an email
        confirming your order.
      </Typography>
      <Typography variant={"body1"} align={"left"} className={classes.text}>
        We’ll dispatch your order soon. Please see our terms and conditions for
        information about deliveries.
      </Typography>
      <Typography variant={"body1"} align={"left"} className={classes.text}>
        If you have any questions about your order, please contact us at
        support@digital.well.co.uk.
      </Typography>
      <Button
        variant={"contained"}
        color={"secondary"}
        className={classes.button}
        onClick={() => {
          history.push("/");
        }}
      >
        Continue shopping
      </Button>
    </Container>
  );
};

export default Confirmation;
