import * as b2bPayment from "./reducer/b2bPayments/selectors";
import * as basket from "./reducer/basket/selectors";
import { BasketItem } from "./reducer/basket/types";
import * as categories from "./reducer/category/selectors";
import { Category } from "./reducer/category/types";
import * as checkout from "./reducer/checkout/selectors";
import {
  Address,
  PaymentError,
  PostcodeLookupAddress,
} from "./reducer/checkout/types";
import * as products from "./reducer/products/selectors";
import * as productSuggestions from "./reducer/productSuggestions/selectors";
import {
  HighlightedCategories,
  SelectedProduct,
} from "./reducer/products/types";
import { ProductSuggestion } from "./reducer/productSuggestions/types";
import { RootState } from "./types";

export function getSelectedProduct(state: RootState): SelectedProduct {
  return products.getSelectedProduct(state.products);
}

export const getHighlightedCategories = (
  state: RootState
): HighlightedCategories[] => {
  return products.getHighlightedCategories(state.products);
};

export function getTotalItems(state: RootState): number {
  return basket.getTotalItems(state.basket);
}

export function getTotalCost(state: RootState): number {
  return basket.getTotalCost(state.basket);
}

export function getBasketItems(state: RootState): BasketItem[] {
  return basket.getBasketItems(state.basket);
}

export function showMobileBasket(state: RootState): boolean {
  return basket.showMobileBasket(state.basket);
}

export function showMobileCat(state: RootState): boolean {
  return basket.showMobileCat(state.basket);
}

export function showDesktopBasket(state: RootState): boolean {
  return basket.showDesktopBasket(state.basket);
}

export function maxParacetamol(state: RootState): boolean {
  return basket.maxParacetamol(state.basket);
}

export function getShippingAddress(state: RootState): Address {
  return checkout.getShippingAddress(state.checkout);
}

export function getBillingAddress(state: RootState): Address {
  return checkout.getBillingAddress(state.checkout);
}

export function getBraintreeToken(state: RootState): string {
  return checkout.getBraintreeToken(state.checkout);
}

export function getConfirmedPayment(state: RootState): boolean {
  return checkout.getConfirmedPayment(state.checkout);
}

export function getPostcodeLookupError(state: RootState): string {
  return checkout.getPostcodeLookupError(state.checkout);
}

export function getPostcodeAddressLookup(
  state: RootState
): PostcodeLookupAddress[] {
  return checkout.getPostcodeAddressLookup(state.checkout);
}

export function getCheckoutAWCToken(state: RootState): string | null {
  return checkout.getAWCToken(state.checkout);
}

export function paymentActive(state: RootState): boolean {
  return basket.paymentActive(state.basket);
}

export function checkoutActive(state: RootState): boolean {
  return basket.checkoutActive(state.basket);
}

export const basketPatientDetailsActive = (state: RootState) =>
  basket.patientDetailsActive(state.basket);

export function productActive(state: RootState): boolean {
  return basket.productActive(state.basket);
}

export function getCheckoutError(state: RootState): PaymentError | null {
  return checkout.getError(state.checkout);
}

export function getCategories(state: RootState): Category[] {
  return categories.getCategories(state.categories);
}

export function getSelectedCategory(state: RootState): Category | null {
  return categories.getSelectedCategory(state.categories);
}

export function getShippingCost(state: RootState): number {
  return checkout.getShippingCost(state.checkout);
}

export const getCheckoutPatientDetails = (state: RootState) =>
  checkout.getPatientDetails(state.checkout);

export const getBasketNeedsPatientDetails = (state: RootState) =>
  getBasketItems(state).some((item) => item.product.needsPatientDetails);

export const getB2BPaymentsRequestToken = (state: RootState) =>
  b2bPayment.getPaymentRequestToken(state.b2bPayments);

export const getB2BPaymentsRequestTokenError = (state: RootState) =>
  b2bPayment.getPaymentRequestTokenError(state.b2bPayments);

export const getB2BPaymentsRequestTokenLoading = (state: RootState) =>
  b2bPayment.getPaymentRequestTokenLoading(state.b2bPayments);

export const getCheckoutPaymentPending = (state: RootState) =>
  checkout.getPaymentPending(state.checkout);

export const getCheckoutOrderId = (state: RootState) =>
  checkout.getOrderId(state.checkout);

export const getB2BPaymentsPaymentAmount = (state: RootState) =>
  b2bPayment.getPaymentAmount(state.b2bPayments);

export const getB2BPaymentsPaymentPending = (state: RootState) =>
  b2bPayment.getPaymentPending(state.b2bPayments);

export const getB2BPaymentsPaymentConfirmed = (state: RootState) =>
  b2bPayment.getPaymentConfirmed(state.b2bPayments);

export const getB2BPaymentsPaymentError = (state: RootState) =>
  b2bPayment.getPaymentError(state.b2bPayments);

export const getB2BPaymentsIsB2B = (state: RootState) =>
  b2bPayment.getPaymentisB2B(state.b2bPayments);

export const getB2BPaymentsReference = (state: RootState) =>
  b2bPayment.getReference(state.b2bPayments);

export function getSearchSuggestions(state: RootState): ProductSuggestion[] {
  return productSuggestions.getProductSuggestions(state.productSuggestions);
}
