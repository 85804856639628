import { connect } from "react-redux";
import {
  getBasketNeedsPatientDetails,
  getBasketItems,
} from "../../store/selectors";
import { RootState } from "../../store/types";
import Payment, { PaymentStateProps, PaymentDispatchProps } from "./Payment";
import { startB2CPaymentProcessing } from "../../store/reducer/checkout/actions";

function mapStateToProps(state: RootState): PaymentStateProps {
  return {
    neededPatientDetails: getBasketNeedsPatientDetails(state),
    basketItems: getBasketItems(state),
  };
}

const mapDispatchToProps: PaymentDispatchProps = {
  onLoad: startB2CPaymentProcessing,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
