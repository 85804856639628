import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const avdId = process.env.REACT_APP_AWIN_ADVERTISER_ID;

function addTracker(): string {
  const id = `awin-master-${avdId}`;
  const script = document.createElement("script");
  script.src = `https://www.dwin1.com/${avdId}.js`;
  script.type = "text/javascript";
  script.async = true;
  script.id = id;
  document.body.appendChild(script);
  return id;
}

function removeTracker(id: string) {
  const e = document.getElementById(id);
  if (e) {
    document.body.removeChild(e);
  }
}

interface MasterTrackerData {
  setAWCToken(t: string): void;
}

export function useAwinMasterTracker(d: MasterTrackerData) {
  const location = useLocation();
  useEffect(() => {
    // Awin says we should not include thier tracker on payment screens.
    if (location.pathname.includes("payment")) {
      return;
    }
    const id = addTracker();
    return () => removeTracker(id);
  }, [location]);

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    const awc = qs.get("awc");
    if (awc) {
      d.setAWCToken(awc);
    }
  }, [location, d]);
}

export interface AwinConversionData {
  itemTotal: number; // in pence
  orderReference: string;
}

// getNetTotal returns the total without taxes and shipping.
function getNetTotal(d: AwinConversionData): number {
  return d.itemTotal * 0.8; // 20% VAT
}

function formatItems(d: AwinConversionData): string {
  // we only have one category.
  return `DEFAULT:${formatPriceInPence(getNetTotal(d))}`;
}

function formatPriceInPence(n: number): string {
  return (n / 100).toFixed(2);
}

function createConversionScript(d: AwinConversionData): HTMLElement {
  const el = document.createElement("script");
  el.type = "text/javascript";
  el.textContent = `
    //<![CDATA[
      var AWIN = {};
      AWIN.Tracking = {};
      AWIN.Tracking.Sale = {};
      AWIN.Tracking.Sale.amount = "${formatPriceInPence(getNetTotal(d))}";
      AWIN.Tracking.Sale.orderRef = "${d.orderReference}";
      AWIN.Tracking.Sale.parts = "${formatItems(d)}";
      AWIN.Tracking.Sale.currency = "GBP";
      AWIN.Tracking.Sale.test = "0";
      AWIN.Tracking.Sale.channel = "aw";
    //]]>
  `;
  return el;
}

function createConversionImage(d: AwinConversionData): HTMLElement {
  const img = document.createElement("img");
  const qs = Object.entries({
    tt: "ns",
    tv: 2,
    merchant: avdId,
    amount: formatPriceInPence(getNetTotal(d)),
    cr: "GBP",
    ref: d.orderReference,
    parts: formatItems(d),
    ch: "aw",
    testmode: 0,
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  img.src = `https://www.awin1.com/sread.img?${qs}`;
  img.border = "0";
  img.width = 0;
  img.height = 0;
  return img;
}

export function useAwinConversionTracking(d: AwinConversionData) {
  useEffect(() => {
    if (!avdId) return;
    const script = createConversionScript(d);
    const img = createConversionImage(d);
    document.body.appendChild(script);
    document.body.appendChild(img);
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(img);
    };
  }, [d]);
}
