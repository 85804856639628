import { Container, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Banner from "../Banner/Banner";
//Components.
import DesktopCheckout from "../DesktopCheckout/DesktopCheckout";
import MobileCheckout from "../MobileCheckout/MobileCheckout";
import BreadCrumb, { BreadCrumbLink } from "./Breadcrumb/Breadcrumb";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 15,
    },
    root: {
      width: "100%",
    },
    breadcrumb: {
      marginTop: 147,
    },
    hr: {
      color: "E6E6E6",
      marginTop: 20,
    },
    logo: {
      height: 40,
    },
    text: {
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  })
);

export interface CheckoutPropsFromParent {
  view: JSX.Element;
  breadcrumb?: BreadCrumbLink[];
  withoutBasket?: boolean;
  title: string;
}

const Checkout: React.FC<CheckoutPropsFromParent> = (props) => {
  let view: JSX.Element;
  const theme = useTheme();
  const classes = useStyles();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down("sm"));
  if (isSmallerThanMd) {
    view = <MobileCheckout view={props.view} title={props.title} />;
  } else {
    view = (
      <div className={classes.root}>
        {props.breadcrumb && (
          <div className={classes.breadcrumb}>
            <BreadCrumb links={props.breadcrumb} />
          </div>
        )}
        <DesktopCheckout
          view={props.view}
          title={props.title}
          withoutBasket={props.withoutBasket}
        />
      </div>
    );
  }

  return (
    <div>
      <Banner />
      <Container className={classes.container}>{view}</Container>
    </div>
  );
};

export default Checkout;
