import { RSAA } from "redux-api-middleware";
import {
  FETCH_PRODUCT_SUGGESTIONS,
  FETCH_PRODUCT_SUGGESTIONS_REQUEST,
  FETCH_PRODUCT_SUGGESTIONS_RESPONSE,
  FETCH_PRODUCT_SUGGESTIONS_FAILURE,
  FetchProductSuggestionsAction,
  ClearSuggestionsResponseAction,
  CLEAR_PROD_SELECTION,
} from "./types";
import { API_ENDPOINT } from "../../config";

export function fetchProductSuggestions(
  q: string,
  endpoint: string = API_ENDPOINT
): FetchProductSuggestionsAction {
  return {
    type: FETCH_PRODUCT_SUGGESTIONS,
    [RSAA]: {
      endpoint: `${endpoint}/products/suggest?q=${q}`,
      method: "GET",
      types: [
        FETCH_PRODUCT_SUGGESTIONS_REQUEST,
        FETCH_PRODUCT_SUGGESTIONS_RESPONSE,
        FETCH_PRODUCT_SUGGESTIONS_FAILURE,
      ],
    },
  };
}

export function clearSelected(): ClearSuggestionsResponseAction {
  return {
    type: CLEAR_PROD_SELECTION,
  };
}
