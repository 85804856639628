import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Checkout from "../../components/Checkout/Checkout";
import Payment from "../../components/Payment/B2B";
import { getCostStr } from "../../utils/utils";
import { pageTitle, topInfoParagraphs } from "./copy";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 900,
  },
  wrapperSmallerThanMd: {
    // Braintree drop in styling applies margin padding of 8
    paddingLeft: 8,
    paddingRight: 8,
  },
  wrapper: {
    // Braintree drop in styling applies margin padding of 8 and our wrapper uses theme.spacing(2)
    paddingLeft: theme.spacing(2) + 8,
    paddingRight: theme.spacing(2) + 8,
  },
}));

export interface PaymentStateProps {
  reference: string;
  amount: number;
}

export interface PaymentDispatchProps {
  onLoad(): void;
}

export type PaymentProps = PaymentStateProps & PaymentDispatchProps;

const PaymentPage: React.FC<PaymentProps> = ({ amount, reference, onLoad }) => {
  const [isDataCleared, setIsDataCleared] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down("sm"));

  // This is clearing the data on first mount
  useEffect(() => {
    onLoad();
    setIsDataCleared(true);
  }, [onLoad]);

  const view: JSX.Element = (
    <Grid container direction={"column"} spacing={2}>
      <Grid
        container
        direction={"column"}
        spacing={2}
        className={
          isSmallerThanMd ? classes.wrapperSmallerThanMd : classes.wrapper
        }
      >
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography className={classes.bold} display={"inline"}>
              {"Order reference: "}
            </Typography>
            <Typography display={"inline"}>{`${reference}`}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.bold} display={"inline"}>
              {"Payment amount: £"}
            </Typography>
            <Typography display={"inline"}>{getCostStr(amount)}</Typography>
          </Grid>
        </Grid>
        {topInfoParagraphs.map((p, i) => (
          <Grid key={i} item>
            <Typography>{p}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item>{isDataCleared ? <Payment /> : null}</Grid>
    </Grid>
  );

  // Run on load on mount
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return <Checkout view={view} title={pageTitle} withoutBasket />;
};

export default PaymentPage;
