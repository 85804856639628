import { connect } from "react-redux";
import { RootState } from "../../store/types";

import ProductDetailsPage, {
  ProductDetailsPageDispatchProps,
  ProductDetailsPageStateProps,
} from "./ProductDetailPage";
import { getTotalItems } from "../../store/selectors";
import {
  toggleDesktopBasket,
  toggleMobileBasket,
  toggleMobileCat,
} from "../../store/reducer/basket/action";

function mapStateToProps(state: RootState): ProductDetailsPageStateProps {
  return {
    totalItems: getTotalItems(state),
  };
}

const mapDispatchToProps: ProductDetailsPageDispatchProps = {
  onDesktopCartClick: toggleDesktopBasket,
  onMobileCartClick: toggleMobileBasket,
  onCatClick: toggleMobileCat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage);
