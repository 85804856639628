import { RichTextBlock } from "prismic-reactjs";
import { RSAA } from "redux-api-middleware";
import { Category } from "../category/types";

export interface Product {
  sku: string;
  name: string;
  description: RichTextBlock[];
  shortDescription: RichTextBlock[];
  imageUrl: string;
  length: number;
  width: number;
  height: number;
  cost: number;
  maxPurchaseNo: number;
  containsParacetamol: boolean;
  ingredients: RichTextBlock[];
  directions: RichTextBlock[];
  warnings: RichTextBlock[];
  categories: string[];
  maxDailyCount: number;
  inventoryCount: number;
  needsPatientDetails: boolean;
}

export interface State {
  fetching: boolean;
  selectedProduct: SelectedProduct;
  highlightedCategories: HighlightedCategories[];
}

export interface SelectedProduct {
  product: Product;
  relatedProducts: Product[];
}

export interface HighlightedCategories {
  category: Category;
  products: Product[];
}

export const FETCH_PRODUCTS_HIGHLIGHT = "PRODUCTS/FETCH_PRODUCTS_HIGHLIGHT";
export const FETCH_PRODUCTS_HIGHLIGHT_RESPONSE =
  "PRODUCTS/FETCH_PRODUCTS_HIGHLIGHT_RESPONSE";

export const FETCH_CAT_PRODUCTS = "PRODUCTS/FETCH_CAT_PRODUCTS";
export const FETCH_CAT_PRODUCTS_RESPONSE =
  "PRODUCTS/FETCH_CAT_PRODUCTS_RESPONSE";

export const FETCH_PRODUCT_DETAIL = "PRODUCTS/FETCH_PRODUCT_DETAIL";
export const FETCH_PRODUCT_DETAIL_RESPONSE =
  "PRODUCTS/FETCH_PRODUCT_DETAIL_RESPONSE";

export const FETCH_PRODUCTS_FAIL = "PRODUCTS/FETCH_PRODUCTS_FAIL";

export interface FetchCategoryProductsAction {
  type: string;
  payload: {
    category: string;
  };
}

export interface FetchProductDetailsAction {
  type: string;
  payload: {
    sku: string;
  };
}

export interface FetchHighlightProductsAction {
  type: string;
}

export interface FetchProductsHighlightedResponseAction {
  type: string;
  payload: {
    highlightedCategories: HighlightedCategories[];
  };
}

export interface FetchProductDetailsResponseAction {
  type: string;
  payload: {
    selectedProduct: SelectedProduct;
  };
}

export const FETCH_PRODUCT_SALES_COUNT = "PRODUCTS/FETCH_PRODUCT_SALES_COUNT";
export const FETCH_PRODUCT_SALES_COUNT_REQUEST =
  "PRODUCTS/FETCH_PRODUCT_SALES_COUNT_REQUEST";
export const FETCH_PRODUCT_SALES_COUNT_RESPONSE =
  "PRODUCTS/FETCH_PRODUCT_SALES_COUNT_RESPONSE";
export const FETCH_PRODUCT_SALES_COUNT_FAILURE =
  "PRODUCTS/FETCH_PRODUCT_SALES_COUNT_FAILURE";

export interface FetchProductSalesCountAction {
  type: string;
  [RSAA]: {
    endpoint: string;
    method: string;
    types: [string, string, string];
  };
}

export interface FetchProductSalesCountResponseAction {
  type: string;
  payload: {
    counts: { [key: string]: number };
  };
}
