import { Product } from "../products/types";

export interface BasketItem {
  product: Product;
  qty: number;
}

export interface State {
  items: BasketItem[];
  showMobileBasket: boolean;
  showMobileCat: boolean;
  showDesktopBasket: boolean;
  checkoutActive: boolean;
  paymentActive: boolean;
  productActive: boolean;
  patientDetailsActive: boolean;
}

export const TOGGLE_MOBILE = "MOBILE_BASKET/TOGGLE_MOBILE";
export const CLOSE_MOBILE_BASKET = "MOBILE_BASKET/CLOSE";

export const TOGGLE_DESKTOP = "DESKTOP_BASKET/TOGGLE_DESKTOP";
export const CLOSE_DESKTOP_BASKET = "DESKTOP_BASKET/CLOSE";

export const TOGGLE_MOBILE_CAT = "MOBILE/TOGGLE_MOBILE_CAT";
export const ADD_TO_BASKET = "ADD_TO_BASKET";

export interface AddToBasketAction {
  type: string;
  payload: {
    product: Product;
    qty: number;
  };
}

export const BASKET_SET_CHECKOUT_ACTIVE = "BASKET/SET_CHECKOUT";
export const BASKET_SET_PAYMENT_ACTIVE = "BASKET/SET_PAYMENT";
export const BASKET_SET_PRODUCT_ACTIVE = "BASKET/SET_PRODUCT";
export const BASKET_SET_PATIENT_DETAILS_ACTIVE =
  "BASKET/SET_PATIENT_DETAILS_ACTIVE";

export const CLEAN_BASKET = "BASKET/CLEAN";

export interface CleanBasketAction {
  type: string;
}

export interface ToggleBasketActon {
  type: string;
}
