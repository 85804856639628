import { Product } from "../reducer/products/types";
import { Document as DocType } from "@prismicio/client/types/documents";
import ApiSearchResponse from "@prismicio/client/types/ApiSearchResponse";
import { Category } from "../reducer/category/types";

import Prismic from "@prismicio/client";
import { PrismicEndPoint } from "../../services/prismic";

const Client = Prismic.client(PrismicEndPoint);

export const makeProdArrayFromPrismic = (res: ApiSearchResponse): Product[] => {
  return res.results.map((p: DocType): Product => makeProdFromPrismicDoc(p));
};

export const makeCatArrayFromPrismic = (res: ApiSearchResponse): Category[] => {
  return res.results.map((p: DocType): Category => makeCatFromPrismic(p));
};

export const makeCatFromPrismic = (res: DocType): Category => {
  if (res === undefined) return {} as Category;
  return {
    id: res.uid ? res.uid : "",
    name: res?.data?.name,
    description: res?.data?.description,
  };
};

export const makeProdFromPrismicDoc = (p: DocType): Product => {
  if (p === undefined) return {} as Product;
  return {
    sku: p.uid ? p.uid : "",
    name: p?.data?.name,
    description: p?.data?.description,
    shortDescription: p?.data?.short_description,
    imageUrl: p?.data?.image?.url,
    length: p?.data?.length,
    width: p?.data?.width,
    height: p?.data?.height,
    cost: p?.data?.cost,
    maxPurchaseNo: p?.data?.maximum_purchase,
    containsParacetamol: p?.data?.contains_paracetamol,
    ingredients: p?.data?.ingredients,
    directions: p?.data?.directions,
    warnings: p?.data?.warnings,
    categories: p?.data?.categories?.map((cat: any) => cat.category.uid),
    maxDailyCount: p?.data?.maximum_daily_sales,
    inventoryCount: 500,
    needsPatientDetails: p?.data?.needs_patient_details,
  };
};

export const queryHighlightedCategories = async (): Promise<ApiSearchResponse> => {
  const f = (page: number) =>
    Client.query(
      [
        Prismic.Predicates.at("document.type", "category"),
        Prismic.Predicates.at("my.category.highlighted", true),
        Prismic.Predicates.at("my.category.visible", true),
      ],
      { orderings: "[my.category.highlight_position]", page }
    );
  return await doWithPagination(f);
};

export const queryHighlightedProds = async (
  catId: string
): Promise<ApiSearchResponse> => {
  const f = (page: number) =>
    Client.query(
      [
        Prismic.Predicates.at("document.type", "product"),
        Prismic.Predicates.at("my.product.visible", true),
        Prismic.Predicates.at("my.product.highlighted", true),
        Prismic.Predicates.at("my.product.categories.category", catId),
      ],
      { orderings: "[my.product.highlight_position]", page }
    );
  return await doWithPagination(f);
};

export const getByIds = async (ids: string[]): Promise<ApiSearchResponse> => {
  const docs = await Client.getByIDs(ids, {});
  return docs;
};

export const getByUid = async (
  docType: string,
  id: string
): Promise<DocType> => {
  const doc = await Client.getByUID(docType, id, {});
  return doc;
};

export const getProductsInCategory = async (
  catId: string
): Promise<ApiSearchResponse> => {
  const f = (page: number) =>
    Client.query(
      [
        Prismic.Predicates.at("document.type", "product"),
        Prismic.Predicates.at("my.product.categories.category", catId),
        Prismic.Predicates.at("my.product.visible", true),
      ],
      { orderings: "[my.product.highlight_position]", page }
    );

  return await doWithPagination(f);
};

export const getAllCategories = async (): Promise<ApiSearchResponse> => {
  const f = (page: number) =>
    Client.query(
      [
        Prismic.Predicates.at("document.type", "category"),
        Prismic.Predicates.at("my.category.visible", true),
      ],
      { orderings: "[my.category.highlight_position]", page }
    );

  return await doWithPagination(f);
};

const doWithPagination = async (
  f: (page: number) => Promise<ApiSearchResponse>
): Promise<ApiSearchResponse> => {
  let page = 1;
  let totalPages = 2;
  let response: ApiSearchResponse = {} as ApiSearchResponse;
  while (page <= totalPages) {
    const responseTmp = await f(page);
    if (page === 1) {
      response = responseTmp;
      totalPages = responseTmp.total_pages;
    } else {
      response.results.push(...responseTmp.results);
    }
    page = responseTmp.page + 1;
  }

  return response;
};
