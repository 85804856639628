import { connect } from "react-redux";
import { RootState } from "../../store/types";
import ProductList, {
  BannerStateProps,
  BannerDispatchProps,
} from "./ProductList";
import {
  getTotalItems,
  showMobileBasket,
  showMobileCat,
} from "../../store/selectors";
import {
  toggleDesktopBasket,
  toggleMobileBasket,
  toggleMobileCat,
} from "../../store/reducer/basket/action";

function mapStateToProps(state: RootState): BannerStateProps {
  return {
    totalItems: getTotalItems(state),
    showNews: !showMobileBasket(state) && !showMobileCat(state),
  };
}

const mapDispatchToProps: BannerDispatchProps = {
  onDesktopCartClick: toggleDesktopBasket,
  onMobileCartClick: toggleMobileBasket,
  onCatClick: toggleMobileCat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
