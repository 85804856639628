import { connect } from "react-redux";
import { RootState } from "../../../store/types";
import MobileCat, {
  MobileCatStateProps,
  MobileCatDispatchProps,
} from "./MobileCat";
import { getCategories, showMobileCat } from "../../../store/selectors";
import { toggleMobileCat } from "../../../store/reducer/basket/action";
import { fetchCategories } from "../../../store/reducer/category/actions";

function mapStateToProps(state: RootState): MobileCatStateProps {
  return {
    show: showMobileCat(state),
    categories: getCategories(state),
  };
}

const mapDispatchToProps: MobileCatDispatchProps = {
  onClose: toggleMobileCat,
  fetchCategories: fetchCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileCat);
