import { Dispatch, MiddlewareAPI, AnyAction } from "redux";
import ReactGA from "react-ga";
import { ADD_TO_BASKET, AddToBasketAction } from "./types";

export const gaBasketMiddleware = (store: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  if (action.type !== ADD_TO_BASKET || !process.env.REACT_APP_GA_ID) {
    return next(action);
  }

  const a = action as AddToBasketAction;
  ReactGA.plugin.execute("ec", "addProduct", {
    id: a.payload.product.sku,
    name: a.payload.product.name,
    category: a.payload.product.categories,
    price: a.payload.product.cost / 100,
  });
  ReactGA.plugin.execute(
    "ec",
    "setAction",
    a.payload.qty > 0 ? "add" : "remove"
  );
  ReactGA.event({
    category: "ecommerce",
    action: "basket",
    nonInteraction: true,
  });

  return next(action);
};
