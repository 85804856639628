import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";

import { Category } from "../../store/reducer/category/types";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300,
      marginTop: 90,
      paddingLeft: 68,
    },
    menu: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.text.primary,
      marginBottom: 26,
    },
    menuItem: {
      "&:hover": {
        color: "#0059F0",
      },
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.text.primary,
      textDecoration: "none",
      marginBottom: 19,
    },
    selectedMenuItem: {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.primary.dark,
      textDecoration: "none",
      marginBottom: 19,
    },
  })
);

export interface CategoriesStateProps {
  categories: Category[];
}

export interface CategoriesDispatchProps {
  fetchCategories(): void;
}

interface ParamTypes {
  category: string;
}

export type CategoriesProps = CategoriesStateProps & CategoriesDispatchProps;

const Categories: React.FC<CategoriesProps> = ({
  categories,
  fetchCategories,
}) => {
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const classes = useStyles();
  const { category } = useParams<ParamTypes>();

  return (
    <div className={classes.root}>
      <Typography variant={"h5"} className={classes.menu}>
        Categories
      </Typography>
      {categories.map((cat) => (
        <Link
          to={`/category/${cat.id.toLowerCase()}/products`}
          className={classes.menuItem}
          key={cat.id}
        >
          {cat.id.toLowerCase() === category?.toLowerCase() ? (
            <Typography variant={"body1"} className={classes.selectedMenuItem}>
              {cat.name}
            </Typography>
          ) : (
            <Typography variant={"body1"} className={classes.menuItem}>
              {cat.name}
            </Typography>
          )}
        </Link>
      ))}
    </div>
  );
};

export default Categories;
