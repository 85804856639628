export default class Marketing {
  private enabled: boolean;

  constructor() {
    this.enabled = false;
  }

  public enable() {
    this.enabled = true;
  }

  public isEnabled(): boolean {
    return this.enabled;
  }
}
