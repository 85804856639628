import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import Logo from "../Logo/Logo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 15,
    },
    root: {
      width: "100%",
    },
    breadcrumb: {
      marginTop: 147,
    },
    logo: {
      height: 40,
      display: "inline-block",
    },
    text: {
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  })
);

const Banner: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div className={classes.logo}>
        <Logo />
      </div>
      <Typography variant={"body2"} align={"left"} className={classes.text}>
        shop
      </Typography>
    </Container>
  );
};

export default Banner;
