import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Breadcrumbs as BreadcrumbsMaterial,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
    bold: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
  })
);

export interface BreadcrumbProps {
  links: BreadCrumbLink[];
}

export interface BreadCrumbLink {
  name: string;
  linkTo: string;
}
const BreadCrumb: React.FC<BreadcrumbProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BreadcrumbsMaterial separator={">"}>
        {props.links.map((link) => {
          if (link.linkTo === "") {
            return (
              <Typography
                variant={"body1"}
                align={"left"}
                className={classes.bold}
                key={link.name}
              >
                {link.name}
              </Typography>
            );
          }
          return (
            <Link to={link.linkTo} className={classes.link} key={link.name}>
              <Typography variant={"body1"} align={"left"}>
                {link.name}
              </Typography>
            </Link>
          );
        })}
      </BreadcrumbsMaterial>
    </div>
  );
};

export default BreadCrumb;
