import React, { useState, useCallback } from "react";
import { Modal } from "@material-ui/core";
import CookieManagerConsentView from "@welldigital/ui-common/Components/CookiesManager/components/CookieManagerConsentView";
import CookieManagerPreferencesView from "@welldigital/ui-common/Components/CookiesManager/components/CookieManagerPreferencesView";
import { CookiesPreferenceNames } from "@welldigital/ui-common/Components/CookiesManager/constants";
import { cookiesViewsStyles } from "@welldigital/ui-common/Components/CookiesManager/cookiesViewsStyles";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  clearAllCookies,
  cookie,
} from "@welldigital/ui-common/Components/CookiesManager/helpers";
import initializeGTM from "../../services/gtm";
import { initializeHotJar } from "../../services/hotjar";
import ReactGA from "react-ga";
import { trackCheckoutStep, loadIndicativePlugin } from "../../services/ga";
import { useLocation } from "react-router-dom";
import analytics from "../../analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      color: "#333333",
      fontWeight: 500,
      "& .MuiBox-root .MuiButtonBase-root": {
        textTransform: "none",
        padding: theme.spacing(1, 1.5),
        fontWeight: 600,
        borderRadius: 6,
        height: 48,
      },
      "& .MuiTypography-h5": {
        fontWeight: 600,
      },
      "& .MuiTypography-body1": {
        fontWeight: 500,
        letterSpacing: 0.5,
        lineHeight: "24px",
      },
      "& .MuiContainer-root .MuiTypography-h6": {
        fontWeight: "bold",
        lineHeight: "27px",
        letterSpacing: "0.15px",
      },
      "& .MuiGrid-root .MuiSwitch-root": {
        padding: 0,
        margin: 8,
        width: "52px",
        height: "26px",
      },
      "& .MuiBox-root .MuiContainer-root .MuiTypography-h5": {
        lineHeight: "36px",
        "& button": {
          fontFamily: "GilroyAlt",
        },
      },
      "& .MuiGrid-root .MuiSwitch-root .MuiButtonBase-root": {
        width: "26px",
        height: "26px",
        padding: "2px",
        "& .MuiIconButton-label": {
          height: "22px",
          "& .MuiSwitch-thumb": {
            width: "22px",
            height: "22px",
          },
        },
        "& + .MuiSwitch-track": {
          borderRadius: "13px",
        },
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(26px)",
      },
    },
  })
);

export enum Panel {
  home = "home",
  preferences = "preferences",
}

export type CookieManagerModalProps = {
  onDone: () => void;
  onPanelChangeIntent: (panel: Panel | null) => void;
  panel: Panel | null;
};

const panels = new Set<Panel | null>([Panel.home, Panel.preferences]);

export const CookieManagerModal: React.FC<CookieManagerModalProps> = (
  props
) => {
  const className = cookiesViewsStyles();
  const classes = useStyles();

  const {
    cookiePreferencesSet,
    cookiePreferencesAllowAnalytics,
    cookiePreferencesAllowFunctional,
    cookiePreferencesAllowAds,
  } = CookiesPreferenceNames;
  const { onDone, panel, onPanelChangeIntent } = props;
  const isOpen = panels.has(panel);
  const isPreferenceSet = cookie.get(cookiePreferencesSet) === "true";
  const [isFunctionalChecked, setFunctionalChecked] = useState<boolean>(
    cookie.get(cookiePreferencesAllowFunctional) === "true"
  );
  const [isAnalyticsChecked, setAnalyticsChecked] = useState<boolean>(
    cookie.get(cookiePreferencesAllowAnalytics) === "true"
  );
  const [isAdsChecked, setAdsChecked] = useState<boolean>(
    cookie.get(cookiePreferencesAllowAds) === "true"
  );

  const handleAcceptAllCookies = useCallback(() => {
    clearAllCookies();
    setFunctionalChecked(true);
    setAnalyticsChecked(true);
    setAdsChecked(true);
    writePreference(cookiePreferencesSet, true);
    writePreference(cookiePreferencesAllowAnalytics, true);
    writePreference(cookiePreferencesAllowAds, true);
    writePreference(cookiePreferencesAllowFunctional, true);
    onDone();
  }, [
    cookiePreferencesSet,
    cookiePreferencesAllowAnalytics,
    cookiePreferencesAllowAds,
    cookiePreferencesAllowFunctional,
    onDone,
    setFunctionalChecked,
    setAnalyticsChecked,
    setAdsChecked,
  ]);

  const handlePreference = useCallback(() => {
    clearAllCookies();
    writePreference(cookiePreferencesSet, true);
    writePreference(cookiePreferencesAllowAnalytics, isAnalyticsChecked);
    writePreference(cookiePreferencesAllowAds, isAdsChecked);
    writePreference(cookiePreferencesAllowFunctional, isFunctionalChecked);
    onDone();
  }, [
    cookiePreferencesSet,
    cookiePreferencesAllowAnalytics,
    cookiePreferencesAllowAds,
    cookiePreferencesAllowFunctional,
    isAnalyticsChecked,
    isAdsChecked,
    isFunctionalChecked,
    onDone,
  ]);

  const handleFunctionalCookiesPreference = useCallback(() => {
    setFunctionalChecked((isFunctionalChecked) => !isFunctionalChecked);
  }, []);
  const handleAnalyticsCookiesPreference = useCallback(() => {
    setAnalyticsChecked((isAnalyticsChecked) => !isAnalyticsChecked);
  }, []);
  const handleAdsCookiesPreference = useCallback(() => {
    setAdsChecked((isAdsChecked) => !isAdsChecked);
  }, []);

  const writePreference = (key: string, value: boolean) => {
    return cookie.set(key, value, {
      path: "/",
      expires: cookieExpires,
    });
  };
  const cookieExpires = new Date(+new Date() + 3600 * 24 * 365 * 1000);

  const TRACKING_ID = "process.env.REACT_APP_GA_ID";
  const location = useLocation();

  if (cookie.get("cookie-preferences-allow-analytics") === "true") {
    initializeGTM();

    initializeHotJar();

    // useGATracker();
    ReactGA.initialize(TRACKING_ID);
    ReactGA.plugin.require("ec");
    loadIndicativePlugin();
    ReactGA.plugin.require("indGaPlugin", {
      apikey: process.env.REACT_APP_INDICATIVE_KEY,
    });
    // Track page every time the location changes.
    trackCheckoutStep(location.pathname);
    ReactGA.pageview(window.location.pathname + window.location.search);

    analytics.init();
  }

  const panelToRender =
    panel === Panel.home ? (
      <CookieManagerConsentView
        onCustomizePreferences={onPanelChangeIntent.bind(
          null,
          Panel.preferences
        )}
        onAcceptAllCookies={handleAcceptAllCookies}
      />
    ) : (
      <CookieManagerPreferencesView
        isPreferenceSet={isPreferenceSet}
        onBack={onPanelChangeIntent.bind(null, Panel.home)}
        onClose={onPanelChangeIntent.bind(null, null)}
        onAcceptAllCookies={handleAcceptAllCookies}
        onPreferenceSaving={handlePreference}
        onChangeFunctionalCookiesPreference={handleFunctionalCookiesPreference}
        onChangeAnalyticsCookiesPreference={handleAnalyticsCookiesPreference}
        onChangeAdsCookiesPreference={handleAdsCookiesPreference}
        isFunctionalChecked={isFunctionalChecked}
        isAnalyticsChecked={isAnalyticsChecked}
        isAdsChecked={isAdsChecked}
      />
    );
  return (
    <Modal className={className.modalBackdrop} open={isOpen}>
      <div className={classes.panel}>{panelToRender}</div>
    </Modal>
  );
};

export default CookieManagerModal;
