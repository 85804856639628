import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import B2B from "../../components/B2B/B2B";
import { PaymentRequestData } from "../../store/reducer/b2bPayments/types";
import { pageTitle } from "./copy";
import Form from "./_Form";
import LinkDisplay from "./_Link";

export interface B2BPaymentRequestStateProps {
  token: string | null;
  tokenError: string | null;
  tokenLoading: boolean;
}

export interface B2BPaymentRequestDispatchProps {
  onLoad(): void;
  onSubmit(data: PaymentRequestData): void;
}

export type B2BPaymentRequestProps = B2BPaymentRequestStateProps &
  B2BPaymentRequestDispatchProps;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0px 60px 30px 60px",
  },
}));

const B2BPaymentRequestPage: React.FC<B2BPaymentRequestProps> = ({
  onLoad,
  onSubmit,
  token,
  ...props
}) => {
  const classes = useStyles();
  const [orderReference, setOrderReference] = useState("");
  const [amountInPence, setAmountInPence] = useState(0);

  // This is clearing the data on first mount
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const view: JSX.Element = !token ? (
    <Grid className={classes.container}>
      <Form
        onSubmit={(data: PaymentRequestData) => {
          setOrderReference(data.reference);
          setAmountInPence(Math.floor(data.amount * 100));
          onSubmit(data);
        }}
        loading={props.tokenLoading}
        error={props.tokenError}
      />
    </Grid>
  ) : (
    <Grid className={classes.container}>
      <LinkDisplay
        baseUrl={window.location.href}
        token={token}
        amount={amountInPence}
        reference={orderReference}
      />
    </Grid>
  );

  return <B2B view={view} title={pageTitle} />;
};

export default B2BPaymentRequestPage;
