import { RSAA } from "redux-api-middleware";
import { API_ENDPOINT } from "../../config";
import {
  FetchBraintreeTokenAction,
  OnPayAction,
  OnPayInput,
} from "../checkout/types";
import {
  CreateB2BPaymentRequestTokenAction,
  CREATE_B2B_PAYMENT_REQUEST_TOKEN,
  CREATE_B2B_PAYMENT_REQUEST_TOKEN_FAILURE,
  CREATE_B2B_PAYMENT_REQUEST_TOKEN_REQUEST,
  CREATE_B2B_PAYMENT_REQUEST_TOKEN_RESPONSE,
  FETCH_B2B_BRAINTREE_TOKEN,
  FETCH_B2B_BRAINTREE_TOKEN_FAILURE,
  FETCH_B2B_BRAINTREE_TOKEN_REQUEST,
  FETCH_B2B_BRAINTREE_TOKEN_RESPONSE,
  ON_B2B_PAY,
  ON_B2B_PAY_FAILURE,
  ON_B2B_PAY_REQUEST,
  ON_B2B_PAY_RESPONSE,
  PaymentRequestData,
  StartPaymentRequestProcessAction,
  START_PAYMENT_REQUEST_PROCESS,
  StartB2BPaymentProcessAction,
  START_B2B_PAYMENT_PROCESS,
} from "./types";

export const createB2BPaymentToken = (
  { reference, amount, password }: PaymentRequestData,
  endpoint: string = API_ENDPOINT
): CreateB2BPaymentRequestTokenAction => ({
  type: CREATE_B2B_PAYMENT_REQUEST_TOKEN,
  [RSAA]: {
    endpoint: `${endpoint}/b2b/payment/request`,
    method: "POST",
    body: JSON.stringify({
      reference,
      // amount should be in pence
      amount: Math.round(amount * 100),
      password,
    }),
    types: [
      CREATE_B2B_PAYMENT_REQUEST_TOKEN_REQUEST,
      CREATE_B2B_PAYMENT_REQUEST_TOKEN_RESPONSE,
      CREATE_B2B_PAYMENT_REQUEST_TOKEN_FAILURE,
    ],
  },
});

export const startPaymentRequestProcess = (): StartPaymentRequestProcessAction => ({
  type: START_PAYMENT_REQUEST_PROCESS,
});

export function fetchB2BBraintreeToken(
  dataToken: string,
  endpoint: string = API_ENDPOINT
): FetchBraintreeTokenAction {
  return {
    type: FETCH_B2B_BRAINTREE_TOKEN,
    [RSAA]: {
      endpoint: `${endpoint}/b2b/payment/token`,
      method: "POST",
      body: JSON.stringify({
        token: dataToken,
      }),
      types: [
        FETCH_B2B_BRAINTREE_TOKEN_REQUEST,
        FETCH_B2B_BRAINTREE_TOKEN_RESPONSE,
        FETCH_B2B_BRAINTREE_TOKEN_FAILURE,
      ],
    },
  };
}

export function onB2BPay(
  { nonce, tokenData }: OnPayInput,
  endpoint: string = API_ENDPOINT
): OnPayAction {
  return {
    type: ON_B2B_PAY,
    [RSAA]: {
      endpoint: `${endpoint}/b2b/payment/charge`,
      method: "POST",
      types: [ON_B2B_PAY_REQUEST, ON_B2B_PAY_RESPONSE, ON_B2B_PAY_FAILURE],
      body: JSON.stringify({
        token: tokenData,
        nonce,
      }),
    },
  };
}

export const startB2BPaymentProcessing = (): StartB2BPaymentProcessAction => ({
  type: START_B2B_PAYMENT_PROCESS,
});
