import moment, { Moment } from "moment";
import * as yup from "yup";

const Schema = yup.object().shape({
  firstName: yup.string().trim().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  email: yup
    .string()
    .email("Email is invalid")
    .trim()
    .required("Email is required"),
  confirmEmail: yup
    .string()
    .trim()
    .test("test-name", "Emails must match", function (value) {
      const { resolve } = this;
      return resolve(yup.ref("email")) === value;
    })
    .required("Confirmation eamil is required"),
  dob: yup
    .string()
    .trim()
    .test(
      "test-name",
      "Date of birth should be in the format: 20/02/1986",
      function (value) {
        const { createError } = this;
        // Expecting DD/MM/YYYY
        const parts = value.split("/");
        if (!value || parts.length !== 3) {
          return false;
        }
        const m = moment(value, "DD/MM/YYYY", true);
        if (!m.isValid()) {
          return false;
        }
        if (!isOverEighteen(m)) {
          return createError({
            message: "You must be over 18 to take this test",
          });
        }
        return true;
      }
    )
    .required("Date of birth is required"),
});

const isOverEighteen = (date: Moment) => moment().diff(date, "years") >= 18;

export default Schema;
