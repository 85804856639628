import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";

import Logo from "../Logo/Logo";
import Search from "../Search";

export interface DesktopSearchPanelProps {
  totalItems: number;
  onCartClick(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridRoot: {
      position: "sticky",
      zIndex: 99,
      paddingTop: 32,
      paddingBottom: 40,
      top: 0,
      width: "100%",
      backgroundColor: theme.palette.primary.light,
    },
    maxWidth: {
      maxWidth: 1660,
    },
    logo: {
      marginLeft: 32,
      width: 100,
      height: 34,
    },
    shopHome: {
      marginLeft: 62,
      paddingTop: 9,
    },
    link: {
      textDecoration: "none",
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: 16,
      textDecoration: "none",
      fontWeight: 600,
    },
    cart: {
      "&:hover": {
        cursor: "pointer",
      },
      marginTop: -2,
      paddingTop: 9,
      marginRight: 42,
    },
    shoppingCart: {
      color: theme.palette.text.primary,
      fontSize: 30,
    },
    search: {
      marginRight: 22,
      width: 230,
    },
    cartNumber: {
      background: theme.palette.primary.dark,
      color: theme.palette.text.hint,
      paddingLeft: ({ totalItems }: DesktopSearchPanelProps) =>
        totalItems >= 10 ? 3 : 6,
      borderRadius: 50,
      marginLeft: -10,
      marginTop: -5,
      height: 20,
      width: 20,
      fontSize: 16,
    },
    find: {
      "&:hover": {
        cursor: "pointer",
      },
      paddingTop: 9,
      marginRight: 36,
      color: theme.palette.text.primary,
      fontSize: 16,
      textDecoration: "none",
    },
  })
);

const DesktopSearchPanel: React.FC<DesktopSearchPanelProps> = ({
  totalItems,
  onCartClick,
}) => {
  const classes = useStyles({ totalItems } as DesktopSearchPanelProps);

  return (
    <div className={classes.gridRoot}>
      <div className={classes.maxWidth}>
        <Box display={"flex"}>
          <Box flexGrow={1}>
            <Box display={"flex"} flexDirection={"row"}>
              <Box>
                <a href={"https://www.well.co.uk"} rel={"noopener noreferrer"}>
                  <div className={classes.logo}>
                    <Logo />
                  </div>
                </a>
              </Box>
              <Box>
                <div className={classes.shopHome}>
                  <Link to={`/`} className={classes.link}>
                    <Typography
                      variant={"h5"}
                      align={"center"}
                      className={classes.text}
                    >
                      Shop Home
                    </Typography>
                  </Link>
                </div>
              </Box>
            </Box>
          </Box>

          <Box>
            <div className={classes.find}>
              <a
                href={"https://finder.well.co.uk/"}
                rel={"noopener noreferrer"}
                className={classes.link}
              >
                <Typography
                  variant={"h5"}
                  align={"center"}
                  className={classes.text}
                >
                  Find a pharmacy
                </Typography>
              </a>
            </div>
          </Box>
          <Box>
            <div className={classes.search}>
              <Search />
            </div>
          </Box>
          <Box>
            <div className={classes.cart}>
              <Box display={"flex"}>
                <Box>
                  <ShoppingCartIcon
                    className={classes.shoppingCart}
                    onClick={onCartClick}
                  />
                </Box>
                <Box>
                  <div className={classes.cartNumber}>{totalItems}</div>
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default DesktopSearchPanel;
