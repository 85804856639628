import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    click: {
      "&:hover": {
        cursor: "pointer",
      },
      width: "100%",
      height: "100%",
    },
    fillColour: {
      fill: theme.palette.secondary.dark,
    },
    svg: {
      margin: "auto",
      display: "block",
      height: "100%",
      width: "auto",
    },
  })
);

const Logo: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();
  return (
    <div
      onClick={() => {
        history.push("/");
      }}
      className={classes.click}
    >
      <svg
        className={classes.svg}
        viewBox={"0 0 59 20"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}
        preserveAspectRatio={"xMidYMid meet"}
      >
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M7.52639 5.89697H5.97662V4.3966C5.97662 3.59129 5.32883 2.93268 4.53688 2.93268H4.46512C3.67339 2.93268 3.02517 3.59129 3.02517 4.3966V5.89697H1.47583C0.663971 5.89697 0 6.53974 0 7.32508V7.39648C0 8.18204 0.663971 8.82481 1.47583 8.82481H3.02517V10.3983C3.02517 11.2036 3.67339 11.8622 4.46512 11.8622H4.53688C5.32883 11.8622 5.97662 11.2036 5.97662 10.3983V8.82481H7.52639C8.33803 8.82481 9.002 8.18204 9.002 7.39648V7.32508C9.002 6.53974 8.33803 5.89697 7.52639 5.89697"
          }
          className={classes.fillColour}
        />
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M28.9224 5.9714V5.9714C28.0379 5.6932 27.0659 6.13611 26.7618 6.95574L24.3052 13.5835L21.9182 7.14475L21.848 6.95574C21.6077 6.30733 20.9495 5.89502 20.2466 5.88872C19.5441 5.89502 18.8851 6.30733 18.6449 6.95574L18.5747 7.14475L16.1881 13.5835L13.7313 6.95574C13.4274 6.13611 12.4552 5.6932 11.571 5.9714C10.6865 6.2496 10.2113 7.148 10.5147 7.96742L14.5701 18.9073L14.5791 18.932C14.8204 19.5826 15.4826 19.9954 16.1881 19.9991C16.8937 19.9954 17.5559 19.5826 17.797 18.932L17.8059 18.9071L20.2466 12.3238L22.6869 18.9073L22.6959 18.932C22.9374 19.5826 23.5997 19.9954 24.3052 19.9991C25.0103 19.9954 25.6727 19.5826 25.9138 18.932L25.923 18.9071L29.9782 7.96742C30.2822 7.148 29.8069 6.2496 28.9224 5.9714"
          }
          className={classes.fillColour}
        />
        <mask
          id={"mask0"}
          mask-type={"alpha"}
          maskUnits={"userSpaceOnUse"}
          x={"45"}
          y={"0"}
          width={"4"}
          height={"20"}
        >
          <path
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            d={"M45.3608 0H48.7555V20H45.3608V0Z"}
            className={classes.fillColour}
          />
        </mask>
        <g mask={"url(#mask0)"}>
          <path
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            d={
              "M47.1106 -0.000244141H47.0058C46.1009 -0.000244141 45.3608 0.733885 45.3608 1.63099V18.3684C45.3608 19.2657 46.1009 19.9998 47.0058 19.9998H47.1106C48.0152 19.9998 48.7555 19.2657 48.7555 18.3684V1.63099C48.7555 0.733885 48.0152 -0.000244141 47.1106 -0.000244141"
            }
            className={classes.fillColour}
          />
        </g>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M40.3271 11.2528C40.3227 11.2346 40.319 11.2185 40.3162 11.207C40.319 11.2185 40.3227 11.2346 40.3271 11.2528V11.2528ZM34.0886 11.1801C34.1661 10.9601 34.2588 10.7513 34.3649 10.5556C34.5435 10.2003 34.7769 9.89502 35.0598 9.64698C35.6025 9.16957 36.3286 8.90374 37.2013 8.90374C37.219 8.90374 37.2363 8.9046 37.254 8.90482C38.7959 8.92565 39.9237 9.86116 40.3098 11.1801H34.0886ZM44.0935 12.2944C43.8366 8.44694 40.9769 5.72504 37.2013 5.72504C33.2426 5.72504 30.0696 8.93498 30.0696 12.8615C30.0696 16.7882 33.2744 19.9979 37.2326 19.9979C37.3691 19.9979 37.5067 19.9947 37.6448 19.9888C39.7117 19.9717 41.3332 19.3245 42.5853 18.2957C42.6783 18.2141 42.766 18.1197 42.8425 18.0123C43.011 17.7769 43.1252 17.4802 43.1252 17.1215C43.1252 16.2361 42.4501 15.5586 41.5323 15.5586C41.0998 15.5586 40.8294 15.6626 40.5323 15.8707C39.729 16.4458 38.825 16.781 37.75 16.8069C37.7056 16.8097 37.2234 16.8162 37.1418 16.8116C35.8683 16.7411 34.8598 16.1276 34.3463 15.1884C34.3019 15.1036 34.2597 15.0168 34.2201 14.9272C34.1081 14.6717 34.0182 14.397 33.951 14.108H42.4555C43.9959 14.108 44.1081 12.8194 44.0944 12.3209C44.0939 12.3118 44.0935 12.3031 44.0935 12.2944V12.2944Z"
          }
          className={classes.fillColour}
        />
        <mask
          id={"mask1"}
          mask-type={"alpha"}
          maskUnits={"userSpaceOnUse"}
          x={"50"}
          y={"0"}
          width={"4"}
          height={"20"}
        >
          <path
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            d={"M50.2495 0H53.6442V20H50.2495V0Z"}
            className={classes.fillColour}
          />
        </mask>
        <g mask={"url(#mask1)"}>
          <path
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            d={
              "M51.9992 -0.000244141H51.8945C50.9896 -0.000244141 50.2495 0.733885 50.2495 1.63099V18.3684C50.2495 19.2657 50.9896 19.9998 51.8945 19.9998H51.9992C52.9039 19.9998 53.6442 19.2657 53.6442 18.3684V1.63099C53.6442 0.733885 52.9039 -0.000244141 51.9992 -0.000244141"
            }
            className={classes.fillColour}
          />
        </g>
      </svg>
    </div>
  );
};

export default Logo;
