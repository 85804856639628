import { connect } from "react-redux";
import { RootState } from "../../store/types";
import MobileBasket, {
  MobileBasketStateProps,
  MobileBasketDispatchProps,
} from "./MobileBasket";
import { showMobileBasket } from "../../store/selectors";
import { toggleMobileBasket } from "../../store/reducer/basket/action";

function mapStateToProps(state: RootState): MobileBasketStateProps {
  return {
    show: showMobileBasket(state),
  };
}

const mapDispatchToProps: MobileBasketDispatchProps = {
  closeBasket: toggleMobileBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileBasket);
