import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: "0px 60px 30px 60px",
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(4),
    width: "100%",
  },
  buttons: {
    textTransform: "none",
    alignSelf: "center",
  },
  error: {
    marginBottom: 10,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: 20,
  },
}));

export default useStyles;
