import { State } from "./types";

export const getPaymentRequestToken = (state: State) => state.requestToken;
export const getPaymentRequestTokenError = (state: State) =>
  state.requestTokenError;
export const getPaymentRequestTokenLoading = (state: State) =>
  state.requestTokenLoading;
export const getPaymentAmount = (state: State) => state.paymentAmount;
export const getPaymentConfirmed = (state: State) => state.paymentConfirmed;
export const getPaymentError = (state: State) => state.paymentError;
export const getPaymentPending = (state: State) => state.paymentPending;
export const getPaymentisB2B = (state: State) => state.isB2BPayment;
export const getReference = (state: State) => state.reference;
