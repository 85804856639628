import { connect } from "react-redux";
import { cleanBasket } from "../../store/reducer/basket/action";
import { clearCheckout } from "../../store/reducer/checkout/actions";
import {
  getB2BPaymentsIsB2B,
  getCheckoutOrderId,
  getTotalCost,
  getBasketItems,
} from "../../store/selectors";
import { RootState } from "../../store/types";
import Confirmation, { ConfirmationStateProps } from "./Confirmation";

function mapStateToProps(state: RootState): ConfirmationStateProps {
  return {
    orderId: getCheckoutOrderId(state) || "",
    orderItemsTotal: getTotalCost(state),
    isB2B: getB2BPaymentsIsB2B(state),
    basketItems: getBasketItems(state),
  };
}

const mapDispatchToProps = {
  clearBasket: cleanBasket,
  clearCheckout: clearCheckout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
