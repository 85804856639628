import React, { useEffect } from "react";
export interface RouterWrapperDispatchProps {
  setProduct(): void;
  setCheckout(): void;
  setPayment(): void;
  setPatientDetails(): void;
  closeMobileBasket(): void;
  closeDesktopBasket(): void;
}

export interface RouteWrapperPropsFromParent {
  checkout?: boolean;
  payment?: boolean;
  product?: boolean;
  patientDetails?: boolean;
  children: JSX.Element;
}

export type RouterWrapperProps = RouterWrapperDispatchProps &
  RouteWrapperPropsFromParent;

const RouterWrapper: React.FC<RouterWrapperProps> = (props) => {
  useEffect(() => {
    if (props.checkout) props.setCheckout();
    if (props.product) props.setProduct();
    if (props.payment) props.setPayment();
    if (props.patientDetails) props.setPatientDetails();

    props.closeDesktopBasket();
    props.closeMobileBasket();
  }, [props]);
  return <div>{props.children}</div>;
};

export default RouterWrapper;
