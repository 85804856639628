import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Dialog } from "@material-ui/core";
import Basket from "../Basket";
import { useLocation } from "react-router-dom";

export interface PopUpBasketStateProps {
  show: boolean;
}

export interface PopUpBasketDispatchProps {
  setClose(): void;
}

export type PopUpBasketProps = PopUpBasketStateProps & PopUpBasketDispatchProps;

const PopUpBasket: React.FC<PopUpBasketProps> = ({ show, setClose }) => {
  const loc = useLocation();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      popUp: {
        position: "inherit",
        top: loc.pathname === "/" ? 140 : 90,
        left: 0,
        right: 0,
        height: "60vw",
      },
      maxWidth: {
        width: "100%",
        maxWidth: 1660,
        backgroundColor: "transparent",
        boxShadow: "none",
        marginRight: 40,
        paddingRight: 40,
      },
      scrollPaper: {
        display: "block",
      },
    })
  );

  const classes = useStyles();
  return (
    <Dialog
      open={show}
      onClose={setClose}
      aria-labelledby={"form-dialog-title"}
      classes={{
        paper: classes.popUp,
        paperWidthSm: classes.maxWidth,
        scrollPaper: classes.scrollPaper,
      }}
    >
      <div className={classes.maxWidth}>
        <Box display={"flex"}>
          <Box flexGrow={1} onClick={setClose}></Box>
          <Box>
            <Basket width={372} closeBasket={setClose} />
          </Box>
        </Box>
      </div>
    </Dialog>
  );
};
export default PopUpBasket;
