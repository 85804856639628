import { handleActions } from "redux-actions";
import {
  FETCH_PRODUCT_SUGGESTIONS_RESPONSE,
  FetchProductSuggestionsResponseAction,
  FETCH_PRODUCT_SUGGESTIONS_REQUEST,
  FETCH_PRODUCT_SUGGESTIONS_FAILURE,
  State,
  CLEAR_PROD_SELECTION,
  ProductSuggestion,
} from "./types";

import { AnyAction, combineReducers, Reducer } from "redux";

const suggestions = handleActions(
  {
    [CLEAR_PROD_SELECTION]: () => [],
    [FETCH_PRODUCT_SUGGESTIONS_REQUEST]: () => [],
    [FETCH_PRODUCT_SUGGESTIONS_RESPONSE]: (
      s: ProductSuggestion[],
      action: AnyAction
    ) => {
      const a = action as FetchProductSuggestionsResponseAction;

      if (a.payload) {
        return a.payload.map(
          (prod): ProductSuggestion => ({
            sku: prod.id,
            label: prod.name,
            name: prod.name,
          })
        );
      }
      return [] as ProductSuggestion[];
    },
  },
  [] as ProductSuggestion[]
);

const fetching = handleActions(
  {
    [FETCH_PRODUCT_SUGGESTIONS_REQUEST]: () => true,
    [FETCH_PRODUCT_SUGGESTIONS_RESPONSE]: () => false,
    [FETCH_PRODUCT_SUGGESTIONS_FAILURE]: () => false,
  },
  false
);

const reducer: Reducer<State, any> = combineReducers({
  suggestions,
  fetching,
});

export default reducer;
