import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

import { Product as ProductType } from "../../store/reducer/products/types";
import ListImage from "../ListImage/ListImage";
import { BasketItem } from "../../store/reducer/basket/types";
import { addDisabled, getCostStr } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { addToBasket } from "../../analytics/types";
import analytics from "../../analytics";
import DoneIcon from "@material-ui/icons/Done";
import { RichText } from "prismic-reactjs";

export interface ProductListItemProps {
  product: ProductType;
  basketItem: BasketItem;
  basketItems: BasketItem[];
  addToBasket(p: ProductType, q: number): void;
  maxParacetamol: boolean;
  mobile: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productBox: {
      height: 542,
      width: ({ mobile }: ProductListItemProps) => (mobile ? "100%" : 301),
      border: `1px solid #EAF2FE`,
      borderRadius: "0px 0px 4px 4px",
      textAlign: "center",
      marginRight: ({ mobile }: ProductListItemProps) => (mobile ? "" : 35),
      marginTop: 30,
    },
    topHalf: {
      height: 206,
    },
    bottomHalf: {
      paddingTop: 24,
      paddingBottom: 16,
      height: 334,
      backgroundColor: theme.palette.secondary.light,
    },
    image: {
      padding: 10,
      height: "100%",
    },
    title: {
      fontWeight: 600,
      fontSize: 16,
      paddingLeft: 20,
      color: theme.palette.text.primary,
      height: 48,
    },
    shortDescription: {
      fontSize: 16,
      color: theme.palette.text.primary,
      fontWeight: 500,
      paddingTop: 4,
      paddingLeft: 20,
      height: 48,
      textAlign: "left",
    },
    warning: {
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.error.main,
      paddingLeft: 20,
      paddingTop: 4,
      height: 42,
    },
    cost: {
      color: theme.palette.text.primary,
      fontSize: 18,
      fontWeight: 700,
      paddingLeft: 20,
    },
    button: {
      backgroundColor: theme.palette.primary.dark,
      width: 260,
      marginTop: 16,
      height: 46,
    },
    buttonText: {
      textTransform: "none",
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.text.hint,
    },
    limited: {
      paddingTop: 16,
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontSize: 12,
      paddingLeft: 20,
    },
    disabled: {
      backgroundColor: theme.palette.error.light,
    },
    clickable: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    buttonOuter: {
      textAlign: "left",
      paddingLeft: 20,
    },
  })
);

const Product: React.FC<ProductListItemProps> = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const onClick = () => {
    history.push(`/product/${props.product.sku}`);
  };

  const [isAdded, setIsAdded] = useState<boolean>(false);

  const startTickTimeout = () => {
    setTimeout(() => {
      setIsAdded(false);
    }, 2000);
  };
  return (
    <Grid
      item
      className={classes.productBox}
      style={{
        border: props.basketItem.qty > 0 ? `1px solid #0061F2` : "",
      }}
    >
      <div className={classes.topHalf}>
        <div
          className={`${classes.image} ${classes.clickable}`}
          onClick={onClick}
        >
          <ListImage
            imageUrl={props.product.imageUrl}
            productName={props.product.name}
            iconSize={80}
          />
        </div>
      </div>
      <div className={classes.bottomHalf}>
        <div onClick={onClick} className={classes.clickable}>
          <Typography variant={"h6"} align={"left"} className={classes.title}>
            {props.product.name}
          </Typography>
          <div className={classes.shortDescription}>
            <RichText render={props.product.shortDescription} />
          </div>
        </div>
        <Typography
          variant={"body2"}
          align={"left"}
          className={classes.warning}
        >
          {props.maxParacetamol && props.product.containsParacetamol && (
            <span>
              You cannot add any more paracetamol containing products to your
              basket
            </span>
          )}
        </Typography>
        <Typography
          variant={"body2"}
          align={"left"}
          className={classes.warning}
        >
          {props.product.inventoryCount <= props.basketItem.qty && (
            <span>
              We only have {props.basketItem.qty} of this item in stock at the
              moment
            </span>
          )}
        </Typography>
        <Typography variant={"h4"} align={"left"} className={classes.cost}>
          £{getCostStr(props.product.cost)}
        </Typography>
        <div className={classes.buttonOuter}>
          <Button
            variant={"text"}
            color={"secondary"}
            className={classes.button}
            onClick={() => {
              setIsAdded(true);
              startTickTimeout();
              props.addToBasket(props.basketItem.product, 1);
              analytics.trackEventWithBasketDetails(
                {
                  event: addToBasket,
                  metadata: {
                    productName: props.basketItem.product.name,
                    productSKU: props.basketItem.product.sku,
                  },
                },
                [...props.basketItems, props.basketItem]
              );
            }}
            disabled={addDisabled(
              props.basketItem.product,
              props.maxParacetamol,
              props.basketItem.qty
            )}
            classes={{
              disabled: classes.disabled,
              text: classes.buttonText,
            }}
          >
            {isAdded ? <DoneIcon /> : <>Add to basket</>}
          </Button>
        </div>
        <Typography
          variant={"body2"}
          align={"left"}
          className={classes.limited}
        >
          Limited to {props.product.maxPurchaseNo} per customer
        </Typography>
      </div>
    </Grid>
  );
};

export default Product;
