import { Dispatch, AnyAction, MiddlewareAPI } from "redux";
import { FETCH_CATEGORIES, FETCH_CATEGORIES_RESPONSE } from "./types";

import { getAllCategories, makeCatArrayFromPrismic } from "../../utils/prismic";

export const catMiddle = (store: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => async (action: AnyAction) => {
  if (action.type !== FETCH_CATEGORIES) {
    return next(action);
  }
  const response = await getAllCategories();

  if (response) {
    return next({
      type: FETCH_CATEGORIES_RESPONSE,
      payload: {
        categories: makeCatArrayFromPrismic(response),
      },
    });
  }

  return next(action);
};
