import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { tokenLinkTopInfoParagraphs } from "./copy";
import { getCostStr } from "../../utils/utils";

export interface LinkDisplayProps {
  baseUrl: string;
  token: string;
  amount: number;
  reference: string;
}

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: theme.spacing(1),
  },
  link: {
    wordBreak: "break-all",
  },
  buttons: {
    textTransform: "none",
  },
  bold: {
    fontWeight: 900,
  },
}));

const LinkDisplay: React.FC<LinkDisplayProps> = ({
  token,
  baseUrl,
  amount,
  reference,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction={"column"} spacing={2}>
      <Grid item container direction={"column"} justify={"center"}>
        <Grid item>
          <Typography className={classes.bold} display={"inline"}>
            {"Order reference: "}
          </Typography>
          <Typography display={"inline"}>{`${reference}`}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.bold} display={"inline"}>
            {"Payment amount: "}
          </Typography>
          <Typography display={"inline"}>{`£${getCostStr(amount)}`}</Typography>
        </Grid>
      </Grid>
      {tokenLinkTopInfoParagraphs.map((p, i) => (
        <Grid key={i} item>
          <Typography>{p}</Typography>
        </Grid>
      ))}
      <Grid container item justify={"center"} alignItems={"center"}>
        <Grid item className={classes.linkContainer}>
          <Typography className={classes.link}>
            {`${baseUrl}/${token}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item justify={"center"} alignItems={"center"}>
        <Grid item>
          <CopyToClipboard text={`${baseUrl}/${token}`}>
            <Button
              variant={"contained"}
              color={"secondary"}
              data-testid={"b2b-payment-link-copy-to-clipboard"}
              className={classes.buttons}
            >
              Copy
            </Button>
          </CopyToClipboard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LinkDisplay;
