import { Dispatch, AnyAction, MiddlewareAPI } from "redux";
import {
  FETCH_PRODUCTS_HIGHLIGHT,
  FETCH_PRODUCTS_HIGHLIGHT_RESPONSE,
  FetchProductsHighlightedResponseAction,
  HighlightedCategories,
  FETCH_CAT_PRODUCTS,
  FETCH_PRODUCT_DETAIL,
  FetchCategoryProductsAction,
  FetchProductDetailsAction,
  FetchProductDetailsResponseAction,
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCT_DETAIL_RESPONSE,
} from "./types";
import {
  getByIds,
  getByUid,
  getProductsInCategory,
  makeCatFromPrismic,
  makeProdArrayFromPrismic,
  makeProdFromPrismicDoc,
  queryHighlightedCategories,
  queryHighlightedProds,
} from "../../utils/prismic";
import { fetchProductSalesCount } from "./actions";

export const prodMiddle = (store: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => async (action: AnyAction) => {
  switch (action.type) {
    case FETCH_PRODUCTS_HIGHLIGHT:
      return await fetchHighlightProducts(store, next, action);
    case FETCH_PRODUCT_DETAIL:
      return await fetchProductsDetails(store, next, action);
    case FETCH_CAT_PRODUCTS:
      return await fetchSelectedCat(store, next, action);
  }

  return next(action);
};

const fetchHighlightProducts = async (
  store: MiddlewareAPI,
  next: Dispatch<AnyAction>,
  action: AnyAction
) => {
  next(action);
  const catResponse = await queryHighlightedCategories();
  if (catResponse) {
    const highlightedCats: HighlightedCategories[] = [];
    for (const cat of catResponse.results) {
      const prodRes = await queryHighlightedProds(cat.id);
      if (prodRes) {
        const hc: HighlightedCategories = {
          category: makeCatFromPrismic(cat),
          products: makeProdArrayFromPrismic(prodRes),
        };
        highlightedCats.push(hc);
      } else break;
    }
    if (highlightedCats.length > 0) {
      const a: FetchProductsHighlightedResponseAction = {
        type: FETCH_PRODUCTS_HIGHLIGHT_RESPONSE,
        payload: {
          highlightedCategories: highlightedCats,
        },
      };
      store.dispatch(
        fetchProductSalesCount(
          a.payload.highlightedCategories
            .reduce(
              (accumulator, currentValue) =>
                ({
                  products: currentValue.products.concat(accumulator.products),
                } as HighlightedCategories)
            )
            .products.map((prod) => prod.sku)
        )
      );

      return next(a);
    }
  }
  return next({ type: FETCH_PRODUCTS_FAIL });
};

const fetchProductsDetails = async (
  store: MiddlewareAPI,
  next: Dispatch<AnyAction>,
  action: AnyAction
) => {
  next(action);
  const a = action as FetchProductDetailsAction;
  const prodDetailResponse = await getByUid(
    "product",
    a.payload.sku.toLowerCase()
  );

  if (prodDetailResponse) {
    const relatedProdRes = await getByIds(
      prodDetailResponse.data.related_products
        .filter((p: any) => !!p.product.id)
        .map((p: any) => p.product.id)
    );
    if (relatedProdRes) {
      const a: FetchProductDetailsResponseAction = {
        type: FETCH_PRODUCT_DETAIL_RESPONSE,
        payload: {
          selectedProduct: {
            product: makeProdFromPrismicDoc(prodDetailResponse),
            relatedProducts: makeProdArrayFromPrismic(relatedProdRes),
          },
        },
      };
      return next(a);
    }
  }
  return next({ type: FETCH_PRODUCTS_FAIL });
};

const fetchSelectedCat = async (
  store: MiddlewareAPI,
  next: Dispatch<AnyAction>,
  action: AnyAction
) => {
  next(action);
  const a = action as FetchCategoryProductsAction;
  const catResponse = await getByUid("category", a.payload.category);
  if (catResponse) {
    const prodRes = await getProductsInCategory(catResponse.id);
    if (prodRes) {
      const hc: HighlightedCategories = {
        category: makeCatFromPrismic(catResponse),
        products: makeProdArrayFromPrismic(prodRes),
      };
      const a: FetchProductsHighlightedResponseAction = {
        type: FETCH_PRODUCTS_HIGHLIGHT_RESPONSE,
        payload: {
          highlightedCategories: [hc],
        },
      };
      return next(a);
    }
  }

  return next({ type: FETCH_PRODUCTS_FAIL });
};
