import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      display: "block",
      margin: "auto",
    },
    blankImage: {
      width: "100%",
      height: "100%",
      background: theme.palette.primary.light,
    },
    cameraIcon: {
      width: "100%",
      margin: "auto",
      marginTop: "20%",
    },
  })
);

interface ListImageProps {
  imageUrl: string;
  productName: string;
  iconSize: number;
}

const ListImage: React.FC<ListImageProps> = ({
  imageUrl,
  productName,
  iconSize,
}) => {
  const classes = useStyles();
  if (imageUrl === "" || imageUrl === undefined) {
    return (
      <div className={classes.blankImage}>
        <CameraAltIcon
          className={classes.cameraIcon}
          style={{ fontSize: iconSize }}
        />
      </div>
    );
  }
  return <img src={imageUrl} alt={productName} className={classes.image} />;
};

export default ListImage;
