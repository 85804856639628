import {
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import BreadCrumb from "../../components/Checkout/Breadcrumb/Breadcrumb";
import Payment from "../../components/Payment/B2C";
import { makeCheckoutBreadcrumb } from "../Shipping/Shipping";
import { BasketItem } from "../../store/reducer/basket/types";
import analytics from "../../analytics";
import { paymentPage } from "../../analytics/types";

export interface PaymentStateProps {
  neededPatientDetails: boolean;
  basketItems: BasketItem[];
}

export interface PaymentDispatchProps {
  onLoad(): void;
}

export type PaymentProps = PaymentStateProps & PaymentDispatchProps;

const PaymentPage: React.FC<PaymentProps> = ({
  neededPatientDetails,
  onLoad,
  basketItems,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginLeft: isMobile ? 20 : 100,
      },
      breadcrumb: {
        marginTop: 20,
      },
      title: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginTop: 45,
        marginBottom: 25,
        fontSize: 34,
      },
    })
  );
  const classes = useStyles();

  // Run on load on mount
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    analytics.trackEventWithBasketDetails(
      {
        event: paymentPage,
      },
      basketItems
    );
  }, [basketItems]);

  return (
    <div className={classes.root}>
      {!isMobile && (
        <div className={classes.breadcrumb}>
          <BreadCrumb
            links={makeCheckoutBreadcrumb(neededPatientDetails, "payment")}
          />
        </div>
      )}
      <Typography variant={"h4"} align={"left"} className={classes.title}>
        Select a method of payment
      </Typography>
      <Payment />
    </div>
  );
};

export default PaymentPage;
