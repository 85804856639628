import { connect } from "react-redux";
import { RootState } from "../../store/types";
import BillingAddress, {
  BillingAddressStateProps,
  BillingAddressDispatchProps,
} from "./BillingAddress";
import {
  getBillingAddress,
  getPostcodeAddressLookup,
  getPostcodeLookupError,
  getBasketItems,
  getTotalCost,
  getBasketNeedsPatientDetails,
} from "../../store/selectors";
import {
  addBillingAddress,
  consentToMarketing,
  fetchPostCodeAddress,
} from "../../store/reducer/checkout/actions";

function mapStateToProps(state: RootState): BillingAddressStateProps {
  return {
    billingAddress: getBillingAddress(state),
    error: getPostcodeLookupError(state),
    postCodeLookUpAddresses: getPostcodeAddressLookup(state),
    basketItems: getBasketItems(state),
    totalCost: getTotalCost(state),
    neededPatientDetails: getBasketNeedsPatientDetails(state),
  };
}

const mapDispatchToProps: BillingAddressDispatchProps = {
  onSubmit: addBillingAddress,
  onClickPostCodeSearch: fetchPostCodeAddress,
  includeMarketing: consentToMarketing,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAddress);
