import { AnyAction } from "redux";
import { RSAA } from "redux-api-middleware";
import { PaymentError } from "../checkout/types";

export interface State {
  requestToken: string | null;
  requestTokenError: string | null;
  requestTokenLoading: boolean;
  paymentAmount: number;
  paymentPending: boolean;
  paymentError: PaymentError | null;
  paymentConfirmed: boolean;
  isB2BPayment: boolean;
  reference: string | null;
}

// Data structure types
export interface PaymentRequestData {
  reference: string;
  amount: number;
  password: string;
}

// Action types
export const CREATE_B2B_PAYMENT_REQUEST_TOKEN =
  "CREATE_B2B_PAYMENT_REQUEST_TOKEN";
export const CREATE_B2B_PAYMENT_REQUEST_TOKEN_REQUEST =
  "CREATE_B2B_PAYMENT_REQUEST_TOKEN_REQUEST";
export const CREATE_B2B_PAYMENT_REQUEST_TOKEN_RESPONSE =
  "CREATE_B2B_PAYMENT_REQUEST_TOKEN_RESPONSE";
export const CREATE_B2B_PAYMENT_REQUEST_TOKEN_FAILURE =
  "CREATE_B2B_PAYMENT_REQUEST_TOKEN_FAILURE";
export interface CreateB2BPaymentRequestTokenAction {
  type: string;
  [RSAA]: {
    endpoint: string;
    method: string;
    body: string;
    types: [string, string, string];
  };
}

export interface CreateB2BPaymentRequestTokenAResponseAction {
  type: string;
  payload: {
    token: string;
  };
}

export const START_PAYMENT_REQUEST_PROCESS = "START_PAYMENT_REQUEST_PROCESS";
export type StartPaymentRequestProcessAction = AnyAction;
export const START_B2B_PAYMENT_PROCESS = "START_B2B_PAYMENT_PROCESS";
export type StartB2BPaymentProcessAction = AnyAction;

export interface StandardShopAPIErrorResponseAction {
  type: string;
  payload: {
    response: {
      status: string;
      message: string;
    };
  };
}

export const FETCH_B2B_BRAINTREE_TOKEN = "PAYMENT/FETCH_B2B_BRAINTREE_TOKEN";
export const FETCH_B2B_BRAINTREE_TOKEN_REQUEST =
  "PAYMENT/FETCH_B2B_BRAINTREE_TOKEN_REQUEST";
export const FETCH_B2B_BRAINTREE_TOKEN_RESPONSE =
  "PAYMENT/FETCH_B2B_BRAINTREE_TOKEN_RESPONSE";
export const FETCH_B2B_BRAINTREE_TOKEN_FAILURE =
  "PAYMENT/FETCH_B2B_BRAINTREE_TOKEN_FAILURE";

export const ON_B2B_PAY = "PAYMENT/ON_B2B_PAY";
export const ON_B2B_PAY_REQUEST = "PAYMENT/ON_B2B_PAY_REQUEST";
export const ON_B2B_PAY_RESPONSE = "PAYMENT/ON_B2B_PAY_RESPONSE";
export const ON_B2B_PAY_FAILURE = "PAYMENT/ON_B2B_PAY_FAILURE";
